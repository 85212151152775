import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Card,
  CardContent,
  MenuItem,
  Select,
  Button,
  InputAdornment,
} from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
const ALPHA_NUMERIC = /^[a-zA-Z0-9-]+$/;
const ALPHA = /^[a-zA-Z ]+$/;
const Numerics = /^[0-9.]*$/;
const Numerics1 = /^[0-9]*$/;
import { ToastContainer, toast } from "react-toastify";

import moment from "moment";

import "../styles/DataEntry.scss";

import {
  createPatientProfile,
  babyProfileUpdate,
  setBabyData,
  getBabyProfileByReadingId,
} from "../reducers/patientDataThunk";

import { getWardDetailbyBranch } from "../../Dashboard/reducers/Dashboardthunk";
import { number } from "prop-types";
import { DD } from "../../Admin/utils/mockdata";

const BabyGeneral = (props) => {
  const patient = useSelector((state) => state.patient);
  const user = useSelector((state) => state.user);
  const dbbranchdata = useSelector((state) => state.dashboard);

  const dispatch = useDispatch();
  let study_id = patient.bmrndata?.study_id;
  const loggedUserId = user.userProfile?.user_id;
  const [updateRecord, setUpdateRecord] = useState();
  const [addFormData, setAddFormData] = useState(
    Object.assign({ baby_date_of_admission: new Date() }, patient.babyData)
  );

  console.log('5555555555',addFormData)

  const hospital_id = user.userProfile.hospital_id;
  const branch_id = user.branchdata.branch_id;
  const [warddata, setwarddata] = React.useState([]);
  const [fromDate, setFromDate] = React.useState(moment().format());
  const [toDate, setToDate] = React.useState(moment().format());
  const [AgeAtAdmission, setAgeAtAdmission] = useState();
  const [BabyWeightType, setBabyWeightType] = useState("Kgs");
  const [errorFields, setErrorFields] = useState({});
  const [babyWeightAtBirth, setBabyWeightAtBirth] = useState();
  const [babyWeightAtAdmission, setBabyWeightAtAdmission] = useState();
  const [babyWeightAtBirthType, setBabyWeightAtBirthType] = useState("kgs");
  const [babyWeightAtAdmissionType, setBabyWeightAtAdmissionType] = useState("kgs");
  const [showPOBNameErrorMessage, setShowPOBNameErrorMessage] = useState();
  const [showPincodeErrorMessage, setShowPincodeErrorMessage] = useState();
  const [showApgar1MinErrorMessage, setShowApgar1MinErrorMessage] = useState();
  const [showApgar5MinErrorMessage, setShowApgar5MinErrorMessage] = useState();
  const [showApgar10MinErrorMessage, setShowApgar10MinErrorMessage] =
    useState();
  const [showBabyWtAtBirthErrorMessage, setShowBabyWtAtBirthErrorMessage] =
    useState();
  const [
    showBabyWtAtAdmissionErrorMessage,
    setShowBabyWtAtAdmissionErrorMessage,
  ] = useState();
  const [
    showGestationalAgeMinErrorMessage,
    setShowGestationalAgeMinErrorMessage,
  ] = useState();

  const [
    showBabyBirthTimeHoursErrorMessage,
    setShowBabyBirthTimeHoursErrorMessage,
  ] = useState();
  const [
    showBabyBirthTimeMinsErrorMessage,
    setShowBabyBirthTimeMinsErrorMessage,
  ] = useState();
  const [
    showDayofSepsisEventErrorMessage,
    setShowDayofSepsisEventErrorMessage,
  ] = useState();
  useEffect(() => {
    const start = moment(addFormData.baby_birth_date).format("MM-DD-YYYY");
    const end = moment(addFormData.baby_date_of_admission).format("MM-DD-YYYY");
    const diff = moment(end).diff(start, "days");
    setAgeAtAdmission(diff);
  }, [addFormData.baby_birth_date, addFormData.baby_date_of_admission]);

  useEffect(() => {
    setAddFormData(
      Object.assign({ baby_date_of_admission: new Date() }, patient.babyData)
    );
  }, [patient.babyData]);

  useEffect(() => {
    const req = { hospital_id, branch_id };
    dispatch(getWardDetailbyBranch(req))
      .unwrap()
      .then((resdata) => {});
  }, []);

  useEffect(() => {
    setwarddata(dbbranchdata.wardListbyBranch);
  }, [dbbranchdata.wardListbyBranch]);

  useEffect(() => {
    if (patient?.bmrndata?.study_id) updateData();
  }, [patient?.bmrndata?.study_id]);

  useEffect(() => {
    props.updateBabyGeneralData(addFormData);
  }, [addFormData]);

  const updateData = (event) => {
    const readingId = patient.Selectedreadingid;
    study_id = patient.bmrndata.study_id;
    const req = { readingId, study_id };
    dispatch(getBabyProfileByReadingId(req))
      .unwrap()
      .then((resdata) => {
        let BabyData = {
          response: Object.assign({}, resdata.response),
        };

        const data =
          BabyData.response && BabyData.response
            ? BabyData.response
            : undefined;
        const data1 =
          BabyData.response && BabyData.response.id
            ? setUpdateRecord(true)
            : setUpdateRecord(false);
        if (resdata.response) {
          setAddFormData(
            BabyData.response && BabyData.response
              ? Object.assign(
                  { baby_date_of_admission: new Date() },
                  BabyData.response
                )
              : { baby_date_of_admission: new Date() }
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const verifyGestationalAge = (event) => {
    if (event.target.value >= 20 && event.target.value <= 45) {
      var dataValue = "YES";

        if (event.target.value  >= 31 && event.target.value  <= 37) {
          dataValue = "YES";
          setAddFormData(
            Object.assign(
              {},
              addFormData,
              { baby_preterm: dataValue },
            )
          );
          console.log('dataValue');

          setErrorFields(
            Object.assign({}, errorFields, { baby_gestational_age: false })
          );
        }
        else if (event.target.value >= 38) {
          dataValue = "NO";
          setAddFormData(
            Object.assign({},{ baby_preterm: "NO" })
          );
          setErrorFields(
            Object.assign({}, errorFields, { baby_gestational_age: false })
          );

          console.log('dataValue',dataValue);
        }

      handleAddFormChange(event);
      setShowGestationalAgeMinErrorMessage("");
    } else {
      setShowGestationalAgeMinErrorMessage(
        "Gestational Age should be between 20 and 45"
      );
      setErrorFields(
        Object.assign({}, errorFields, { baby_gestational_age: true })
      );
      handleAddFormChange(event);
    }
  };

  const verifyPincode = (event) => {
    const pincodeRegex1 = /^[1-9][0-9]{5}$/;
    if (!pincodeRegex1.test(event.target.value)) {
      setShowPincodeErrorMessage("pincode should be 6 Digits");
      setErrorFields(
        Object.assign({}, errorFields, { baby_place_of_birth_pin_code: true })
      );
    } else {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, { baby_place_of_birth_pin_code: false })
      );
      setShowPincodeErrorMessage("");
    }
  };

  const verifyBabyBirthTimeHours = (event) => {
    if (event.target.value >= 0 && event.target.value <= 23) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, { baby_birth_time_hours: false })
      );
      setShowBabyBirthTimeHoursErrorMessage("");
    } else {
      setShowBabyBirthTimeHoursErrorMessage(
        "Time of Birth Hours should be between 0 and 23"
      );
      setErrorFields(
        Object.assign({}, errorFields, { baby_birth_time_hours: true })
      );
    }
  };

  const verifyBabyBirthTimeMins = (event) => {
    if (event.target.value >= 0 && event.target.value <= 59) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, { baby_birth_time_minit: false })
      );
      setShowBabyBirthTimeMinsErrorMessage("");
    } else {
      setShowBabyBirthTimeMinsErrorMessage(
        "Time of Birth Minutes should be between 0 and 59"
      );
      setErrorFields(
        Object.assign({}, errorFields, { baby_birth_time_minit: true })
      );
    }
  };

  const verifyApgarScore1Min = (event) => {
    if (event.target.value >= 0 && event.target.value <= 10) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, { baby_apgar_score_one_min: false })
      );
      setShowApgar1MinErrorMessage("");
    } else {
      setShowApgar1MinErrorMessage(
        "Apgar Score 1 Min should be between 0 and 10"
      );
      setErrorFields(
        Object.assign({}, errorFields, { baby_apgar_score_one_min: true })
      );
    }
  };

  const verifyApgarScore5Min = (event) => {
    if (event.target.value >= 0 && event.target.value <= 10) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, { baby_apgar_score_five_min: false })
      );
      setShowApgar5MinErrorMessage("");
    } else {
      setShowApgar5MinErrorMessage(
        "Apgar Score 5 Min should be between 0 and 10"
      );
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, { baby_apgar_score_five_min: true })
      );
    }
  };

  const verifyApgarScore10Min = (event) => {
    if (event.target.value >= 0 && event.target.value <= 10) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, { baby_apgar_score_ten_min: false })
      );
      setShowApgar10MinErrorMessage("");
    } else {
      setShowApgar10MinErrorMessage(
        "Apgar Score 10 Mins should be between 0 and 10"
      );
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, { baby_apgar_score_ten_min: true })
      );
    }
  };

  const verifyDayofSepsisEvent = (event) => {
    if (event.target.value >= 0 && event.target.value <= 50) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, { baby_day_of_event: false })
      );
      setShowDayofSepsisEventErrorMessage("");
    } else {
      setShowDayofSepsisEventErrorMessage(
        "Day of Sepsis Event should be between 0 and 50"
      );
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, { baby_day_of_event: true })
      );
    }
  };

  const verifyBabyWeightAtBirth = (event) => {
    if (babyWeightAtBirthType == "Kgs") {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 0,
      maxValue = 10;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, { baby_weight_at_birth: false })
      );
      setShowBabyWtAtBirthErrorMessage("");
    } else {
      setShowBabyWtAtBirthErrorMessage(
        "Weight At Birth should be between 0 - 10 Kgs, it should have only two decimal positions."
      );
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, { baby_weight_at_birth: true })
      );
    }
  }
  else if (babyWeightAtBirthType == "Lbs") {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 0,
      maxValue = 25;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, { baby_weight_at_birth: false })
      );
      setShowBabyWtAtBirthErrorMessage("");
    } else {
      setShowBabyWtAtBirthErrorMessage(
        "Weight At Birth should be between 0 - 25 Lbs, it should have only two decimal positions."
      );
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, { baby_weight_at_birth: true })
      );
    }
  }
  };
  const verifyBabyWeightType = (event) => {
    if(babyWeightAtBirth){
    if (event.target.value == "Kgs") {
      let temp1 = babyWeightAtBirth.toString();
      let decimalValue1 = temp1.split(".");
      let minValue = 0,
        maxValue = 9;
      if (
        babyWeightAtBirth <= maxValue &&
        babyWeightAtBirth >= minValue &&
        (!decimalValue1[1] || decimalValue1[1].length <= 2)
      ) {
        handleAddFormChange(event);
        setErrorFields(
          Object.assign({}, errorFields, { baby_weight_at_birth: false })
        );
        setShowBabyWtAtBirthErrorMessage("");
      } else {
        setShowBabyWtAtBirthErrorMessage(
          "Weight At Birth should be between 0 - 10 Kgs, it should have only two decimal positions."
        );
        handleAddFormChange(event);
        setErrorFields(
          Object.assign({}, errorFields, { baby_weight_at_birth: true })
        );
      }

    }
    else if (event.target.value == "lbs") {
      let temp1 = babyWeightAtBirth.toString();
      let decimalValue1 = temp1.split(".");
      let minValue = 0,
        maxValue = 25;
      if (
        babyWeightAtBirth <= maxValue &&
        babyWeightAtBirth >= minValue &&
        (!decimalValue1[1] || decimalValue1[1].length <= 2)
      ) {
        handleAddFormChange(event);
        setErrorFields(
          Object.assign({}, errorFields, { baby_weight_at_birth: false })
        );
        setShowBabyWtAtBirthErrorMessage("");
      } else {
        setShowBabyWtAtBirthErrorMessage(
          "Weight At Birth should be between 0 - 25 Lbs, it should have only two decimal positions."
        );
        handleAddFormChange(event);
        setErrorFields(
          Object.assign({}, errorFields, { baby_weight_at_birth: true })
        );
      }

    }
  }
  }

  const verifyBabyWeightAtAdmission = (event) => {

    if (babyWeightAtAdmissionType == "kgs") {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 0,
      maxValue = 9;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, { baby_weight_at_admission: false })
      );
      setShowBabyWtAtAdmissionErrorMessage("");
    } else {
      setShowBabyWtAtAdmissionErrorMessage(
        "Weight At Admission should be between 0 - 9 Kgs, it should have only two decimal positions."
      );
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, { baby_weight_at_admission: true })
      );
    }
    }

    if (babyWeightAtAdmissionType == "lbs") {
      let temp1 = event.target.value.toString();
      let decimalValue1 = temp1.split(".");
      let minValue = 0,
        maxValue = 25;
      if (
        event.target.value <= maxValue &&
        event.target.value >= minValue &&
        (!decimalValue1[1] || decimalValue1[1].length <= 2)
      ) {
        handleAddFormChange(event);
        setErrorFields(
          Object.assign({}, errorFields, { baby_weight_at_admission: false })
        );
        setShowBabyWtAtAdmissionErrorMessage("");
      } else {
        setShowBabyWtAtAdmissionErrorMessage(
          "Weight At Admission should be between 0 - 25 Lbs, it should have only two decimal positions."
        );
        handleAddFormChange(event);
        setErrorFields(
          Object.assign({}, errorFields, { baby_weight_at_admission: true })
        );
      }
      }
  };

  const verifyBabyWeightAtAdmissionType = (event) => {
    if(babyWeightAtAdmission){
    if (event.target.value == "kgs") {
      let temp1 = babyWeightAtAdmission.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 0,
      maxValue = 9;
    if (
      babyWeightAtAdmission <= maxValue &&
      babyWeightAtAdmission >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, { baby_weight_at_admission: false })
      );
      setShowBabyWtAtAdmissionErrorMessage("");
    } else {
      setShowBabyWtAtAdmissionErrorMessage(
        "Weight At Admission should be between 0 - 9 Kgs, it should have only two decimal positions."
      );
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, { baby_weight_at_admission: true })
      );
    }
  }
    else if (event.target.value == "lbs") {
      let temp1 = babyWeightAtAdmission.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 0,
      maxValue = 25;
    if (
      babyWeightAtAdmission <= maxValue &&
      babyWeightAtAdmission >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, { baby_weight_at_admission: false })
      );
      setShowBabyWtAtAdmissionErrorMessage("");
    } else {
      setShowBabyWtAtAdmissionErrorMessage(
       "Weight At Admission should be between 0 - 25 Lbs, it should have only two decimal positions."
      );
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, { baby_weight_at_admission: true })
      );
    }
    }
    }


  }

  const handleAddFormChange = (event) => {
    // console.log("We are in handle form change");
    const fieldname = event.target.name;
    // console.log("Field Name is ");
    // console.log(fieldname);
    let fieldvalue = event.target.value;

    //const fieldvalue = event.target.value;
    // console.log("Field Value is ");
    // console.log(fieldvalue);

    const newFormData = { ...addFormData };
    newFormData[fieldname] = fieldvalue;

    // console.log("Field Value is ");
    // console.log(newFormData);

    setAddFormData(newFormData);
    setBabyData(newFormData);
    props.updateBabyGeneralData(newFormData);
  };

  const HandleAddFormSubmit = (event) => {
    event.preventDefault();

    const newFormData = { ...addFormData };
    // console.log("Data That the Application is sending is,");
    // console.log(newFormData);
    // console.log("About to call Axios API");
    // console.log("newFormData", newFormData);
    setBabyData(newFormData);

    for (const key in newFormData) {
      if (!newFormData[key]) {
        newFormData[key] = "NA";
      }
    }
    newFormData.study_id = patient.bmrndata?.study_id;
    newFormData.loggedUserId = loggedUserId;
    if (!updateRecord) {
      dispatch(createPatientProfile(newFormData))
        .unwrap()
        .then((resdata) => {
          // console.log(resdata);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      const req = { study_id, data: newFormData, loggedUserId };
      dispatch(babyProfileUpdate(req))
        .unwrap()
        .then((resdata) => {
          // console.log(resdata);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Card variant="none">
          <CardContent>
            <form onSubmit={HandleAddFormSubmit} id="mother-medical-form">
              <Grid container>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="Type of Record"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Type Of Record
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.record_type}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      value="PROSPECTIVE"
                      id="record_type"
                      name="record_type"
                      size="small"
                      className="dataentry_input"
                    >
                      Prospective
                    </ToggleButton>
                    <ToggleButton
                      value="RETROSPECTIVE"
                      id="record_type"
                      name="record_type"
                      size="small"
                      className="dataentry_input"
                    >
                      Retrospective
                    </ToggleButton>
                    <ToggleButton
                      value="NA"
                      id="record_type"
                      name="record_type"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="Admission Type"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Admission Type
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.baby_admission_type}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      value="Inborn"
                      id="admission_type"
                      name="baby_admission_type"
                      className="dataentry_input"
                      size="small"
                    >
                      Inborn
                    </ToggleButton>
                    <ToggleButton
                      value="Outborn"
                      id="admission_type"
                      name="baby_admission_type"
                      className="dataentry_input"
                      size="small"
                    >
                      Outborn
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="ward"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Birth Facility
                  </label>

                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.birth_facility}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    //required
                  >
                    <ToggleButton
                      value="ICU"
                      id="birth_facility"
                      name="birth_facility"
                      className="dataentry_input"
                      size="small"
                    >
                      ICU
                    </ToggleButton>
                    <ToggleButton
                      value="NICU"
                      id="birth_facility"
                      name="birth_facility"
                      className="dataentry_input"
                      size="small"
                    >
                      NICU
                    </ToggleButton>
                    <ToggleButton
                      value="NA"
                      id="birth_facility"
                      name="birth_facility"
                      className="dataentry_input"
                      size="small"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="POB"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Place Of Birth(Name)
                  </label>
                  <TextField
                    id="placeofbirthname"
                    name="baby_place_of_birth_name"
                    className="dataentry_input"
                    variant="outlined"
                    size="small"
                    //disabled={!defaultCheck.PobNamechecked}
                    value={
                      addFormData?.baby_place_of_birth_name &&
                      addFormData?.baby_place_of_birth_name
                    }
                    onChange={handleAddFormChange}
                    error={
                      errorFields.baby_place_of_birth_name &&
                      showPOBNameErrorMessage
                    }
                    errorText={showPOBNameErrorMessage}
                    helperText={
                      errorFields.baby_place_of_birth_name &&
                      showPOBNameErrorMessage
                        ? showPOBNameErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (!ALPHA.test(event.key)) {
                        setShowPOBNameErrorMessage(
                          "Only Alphabets and Space are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            baby_place_of_birth_name: true,
                          })
                        );
                        event.preventDefault();
                      } else {
                        handleAddFormChange(event);
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            baby_place_of_birth_name: false,
                          })
                        );
                        setShowPOBNameErrorMessage("");
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="DOB"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Date Of Birth
                  </label>
                  <DatePicker
                    id="baby_birth_date"
                    size="small"
                    name="baby_birth_date"
                    className="dataentry_input"
                    inputFormat="DD/MM/YYYY"
                    maxDate={moment(
                      addFormData?.baby_date_of_admission
                    ).format()}

                    value={addFormData?.baby_birth_date}
                    //value={moment(addFormData?.baby_birth_date).format()}
                    InputLabelProps={{ shrink: true }}
                    //disabled={!defaultCheck.DateOfBirthchecked}
                    onChange={(date) => {
                      //addFormData.baby_birth_date = date["$d"];
                      setFromDate(date["$d"]);
                      handleAddFormChange({
                        target: { name: "baby_birth_date", value: date["$d"] },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        className="dataentry_input"
                        helperText={null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="POBpin"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Place Of Birth(Pincode)
                  </label>
                  <TextField
                    id="placeofbirthpin"
                    name="baby_place_of_birth_pin_code"
                    className={
                      "dataentry_input " +
                      (errorFields.baby_place_of_birth_pin_code
                        ? "errorField"
                        : "")
                    }
                    variant="outlined"
                    size="small"
                    //disabled={!defaultCheck.PobPinchecked}
                    value={
                      addFormData?.baby_place_of_birth_pin_code &&
                      addFormData?.baby_place_of_birth_pin_code
                    }
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyPincode(e);
                    }}
                    error={
                      errorFields.baby_place_of_birth_pin_code &&
                      showPincodeErrorMessage
                    }
                    //helperText={showErrorMessage}
                    errorText={showPincodeErrorMessage}
                    helperText={
                      errorFields.baby_place_of_birth_pin_code &&
                      showPincodeErrorMessage
                        ? showPincodeErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowPincodeErrorMessage(
                          "Only Numerics are acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            baby_place_of_birth_pin_code: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="TOB"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Time Of Birth ( 0 - 23 Hrs)
                  </label>
                  <Grid container>
                    <Grid item xs={6} sm={6}>
                      <TextField
                        id="tobHrs"
                        name="baby_birth_time_hours"
                        className="dataentry_time"
                        variant="outlined"
                        size="small"
                        value={
                          addFormData?.baby_birth_time_hours &&
                          addFormData?.baby_birth_time_hours
                        }
                        onChange={(e) => {
                          handleAddFormChange(e);
                          verifyBabyBirthTimeHours(e);
                        }}
                        error={
                          errorFields.baby_birth_time_hours &&
                          showBabyBirthTimeHoursErrorMessage
                        }
                        errorText={showBabyBirthTimeHoursErrorMessage}
                        helperText={
                          errorFields.baby_birth_time_hours &&
                          showBabyBirthTimeHoursErrorMessage
                            ? showBabyBirthTimeHoursErrorMessage
                            : ""
                        }
                        onKeyDown={(event) => {
                          if (
                            !Numerics1.test(event.key) &&
                            event.key != "Backspace"
                          ) {
                            event.preventDefault();
                            setShowBabyBirthTimeHoursErrorMessage(
                              "Only Numerics are acceptable"
                            );
                            setErrorFields(
                              Object.assign({}, errorFields, {
                                baby_birth_time_hours: true,
                              })
                            );
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className="InputAdornment"
                            >
                              Hours
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <TextField
                        id="TobMins"
                        name="baby_birth_time_minit"
                        className="dataentry_time"
                        variant="outlined"
                        size="small"
                        //disabled={!defaultCheck.TimeOfBirthchecked}
                        value={
                          addFormData?.baby_birth_time_minit &&
                          addFormData?.baby_birth_time_minit
                        }
                        onChange={(e) => {
                          handleAddFormChange(e);
                          verifyBabyBirthTimeMins(e);
                        }}
                        error={
                          errorFields.baby_birth_time_minit &&
                          showBabyBirthTimeMinsErrorMessage
                        }
                        errorText={showBabyBirthTimeMinsErrorMessage}
                        helperText={
                          errorFields.baby_birth_time_minit &&
                          showBabyBirthTimeMinsErrorMessage
                            ? showBabyBirthTimeMinsErrorMessage
                            : ""
                        }
                        onKeyDown={(event) => {
                          if (
                            !Numerics1.test(event.key) &&
                            event.key != "Backspace"
                          ) {
                            event.preventDefault();
                            setShowBabyBirthTimeMinsErrorMessage(
                              "Only Numerics are acceptable"
                            );
                            setErrorFields(
                              Object.assign({}, errorFields, {
                                baby_birth_time_minit: true,
                              })
                            );
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className="InputAdornment"
                            >
                              Mins
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="Gender"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Gender
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.baby_gender}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="baby_gender"
                      value="Male"
                      size="small"
                      className="dataentry_input"
                    >
                      Male
                    </ToggleButton>
                    <ToggleButton
                      name="baby_gender"
                      value="Female"
                      size="small"
                      className="dataentry_input"
                    >
                      Female
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="DOA"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Date Of Admission
                  </label>
                  <DatePicker
                    id="baby_date_of_admission"
                    name="baby_date_of_admission"
                    size="small"
                    className="dataentry_input"
                    maxDate={moment().format()}
                    inputFormat="DD/MM/YYYY"
                    minDate={addFormData?.baby_birth_date}
                    value={addFormData?.baby_date_of_admission}
                    //disabled={!defaultCheck.DateOfAdmissionchecked}
                    onChange={(date) => {
                      //addFormData.baby_birth_date = date["$d"];
                      setFromDate(date["$d"]);
                      handleAddFormChange({
                        target: {
                          name: "baby_date_of_admission",
                          value: date["$d"],
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        className="dataentry_input"
                        helperText={null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="AAA"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Age at Admission
                  </label>
                  <TextField
                    id="ageAtAdmission"
                    name="baby_age_of_admission"
                    variant="outlined"
                    size="small"
                    className="dataentry_input"
                    //disabled={!defaultCheck.AgeAtAdmissionchecked}
                    value={AgeAtAdmission}
                    onChange={handleAddFormChange}
                    type="number"
                    disabled
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          Days
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="ApgarScore1Min"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Apgar Score 1 Min
                  </label>
                  <TextField
                    id="ApgarScore1Min"
                    name="baby_apgar_score_one_min"
                    variant="outlined"
                    size="small"
                    // className="dataentry_input"
                    className={
                      "dataentry_input " +
                      (errorFields.baby_apgar_score_one_min ? "errorField" : "")
                    }
                    //required
                    value={
                      addFormData?.baby_apgar_score_one_min &&
                      addFormData?.baby_apgar_score_one_min
                    }
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyApgarScore1Min(e);
                    }}
                    error={
                      errorFields.baby_apgar_score_one_min &&
                      showApgar1MinErrorMessage
                    }
                    errorText={showApgar1MinErrorMessage}
                    helperText={
                      errorFields.baby_apgar_score_one_min &&
                      showApgar1MinErrorMessage
                        ? showApgar1MinErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowApgar1MinErrorMessage(
                          "Only Numerics are acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            baby_apgar_score_one_min: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="ApgarScore5Min"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Apgar Score 5 Min
                  </label>
                  <TextField
                    id="ApgarScore5Min"
                    name="baby_apgar_score_five_min"
                    variant="outlined"
                    size="small"
                    //className="dataentry_input"
                    className={
                      "dataentry_input " +
                      (errorFields.baby_apgar_score_five_min
                        ? "errorField"
                        : "")
                    }
                    value={
                      addFormData?.baby_apgar_score_five_min &&
                      addFormData?.baby_apgar_score_five_min
                    }
                    //required
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyApgarScore5Min(e);
                    }}
                    error={
                      errorFields.baby_apgar_score_five_min &&
                      showApgar5MinErrorMessage
                    }
                    errorText={showApgar5MinErrorMessage}
                    helperText={
                      errorFields.baby_apgar_score_five_min &&
                      showApgar5MinErrorMessage
                        ? showApgar5MinErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowApgar5MinErrorMessage(
                          "Only Numerics are acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            baby_apgar_score_five_min: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="ApgarScore10Min"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Apgar Score 10 Min
                  </label>
                  <TextField
                    id="ApgarScore10Min"
                    name="baby_apgar_score_ten_min"
                    variant="outlined"
                    size="small"
                    //className="dataentry_input"
                    className={
                      "dataentry_input " +
                      (errorFields.baby_apgar_score_ten_min ? "errorField" : "")
                    }
                    //disabled={!defaultCheck.ApgarScore10Minchecked}
                    value={
                      addFormData?.baby_apgar_score_ten_min &&
                      addFormData?.baby_apgar_score_ten_min
                    }
                    //required
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyApgarScore10Min(e);
                    }}
                    error={
                      errorFields.baby_apgar_score_ten_min &&
                      showApgar10MinErrorMessage
                    }
                    errorText={showApgar10MinErrorMessage}
                    helperText={
                      errorFields.baby_apgar_score_ten_min &&
                      showApgar10MinErrorMessage
                        ? showApgar10MinErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowApgar10MinErrorMessage(
                          "Only Numerics are acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            baby_apgar_score_ten_min: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="ApgarScore1Min"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Place Of Delivery
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.place_of_delivery}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="place_of_delivery"
                      value="HOSPITAL"
                      size="small"
                      className="dataentry_input"
                    >
                      Hospital
                    </ToggleButton>
                    <ToggleButton
                      name="place_of_delivery"
                      value="HOME"
                      size="small"
                      className="dataentry_input"
                    >
                      Home
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="Gestational Age"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Gestational Age
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    name="baby_gestational_age"
                    id="GestationalAge"
                    variant="outlined"
                    size="small"
                    //required
                    className={
                      "dataentry_input " +
                      (errorFields.baby_gestational_age ? "errorField" : "")
                    }
                    //className="dataentry_input"
                    //disabled={!defaultCheck.GestationalAgechecked}
                    value={
                      addFormData?.baby_gestational_age &&
                      addFormData?.baby_gestational_age
                    }
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyGestationalAge(e);
                    }}
                    error={
                      errorFields.baby_gestational_age &&
                      showGestationalAgeMinErrorMessage
                    }
                    errorText={showGestationalAgeMinErrorMessage}
                    helperText={
                      errorFields.baby_gestational_age &&
                      showGestationalAgeMinErrorMessage
                        ? showGestationalAgeMinErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowGestationalAgeMinErrorMessage(
                          "Only Numerics are acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            baby_gestational_age: true,
                          })
                        );
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          Weeks
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="Preterm"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Preterm
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    id="Pretern"
                    value={addFormData?.baby_preterm}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    //required
                  >
                    <ToggleButton
                      name="baby_preterm"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="baby_preterm"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      value="NA"
                      id="baby_preterm"
                      name="baby_preterm"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="Weight At Birth"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Weight at Birth
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <Grid container>
                    <Grid item xs={12} sm={8}>
                      <TextField
                        id="babyweightatbirth"
                        label=""
                        name="baby_weight_at_birth"
                        value={
                          addFormData?.baby_weight_at_birth &&
                          addFormData?.baby_weight_at_birth
                        }
                        //required
                        variant="outlined"
                        size="small"
                        className="dataentry_input"
                        onChange={(e) => {
                          handleAddFormChange(e);
                          verifyBabyWeightAtBirth(e);
                          setBabyWeightAtBirth(e.target.value);
                        }}
                        error={
                          errorFields.baby_weight_at_birth &&
                          showBabyWtAtBirthErrorMessage
                        }
                        errorText={showBabyWtAtBirthErrorMessage}
                        helperText={
                          errorFields.baby_weight_at_birth &&
                          showBabyWtAtBirthErrorMessage
                            ? showBabyWtAtBirthErrorMessage
                            : ""
                        }
                        onKeyDown={(event) => {
                          if (
                            !Numerics.test(event.key) &&
                            event.key != "Backspace"
                          ) {
                            event.preventDefault();
                            setShowBabyWtAtBirthErrorMessage(
                              "Only Numerics are acceptable"
                            );
                            setErrorFields(
                              Object.assign({}, errorFields, {
                                baby_weight_at_birth: true,
                              })
                            );
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Select
                        labelId="wt"
                        id="wt"
                        name="baby_weight_at_birth_unit"
                        value={addFormData?.baby_weight_at_birth_unit ? addFormData?.baby_weight_at_birth_unit
                          : "Kgs"}
                        label=""
                        //onChange={handleAddFormChange}
                        size="small"
                        className="dataentry_select"
                        onChange={(e) => {
                          handleAddFormChange(e);
                          setBabyWeightAtBirthType(e.target.value);
                          verifyBabyWeightType(e);
                        }}
                      >
                        <MenuItem value="Kgs">Kgs</MenuItem>
                        <MenuItem value="lbs">Lbs</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="Weight At Admission"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Weight at Admission
                  </label>
                  <Grid container>
                    <Grid item xs={12} sm={8}>
                      <TextField
                        id="babyweightatadmission"
                        name="baby_weight_at_admission"
                       value={
                          addFormData?.baby_weight_at_admission &&
                          addFormData?.baby_weight_at_admission
                        }
                        
                        label=""
                        variant="outlined"
                        size="small"
                        className="dataentry_input"
                        onChange={(e) => {
                          handleAddFormChange(e);
                          verifyBabyWeightAtAdmission(e);
                          setBabyWeightAtAdmission(e.target.value);
                        }}
                        error={
                          errorFields.baby_weight_at_admission &&
                          showBabyWtAtAdmissionErrorMessage
                        }
                        errorText={showBabyWtAtAdmissionErrorMessage}
                        helperText={
                          errorFields.baby_weight_at_admission &&
                          showBabyWtAtAdmissionErrorMessage
                            ? showBabyWtAtAdmissionErrorMessage
                            : ""
                        }
                        onKeyDown={(event) => {
                          if (
                            !Numerics.test(event.key) &&
                            event.key != "Backspace"
                          ) {
                            event.preventDefault();
                            setShowBabyWtAtAdmissionErrorMessage(
                              "Only Numerics are acceptable"
                            );
                            setErrorFields(
                              Object.assign({}, errorFields, {
                                baby_weight_at_admission: true,
                              })
                            );
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Select
                        labelId="wt"
                        id="wt"
                        name="baby_weight_at_admission_unit"
                        //value={addFormData?.baby_weight_at_admission_unit}
                        label=""
                        onChange={(e) => {
                          handleAddFormChange(e);
                          setBabyWeightAtAdmissionType(e.target.value);
                          verifyBabyWeightAtAdmissionType(e);
                        }}
                        value={addFormData?.baby_weight_at_admission_unit ? addFormData?.baby_weight_at_admission_unit
                          : "kgs"}
                        size="small"
                        className="dataentry_select"
                      >
                        <MenuItem value="kgs">Kgs</MenuItem>
                        <MenuItem value="lbs">Lbs</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="Day of Sepsis Event"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Day of Sepsis Event
                  </label>
                  <TextField
                    id="DayofSepsisEvent"
                    name="baby_day_of_event"
                    label=""
                    variant="outlined"
                    size="small"
                    value={
                      addFormData?.baby_day_of_event &&
                      addFormData?.baby_day_of_event
                    }
                    //className="dataentry_input"
                    className={
                      "dataentry_input " +
                      (errorFields.baby_day_of_event ? "errorField" : "")
                    }
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyDayofSepsisEvent(e);
                    }}
                    error={
                      errorFields.baby_day_of_event &&
                      showDayofSepsisEventErrorMessage
                    }
                    errorText={showDayofSepsisEventErrorMessage}
                    helperText={
                      errorFields.baby_day_of_event &&
                      showDayofSepsisEventErrorMessage
                        ? showDayofSepsisEventErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowDayofSepsisEventErrorMessage(
                          "Only Numerics are acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            baby_day_of_event: true,
                          })
                        );
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          Days
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="Diagnosis (Probable Sepsis)"
                    style={{ display: "block" }}
                    baby_condition_suspect
                    className="dataentry_label"
                  >
                    Diagnosis (Probable Sepsis)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.baby_condition_suspect}
                    name="baby_condition_suspect"
                    id="diagnosis"
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="baby_condition_suspect"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="baby_condition_suspect"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      value="NA"
                      id="baby_condition_suspect"
                      name="baby_condition_suspect"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="Diagnosis (EOS/LOS/NA)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (EOS/LOS/NA)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.baby_condition_yes_eos_los}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="baby_condition_yes_eos_los"
                      value="EOS"
                      size="small"
                      className="dataentry_input"
                    >
                      EOS
                    </ToggleButton>
                    <ToggleButton
                      name="baby_condition_yes_eos_los"
                      value="LOS"
                      size="small"
                      className="dataentry_input"
                    >
                      LOS
                    </ToggleButton>
                    <ToggleButton
                      value="NA"
                      id="baby_condition_yes_eos_los"
                      name="baby_condition_yes_eos_los"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="Diagnosis (Meningitis)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (Meningitis)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.meningitis}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="meningitis"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="meningitis"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      value="NA"
                      id="meningitis"
                      name="meningitis"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="Diagnosis (Umbical Sepsis)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (Umbical Sepsis)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.umblical_sepsis}
                    id="diagnosis"
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="umblical_sepsis"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="umblical_sepsis"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      value="NA"
                      id="umblical_sepsis"
                      name="umblical_sepsis"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="Diagnosis (Skin Pustules)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (Skin Pustules)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.skin_pustules}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="skin_pustules"
                      value="+"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="skin_pustules"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      value="NA"
                      id="skin_pustules"
                      name="skin_pustules"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="Diagnosis (RDS)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (RDS)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.diagMRDS}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="diagMRDS"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="diagMRDS"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      value="NA"
                      id="diagMRDS"
                      name="diagMRDS"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="Diagnosis (Jaundice)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (Jaundice)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.baby_condition_jaundice_suspect}
                    id="diagnosis"
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="baby_condition_jaundice_suspect"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="baby_condition_jaundice_suspect"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="baby_condition_jaundice_suspect"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="Diagnosis (LBW)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (LBW)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.baby_condition_lbw_suspect}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="baby_condition_lbw_suspect"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="baby_condition_lbw_suspect"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="baby_condition_lbw_suspect"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="Diagnosis (TTNB)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (TTNB)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.baby_condition_ttnb_suspect}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="baby_condition_ttnb_suspect"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="baby_condition_ttnb_suspect"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="baby_condition_ttnb_suspect"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="Diagnosis (LGA/AGA/SGA/NA)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (LGA/AGA/SGA/NA)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.baby_lga_sga_aga_suspect}
                    id="diagnosis"
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="baby_lga_sga_aga_suspect"
                      value="LGA"
                      size="small"
                      className="dataentry_input"
                    >
                      LGA
                    </ToggleButton>
                    <ToggleButton
                      name="baby_lga_sga_aga_suspect"
                      value="AGA"
                      size="small"
                      className="dataentry_input"
                    >
                      AGA
                    </ToggleButton>
                    <ToggleButton
                      name="baby_lga_sga_aga_suspect"
                      value="SGA"
                      size="small"
                      className="dataentry_input"
                    >
                      SGA
                    </ToggleButton>
                    <ToggleButton
                      name="baby_lga_sga_aga_suspect"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="Diagnosis (Congenital Heart Disease)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (Congenital Heart Disease)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.diagCongenitalHeartDisease}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="diagCongenitalHeartDisease"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="diagCongenitalHeartDisease"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="diagCongenitalHeartDisease"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="Diagnosis (Anemia)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (Anemia)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    name="baby_condition_anemia_suspect"
                    value={addFormData?.baby_condition_anemia_suspect}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="baby_condition_anemia_suspect"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="baby_condition_anemia_suspect"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="baby_condition_anemia_suspect"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="Diagnosis (Perinatal Respiratory Depression)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (Perinatal Respiratory Depression)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.prelim_diagnosis_perinatal}
                    id="prelim_diagnosis_perinatal"
                    name="prelim_diagnosis_perinatal"
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="prelim_diagnosis_perinatal"
                      value="YES"
                      className="dataentry_input"
                      size="small"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="prelim_diagnosis_perinatal"
                      value="NO"
                      className="dataentry_input"
                      size="small"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="prelim_diagnosis_perinatal"
                      value="NA"
                      className="dataentry_input"
                      size="small"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="Diagnosis (Hypoglycemia)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (Hypoglycemia)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.prelim_diagnosis_hypoglycemia}
                    id="Hypoglycemia"
                    name="prelim_diagnosis_hypoglycemia"
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="prelim_diagnosis_hypoglycemia"
                      value="YES"
                      className="dataentry_input"
                      size="small"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="prelim_diagnosis_hypoglycemia"
                      value="NO"
                      className="dataentry_input"
                      size="small"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="prelim_diagnosis_hypoglycemia"
                      value="NA"
                      className="dataentry_input"
                      size="small"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="Diagnosis (Hypocalcemia)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (Hypocalcemia)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.prelim_diagnosis_hypocalcemia}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="prelim_diagnosis_hypocalcemia"
                      value="YES"
                      className="dataentry_input"
                      size="small"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="prelim_diagnosis_hypocalcemia"
                      value="NO"
                      className="dataentry_input"
                      size="small"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="prelim_diagnosis_hypocalcemia"
                      value="NA"
                      className="dataentry_input"
                      size="small"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="Diagnosis (Feeding Intolerance)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (Feeding Intolerance)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.prelim_diagnosis_feeding_intolerence}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="prelim_diagnosis_feeding_intolerence"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="prelim_diagnosis_feeding_intolerence"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="prelim_diagnosis_feeding_intolerence"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="Diagnosis (Thrombocytopenia)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (Thrombocytopenia)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.thrombocytopenia}
                    exclusive
                    onChange={handleAddFormChange}
                    size="small"
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="thrombocytopenia"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="thrombocytopenia"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="thrombocytopenia"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label
                    htmlFor="Diagnosis (Seizures)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (Seizures)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addFormData?.seizures}
                    exclusive
                    onChange={handleAddFormChange}
                    size="small"
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="seizures"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="seizures"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="seizures"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
              {/*  <Box sx={{ "& > :not(style)": { m: 1 } }}>
              <Button
                variant="outlined"
                color="primary"
                aria-label="add"
                className="floating-btn"
                type="submit"
              >
                Save {"&"} Next
              </Button>
            </Box> */}
            </form>
          </CardContent>
        </Card>
      </LocalizationProvider>
    </React.Fragment>
  );
};

export default BabyGeneral;
