import {
  BMRNFormMock,
  babyFormMock,
  motherFormMock,
  babyHealthParametersFormMock,
  babyCNSDataMock,
  babyGITDataMock,
  referralDoctorDataMock,
  babyCVDataMock,
  babyFinalDiagnosis,
  refDocSelectMock,
} from "../utils/mockData";

export const initialState = {
  formTab: null,
  isMotherEdit: false,
  isBabyEdit: false,
  isHealthParameters: false,
  isBabyCVEdit: false,
  isBabyFinalDiagEdit: false,
  editBmrnData: false,
  isBabyCNS: false,
  isBabyGIT: false,
  isBabyApperance: false,
  isBabyRespiratory: false,
  isBabyInv: false,
  checkedFinalDiagnosis: true,
  DeleteReading: {},
  generateScore: {},
  updateScore: {},
  getHealthParameters: {},
  getbabyAppearbyreadingid: {},
  babyCNSDatabyreadingid: {},
  BabyCVFByReadingId: {},
  BabyGitByReadingId: {},
  babyRespiratoryDataByReadingId: {},
  BabyAntibioticDataByReadingId: {},
  BabyInvDataByReadingId: {},
  BabyFinalDiagByReadingId: {},
  getBabyProfile: { ...babyFormMock },
  getMotherProfile: { ...motherFormMock },
  Selectedreadingid: {},
  bmrnList: [],
  patientList: [],
  patientReadings: [],
  selectedEntityType: "Non-Invasive",
  bmrndata: { ...BMRNFormMock },
  referralDoctorData: { ...referralDoctorDataMock },
  globalRecord: {},
  getexistingbmrns: {},
  errorMessage: "",
  successMessage: "",
  babyData: { ...babyFormMock },
  motherData: { ...motherFormMock },
  //babyHealthParameters: { ...babyHealthParametersFormMock },
  babyCNSData: { ...babyCNSDataMock },
  babyGITData: { ...babyGITDataMock },
  babyFinalDiagData: { ...babyFinalDiagnosis },
  babyCVData: { ...babyCVDataMock },
  refDocSelect: { ...refDocSelectMock },
  getBabyQuickSepsis: {},
  BabySemiInvasive: {},

  GenerateScore: {
    scoreData: "",
    color: "",
    risk: "",
    staff_id: "",
    branch_id: "",
  },
  score: {},
  
  babyHealthParameters: {
  baby_appearance: "NA",
  baby_skin_colour: "",
  baby_cry_sound_status: "",
  breathing_rate: "",
  baby_chest_indrawing: "NA",
  heart_rate: "",
  baby_blood_pressure_upper_limb: "",
  baby_blood_pressure_lower_limb: "",
  baby_blood_pressure_mean_arterial_bp: "",
  frequency_of_stools: "",
  baby_respiratory_support: "NA",
  baby_movement: "NA",
},
  babyappearance: {
    study_id: "",
    ward_id: "",
    reading_date: "",
    baby_appearance: "NA",
    time_of_reading_hours: "",
    time_of_reading_minute: "",
    baby_weight_at_birth: "",
    baby_weight_at_birth_unit: "kgs",
    baby_skin_colour: "",
    baby_cry_sound: "",
    baby_cry_sound_status: "",
    hypotonia_muscular_response_one_min_after_birth: "NA",
    hypotonia_muscular_response_five_min_after_birth: "NA",
    excessive_sleeping: "NA",
    hypothermia: "NA",
    hypothermia_status_value: "",
    hypothermia_status: "Fahrenheit",
    baby_feeding_status: "NA",
    baby_presence_of_convulsions: "NA",
    baby_jaundice: "NA",
    breast_feeding_initiation: "NA",
    kangaroo_mother_care: "NA",
    umbilical_redness: "NA",
    umbilical_enduration: "NA",
    umbilical_discharge: "NA",
    skin_pustules: "NA",
  },
  babyRespiratoryData: {
    study_id: "",
    groaning: "NA",
    stridor: "NA",
    grunting: "NA",
    retraction: "NA",
    fast_breathing: "NA",
    oxygen_saturation: "",
    breathing_rate: "",
    baby_chest_indrawing: "NA",
    x_ray_status_done: "NA",
    x_ray_result: "NA",
    x_ray_diagnosis_any_other: "",
    apnea_status: "NA",
    apnea_diagnosis: "",
    baby_respiratory_support: "NA",
    baby_respiratory_support_if_yes: [],
    x_ray_status: "",
  },

  babyInvData: {
    study_id: "",
    baby_thyroid_status: "NA",
    baby_thyroid_result: "",
    baby_thyroid_t3: "",
    baby_thyroid_t4: "",
    baby_blood_glucose: "",
    baby_haemoglobin_levels: "",
    baby_c_reactive_protien_levels: "",
    baby_c_reactive_protien_result: "NA",
    absolute_neutrophil_count: "",
    total_leucocute_count: "",
    prothrombin_type: "",
    urine_rest_for_pus_cells: "NA",
    urine_culture_test: "NA",
    micro_esr: "",
    baby_procalcitonin_levels: "",
    prothrombin_type: "",
    activated_partial_prothrombine_type: "",
    total_leucocute_count: "",
    absolute_neutrophil_count: "",
    immature_to_mature_neutrophil_ratios: "NA",
    thrombocytopenia: "",
    thrombocytopenia_unit: "",
    urine_rest_for_pus_cells: "NA",
    urine_culture_test: "NA",
    blood_culture_report: "NA",
    gram_positive_bacteria: [],
    gram_positive_bacteria_if_other: "",
    gram_negative_bacteria: [],
    gram_negative_bacteria_if_other: "",
    fungi: [],
    antibiotic_status: [],
    antibiotic_status_resisitant: [],
    antibiotic_status_intermediate: [],
    other_organism: "",
    sodium: "",
    potassium: "",
    chlorine: "",
    calcium: "",
    phosphate: "",
    magnesium: "",
    urea: "",
    creatinine: "",
    lactate_levels: "",
    bilirubin_levels: "",
    cord_ph: "",
    arrhythmia: "NA",
    thrombocytopenia_unit: "",
    csf_culture: "NA",
    csf_culture_tsb_value: "",
  },

  babyAntibioticData: {
    study_id: "",
    antibiotic_given: "NA",
    date_of_administration_of_antiobiotic: "",
    time_of_administration_of_antiobiotic_hours: "",
    time_of_administration_of_antiobiotic_minute: "",
    antibiotic_name: [],
    antibiotic_name_if_other: "",
    date_of_blood_samples_sent_for_culture_test: "",
    time_of_blood_samples_sent_for_culture_test_hours: "",
    time_of_blood_samples_sent_for_culture_test_minute: "",
    blood_sample_taken_prior_to_antiobiotic_administration: "NA",
  },
};
