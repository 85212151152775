import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  Checkbox,
  ListItemText,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Card,
  CardContent,
  MenuItem,
  Select,
  Button,
  InputAdornment,
  Snackbar,
  Typography,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

const NUMERIC = /^[0-9-.]+$/;

import "../styles/DataEntry.scss";

import {
  setBabyInvData,
  babyInvestigationAddition,
  updatePatientFormData,
  getBabyInvDataByReadingId,
  setFormTab,
  getPatientLastReadingData
} from "../reducers/patientDataThunk";

import {
  GRAM_POSITIVE,
  GRAM_NEGATIVE,
  FUNGI,
  OTH_ORG,
  ANTI_SENSITIVE,
  ANTI_RESISTANT,
  ANTI_INTERMEDIATE,
} from "../utils/mockData";
const Numerics = /^[0-9 ]*$/;
const Numerics1 = /^[0-9 .]*$/;

import CircularProgress from "@mui/material/CircularProgress";

const BabyInvestigation = (props) => {
  const patient = useSelector((state) => state.patient);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const study_id = patient.bmrndata?.study_id;
  const studyid = patient.bmrndata?.study_id;
  let reading = patient?.Selectedreadingid;
  const readingId =
    JSON.stringify(reading) != "{}" ? reading : patient?.bmrndata?.reading_id;
  const loggedUserId = user.userProfile?.user_id;
  const [addBabyInvData, setAddBabyInvData] = useState({
    gram_positive_bacteria: [],
    gram_negative_bacteria: [],
    fungi: [],
    antibiotic_status: [],
    antibiotic_status_resisitant: [],
    antibiotic_status_intermediate: [],
  });

  let url = `patient/update/baby_investigation`;

  const [updateRecord, setUpdateRecord] = useState(false);
  const [
    showBabyThyroidResultErrorMessage,
    setShowBabyThyroidResultErrorMessage,
  ] = useState();
  const [
    showBabyThyroidResultT3ErrorMessage,
    setShowBabyThyroidResultT3ErrorMessage,
  ] = useState();
  const [
    showBabyThyroidResultT4ErrorMessage,
    setShowBabyThyroidResultT4ErrorMessage,
  ] = useState();
  const [
    showBabyBloodGlucoseErrorMessage,
    setShowBabyBloodGlucoseErrorMessage,
  ] = useState();
  const [
    showBabyHaemoglobinLevelsErrorMessage,
    setShowBabyHaemoglobinLevelsErrorMessage,
  ] = useState();
  const [showCRPLevelErrorMessage, setShowCRPLevelErrorMessage] = useState();
  const [showMicroESRErrorMessage, setShowMicroESRErrorMessage] = useState();
  const [
    showProcalcitoninLevelErrorMessage,
    setShowProcalcitoninLevelErrorMessage,
  ] = useState();
  const [showProthrombinTypeErrorMessage, setShowProthrombinTypeErrorMessage] =
    useState();
  const [
    showActivatedPartialProthrombinErrorMessage,
    setShowActivatedPartialProthrombinErrorMessage,
  ] = useState();
  const [
    showTotalLeucocuteCountErrorMessage,
    setShowTotalLeucocuteCountErrorMessage,
  ] = useState();
  const [
    showAbsoluteNeutrophilCountErrorMessage,
    setShowAbsoluteNeutrophilCountErrorMessage,
  ] = useState();
  const [errorFields, setErrorFields] = useState({});
  const [
    showThrombocytopeniaErrorMessage,
    setShowThrombocytopeniaErrorMessage,
  ] = useState();
  const [showSodiumErrorMessage, setShowSodiumErrorMessage] = useState();
  const [showPotassiumErrorMessage, setShowPotassiumErrorMessage] = useState();
  const [showChlorineErrorMessage, setShowChlorineErrorMessage] = useState();
  const [showCalciumErrorMessage, setShowCalciumErrorMessage] = useState();
  const [showPhosphateErrorMessage, setShowPhosphateErrorMessage] = useState();
  const [showMagnesiumErrorMessage, setShowMagnesiumErrorMessage] = useState();
  const [showUreaErrorMessage, setShowUreaErrorMessage] = useState();
  const [showCreatinineErrorMessage, setShowCreatinineErrorMessage] =
    useState();
  const [showLactateLevelsErrorMessage, setShowLactateLevelsErrorMessage] =
    useState();
  const [showBilirubinLevelsErrorMessage, setShowBilirubinLevelsErrorMessage] =
    useState();
  const [showCordPHErrorMessage, setShowCordPHErrorMessage] = useState();
  const [
    showCSFCultureTSBValueErrorMessage,
    setShowCSFCultureTSBValueErrorMessage,
  ] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setValid] = React.useState(true);

  useEffect(() => {
    verifyErrorFields();
  }, [errorFields]);

  const verifyErrorFields = async () => {
    let enableSubmitButton = true;
    const keys = Object.keys(errorFields);
    keys.map((key) => {
      errorFields[key] !== false ? (enableSubmitButton = false) : null;
    });
    setValid(enableSubmitButton);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const hasOthers = (selectedOpts) => {
    const test =
      selectedOpts && selectedOpts.length
        ? selectedOpts.filter((s) => s.itemName === "Others")
        : [];
    return test.length > 0;
  };

  const gnOthers = (selectedOpts) => {
    const test =
      selectedOpts && selectedOpts.length
        ? selectedOpts.filter((s) => s.itemName === "Others")
        : [];
    return test.length > 0;
  };

  useEffect(() => {
    if (patient?.bmrndata?.reading_id && patient.bmrndata?.study_id) {
      updateData(patient?.bmrndata?.reading_id, patient.bmrndata?.study_id);
    }
  }, [patient?.bmrndata?.reading_id, patient.bmrndata?.study_id]);

  const updateData = (readingId, studyid) => {
    const req = { readingId, studyid };
    dispatch(getBabyInvDataByReadingId(req))
      .unwrap()
      .then((resdata) => {
        if(resdata.response){
        let babyInvData = {
          response: Object.assign({}, resdata.response),
        };
        const data =
          babyInvData.response && babyInvData.response.gram_positive_bacteria
            ? (babyInvData.response.gram_positive_bacteria = JSON.parse(
                babyInvData.response.gram_positive_bacteria
              ))
            : undefined;
        const data1 =
          babyInvData.response && babyInvData.response.gram_negative_bacteria
            ? (babyInvData.response.gram_negative_bacteria = JSON.parse(
                babyInvData.response.gram_negative_bacteria
              ))
            : undefined;
        const data2 =
          babyInvData.response && babyInvData.response.fungi
            ? (babyInvData.response.fungi = JSON.parse(
                babyInvData.response.fungi
              ))
            : undefined;

        const data3 =
          babyInvData.response && babyInvData.response.antibiotic_status
            ? (babyInvData.response.antibiotic_status = JSON.parse(
                babyInvData.response.antibiotic_status
              ))
            : undefined;
        const data4 =
          babyInvData.response &&
          babyInvData.response.antibiotic_status_resisitant
            ? (babyInvData.response.antibiotic_status_resisitant = JSON.parse(
                babyInvData.response.antibiotic_status_resisitant
              ))
            : undefined;

        const data5 =
          babyInvData.response &&
          babyInvData.response.antibiotic_status_intermediate
            ? (babyInvData.response.antibiotic_status_intermediate = JSON.parse(
                babyInvData.response.antibiotic_status_intermediate
              ))
            : undefined;

        const data11 =
          babyInvData.response && babyInvData.response.id
            ? setUpdateRecord(true)
            : setUpdateRecord(false);
        if (resdata.response) {
          setAddBabyInvData(
            babyInvData.response && JSON.stringify(babyInvData.response) != "{}"
              ? babyInvData.response
              : {
                  gram_positive_bacteria: [],
                  gram_negative_bacteria: [],
                  fungi: [],
                  antibiotic_status: [],
                  antibiotic_status_resisitant: [],
                  antibiotic_status_intermediate: [],
                }
          );
        }
      }
      else{
        const study_id = patient?.bmrndata?.study_id;
        //const req = { studyid };
    dispatch(getPatientLastReadingData(study_id)) 
    .unwrap()
          .then((resdata) => {
            if(resdata.response){
            let babyInvData = {
              response: Object.assign({}, resdata.response.baby_investigation),
            };
            const data =
              babyInvData.response && babyInvData.response.gram_positive_bacteria
                ? (babyInvData.response.gram_positive_bacteria = JSON.parse(
                    babyInvData.response.gram_positive_bacteria
                  ))
                : undefined;
            const data1 =
              babyInvData.response && babyInvData.response.gram_negative_bacteria
                ? (babyInvData.response.gram_negative_bacteria = JSON.parse(
                    babyInvData.response.gram_negative_bacteria
                  ))
                : undefined;
            const data2 =
              babyInvData.response && babyInvData.response.fungi
                ? (babyInvData.response.fungi = JSON.parse(
                    babyInvData.response.fungi
                  ))
                : undefined;
    
            const data3 =
              babyInvData.response && babyInvData.response.antibiotic_status
                ? (babyInvData.response.antibiotic_status = JSON.parse(
                    babyInvData.response.antibiotic_status
                  ))
                : undefined;
            const data4 =
              babyInvData.response &&
              babyInvData.response.antibiotic_status_resisitant
                ? (babyInvData.response.antibiotic_status_resisitant = JSON.parse(
                    babyInvData.response.antibiotic_status_resisitant
                  ))
                : undefined;
    
            const data5 =
              babyInvData.response &&
              babyInvData.response.antibiotic_status_intermediate
                ? (babyInvData.response.antibiotic_status_intermediate = JSON.parse(
                    babyInvData.response.antibiotic_status_intermediate
                  ))
                : undefined;
    
            const data11 =
              babyInvData.response && babyInvData.response.id
                ? setUpdateRecord(false)
                : setUpdateRecord(false);
            if (resdata.response) {
              setAddBabyInvData(
                babyInvData.response && JSON.stringify(babyInvData.response) != "{}"
                  ? babyInvData.response
                  : {
                      gram_positive_bacteria: [],
                      gram_negative_bacteria: [],
                      fungi: [],
                      antibiotic_status: [],
                      antibiotic_status_resisitant: [],
                      antibiotic_status_intermediate: [],
                    }
              );
            }
          }
        }) 
      }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChange = (event) => {
    const fieldname = event.target.name;
    let fieldvalue = event.target.value;
    if (
      fieldname == "gram_positive_bacteria" ||
      fieldname == "gram_negative_bacteria" ||
      fieldname == "fungi" ||
      fieldname == "antibiotic_status" ||
      fieldname == "antibiotic_status_resisitant" ||
      fieldname == "antibiotic_status_intermediate"
    ) {
      let updatedValue = event.target.value.map((data) => {
        return data.itemName ? data.itemName : data;
      });
      let value = updatedValue;
      updatedValue.indexOf(updatedValue[updatedValue.length - 1]) !=
      updatedValue.length - 1
        ? (value = updatedValue.filter(
            (br) => br !== updatedValue[updatedValue.length - 1]
          ))
        : null;
      fieldvalue = value;
    }
    const newFormData = { ...addBabyInvData };
    newFormData[fieldname] = fieldvalue;
    setAddBabyInvData(newFormData);
  };
  const handleAddFormChange = (event) => {
    // console.log("We are in handle form change");
    const fieldname = event.target.name;
    // console.log("Field Name is ");
    // console.log(fieldname);

    const fieldvalue = event.target.value;
    // console.log("Field Value is ");
    // console.log(fieldvalue);

    const newFormData = { ...addBabyInvData };
    newFormData[fieldname] = fieldvalue;

    // console.log("Field Value is ");
    // console.log(newFormData);

    setAddBabyInvData(newFormData);
    setBabyInvData(newFormData);
  };

  const HandleAddFormSubmit = async (event) => {
    event.preventDefault();

    await submitData(9);
  };

  useEffect(() => {
    if (props.triggerChildFunction) submitData();
  }, [props.triggerChildFunction]);

  const submitData = async (nextTab) => {
    const newFormData = { ...addBabyInvData };
    // console.log("Data That the Application is sending is,");
    // console.log(newFormData);
    // console.log("About to call Axios API");
    for (const key in newFormData) {
      if (!newFormData[key]) {
        newFormData[key] = "NA";
      }
    }

    newFormData.study_id = patient.bmrndata?.study_id;
    newFormData.id = "";
    newFormData.reading =
      JSON.stringify(readingId) != "{}"
        ? readingId
        : patient?.bmrndata?.reading_id;
    newFormData.gram_positive_bacteria = JSON.stringify(
      newFormData.gram_positive_bacteria
    );
    newFormData.gram_negative_bacteria = JSON.stringify(
      newFormData.gram_negative_bacteria
    );
    newFormData.fungi = JSON.stringify(newFormData.fungi);
    newFormData.antibiotic_status = JSON.stringify(
      newFormData.antibiotic_status
    );
    newFormData.antibiotic_status_resisitant = JSON.stringify(
      newFormData.antibiotic_status_resisitant
    );
    newFormData.antibiotic_status_intermediate = JSON.stringify(
      newFormData.antibiotic_status_intermediate
    );
    if (!updateRecord) {
      dispatch(babyInvestigationAddition(newFormData))
        .unwrap()
        .then((resdata) => {
          const req = { readingId, studyid };
          dispatch(getBabyInvDataByReadingId(req));
          props.updateSessionData(nextTab ? nextTab : props.nextTab);
          dispatch(setFormTab(nextTab ? nextTab : props.nextTab));
          // console.log(resdata);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      // console.log("Inside baby Investigation update");
      // console.log(url);
      // console.log(study_id);
      // console.log(reading);
      // console.log(newFormData);
      const req = {
        url,
        study_id,
        reading:
          JSON.stringify(readingId) != "{}"
            ? readingId
            : patient?.bmrndata?.reading_id,
        data: newFormData,
        loggedUserId,
      };
      dispatch(updatePatientFormData(req))
        .unwrap()
        .then((resdata) => {
          const req = { readingId, studyid };
          dispatch(getBabyInvDataByReadingId(req));
          props.updateSessionData(nextTab ? nextTab : props.nextTab);
          dispatch(setFormTab(nextTab ? nextTab : props.nextTab));
          // console.log(resdata);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const verifyBabyThyroidResult = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 0,
      maxValue = 90;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_thyroid_result: false,
        })
      );
      setShowBabyThyroidResultErrorMessage("");
    } else {
      setShowBabyThyroidResultErrorMessage(
        "Baby Thyroid Result Value Should be Between 0 - 90, it should have only two decimal positions."
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_thyroid_result: true,
        })
      );
    }
  };

  const verifyBabyThyroidResultT3 = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 0,
      maxValue = 80;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_thyroid_t3: false,
        })
      );
      setShowBabyThyroidResultT3ErrorMessage("");
    } else {
      setShowBabyThyroidResultT3ErrorMessage(
        "Baby Thyroid Result T3 Value Should be Between 0 - 80, it should have only two decimal positions."
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_thyroid_t3: true,
        })
      );
    }
  };

  const verifyBabyThyroidResultT4 = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 0,
      maxValue = 15;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_thyroid_t4: false,
        })
      );
      setShowBabyThyroidResultT4ErrorMessage("");
    } else {
      setShowBabyThyroidResultT4ErrorMessage(
        "Baby Thyroid Result T4 Value Should be Between 0 - 15, it should have only two decimal positions."
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_thyroid_t4: true,
        })
      );
    }
  };

  const verifyBabyBloodGlucose = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 1,
      maxValue = 20;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_glucose: false,
        })
      );
      setShowBabyBloodGlucoseErrorMessage("");
    } else {
      setShowBabyBloodGlucoseErrorMessage(
        "Baby Blood Glucose Value Should be Between 0 - 20, it should have only two decimal positions."
      );
      //document.getElementById("BabyBloodGlucose").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_glucose: true,
        })
      );
    }
  };
  const verifyBabyHaemoglobinLevels = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 10,
      maxValue = 45;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_haemoglobin_levels: false,
        })
      );
      setShowBabyHaemoglobinLevelsErrorMessage("");
    } else {
      setShowBabyHaemoglobinLevelsErrorMessage(
        "Baby Haemoglobin Levels Value Should be Between 10 - 45, it should have only two decimal positions."
      );
      //document.getElementById("BabyHaemoglobinLevels").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_haemoglobin_levels: true,
        })
      );
    }
  };
  const verifyCReactiveProtien = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 1,
      maxValue = 20;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_c_reactive_protien_levels: false,
        })
      );
      setShowCRPLevelErrorMessage("");
    } else {
      setShowCRPLevelErrorMessage(
        "C Reactive Protien Value Should be Between 0 - 20, it should have only two decimal positions."
      );
      //document.getElementById("CReactiveProtien").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_c_reactive_protien_levels: true,
        })
      );
    }
  };
  const verifyMicroESR = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 1,
      maxValue = 20;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          micro_esr: false,
        })
      );
      setShowMicroESRErrorMessage("");
    } else {
      setShowMicroESRErrorMessage(
        "Micro ESR Value Should be Between 0 - 20, it should have only two decimal positions."
      );
      //document.getElementById("MicroESR").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          micro_esr: true,
        })
      );
    }
  };
  const verifyProcalcitoninLevel = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 1,
      maxValue = 150;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_procalcitonin_levels: false,
        })
      );
      setShowProcalcitoninLevelErrorMessage("");
    } else {
      setShowProcalcitoninLevelErrorMessage(
        "Procalcitonin Level Value should be between 1 - 150, it should have only two decimal positions."
      );
      //document.getElementById("ProcalcitoninLevel").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_procalcitonin_levels: true,
        })
      );
    }
  };
  const verifyProthrombinType = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 0,
      maxValue = 120;
    if (
      event.target.value <= maxValue &&
      event.target.value > minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          prothrombin_type: false,
        })
      );
      setShowProthrombinTypeErrorMessage("");
    } else {
      setShowProthrombinTypeErrorMessage(
        "Prothrombin Type Level Value Should be Between 0.1 - 120, it should have only two decimal positions."
      );
      //document.getElementById("ProthrombinType").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          prothrombin_type: true,
        })
      );
    }
  };
  const verifyTotalLeucocytesCount = (event) => {
    if (event.target.value > 1000 && event.target.value <= 500000) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          total_leucocute_count: false,
        })
      );
      setShowTotalLeucocuteCountErrorMessage("");
    } else {
      setShowTotalLeucocuteCountErrorMessage(
        "Total Leucocytes Count Value Should be Between 1000 - 500000."
      );
      //document.getElementById("TotalLeucocytesCount").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          total_leucocute_count: true,
        })
      );
    }
  };

  const verifyActivatedPartialProthrombin = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 0,
      maxValue = 120;
    if (
      event.target.value <= maxValue &&
      event.target.value > minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          activated_partial_prothrombine_type: false,
        })
      );
      setShowActivatedPartialProthrombinErrorMessage("");
    } else {
      setShowActivatedPartialProthrombinErrorMessage(
        "Activated Partial Prothrombin Value Should be Between 0.1 - 120."
      );
      //document.getElementById("ActivatedPartialProthrombin").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          activated_partial_prothrombine_type: true,
        })
      );
    }
  };

  const verifyAbsoluteNeutrophilCount = (event) => {
    if (event.target.value >= 0.1 && event.target.value <= 500000) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          absolute_neutrophil_count: false,
        })
      );
      setShowAbsoluteNeutrophilCountErrorMessage("");
    } else {
      setShowAbsoluteNeutrophilCountErrorMessage(
        "Absolute Neutrophil Count Value Should be Between 500 - 500000"
      );
      //document.getElementById("AbsoluteNeutrophilCount").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          absolute_neutrophil_count: true,
        })
      );
    }
  };

  const verifyThrombocytopenia = (event) => {
    if (event.target.value >= 1 && event.target.value <= 500000) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          thrombocytopenia: false,
        })
      );
      setShowThrombocytopeniaErrorMessage("");
    } else {
      setShowThrombocytopeniaErrorMessage(
        "Thrombocytopenia Should be Between 1 - 500000"
      );
      //document.getElementById("Thrombocytopenia").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          thrombocytopenia: true,
        })
      );
    }
  };

  const verifyPotassium = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");

    let minValue = 0,
      maxValue = 20;
    if (
      event.target.value <= maxValue &&
      event.target.value > minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          potassium: false,
        })
      );
      setShowPotassiumErrorMessage("");
    } else {
      setShowPotassiumErrorMessage(
        "Potassium Value Should be Between 0.1 - 20, it should have only two decimal positions."
      );
      //document.getElementById("Potassium").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          potassium: true,
        })
      );
    }
  };

  const verifyChlorine = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 0,
      maxValue = 200;
    if (
      event.target.value <= maxValue &&
      event.target.value > minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          chlorine: false,
        })
      );
      setShowChlorineErrorMessage("");
    } else {
      setShowChlorineErrorMessage(
        "Chlorine Value Should be Between 0.1 - 200, it should have only two decimal positions."
      );
      //document.getElementById("Chlorine").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          chlorine: true,
        })
      );
    }
  };

  const verifyCalcium = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");

    let minValue = 0,
      maxValue = 20;
    if (
      event.target.value <= maxValue &&
      event.target.value > minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          calcium: false,
        })
      );
      setShowCalciumErrorMessage("");
    } else {
      setShowCalciumErrorMessage(
        "Calcium Value Should be Between 0.1 - 20, it should have only two decimal positions."
      );
      //document.getElementById("Calcium").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          calcium: true,
        })
      );
    }
  };

  const verifyPhosphate = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");

    let minValue = 0,
      maxValue = 20;
    if (
      event.target.value <= maxValue &&
      event.target.value > minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          phosphate: false,
        })
      );
      setShowPhosphateErrorMessage("");
    } else {
      setShowPhosphateErrorMessage(
        "Phosphate Value Should be Between 0.1 - 20, it should have only two decimal positions."
      );
      //document.getElementById("Phosphate").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          phosphate: true,
        })
      );
    }
  };

  const verifyMagnesium = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");

    let minValue = 0,
      maxValue = 20;
    if (
      event.target.value <= maxValue &&
      event.target.value > minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          magnesium: false,
        })
      );
      setShowMagnesiumErrorMessage("");
    } else {
      setShowMagnesiumErrorMessage(
        "Magnesium Value Should be Between 0.1 - 20, it should have only two decimal positions."
      );
      //document.getElementById("Magnesium").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          magnesium: true,
        })
      );
    }
  };

  const verifySodium = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 1,
      maxValue = 200;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          sodium: false,
        })
      );
      setShowSodiumErrorMessage("");
    } else {
      setShowSodiumErrorMessage(
        "Sodium Value Should be Between 1 - 200, it should have only two decimal positions."
      );
      //document.getElementById("Sodium").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          sodium: true,
        })
      );
    }
  };

  const verifyUrea = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 0,
      maxValue = 20;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          urea: false,
        })
      );
      setShowUreaErrorMessage("");
    } else {
      setShowUreaErrorMessage(
        "Urea Value Should be Between 0.1 - 20, it should have only two decimal positions."
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          urea: true,
        })
      );
    }
  };

  const verifyCreatinine = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 0,
      maxValue = 20;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          creatinine: false,
        })
      );
      setShowCreatinineErrorMessage("");
    } else {
      setShowCreatinineErrorMessage(
        "Creatinine Value Should be Between 0.1 - 20, it should have only two decimal positions."
      );
      //document.getElementById("Creatinine").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          creatinine: true,
        })
      );
    }
  };

  const verifyLactateLevels = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 1,
      maxValue = 300000;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          lactate_levels: false,
        })
      );
      setShowLactateLevelsErrorMessage("");
    } else {
      setShowLactateLevelsErrorMessage(
        "Lactate Levels Value Should be Between 1 - 300000, it should have only two decimal positions."
      );
      //document.getElementById("LactateLevels").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          lactate_levels: true,
        })
      );
    }
  };

  const verifyBilirubinLevels = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 0,
      maxValue = 200;
    if (
      event.target.value <= maxValue &&
      event.target.value > minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          bilirubin_levels: false,
        })
      );
      setShowBilirubinLevelsErrorMessage("");
    } else {
      setShowBilirubinLevelsErrorMessage(
        "Bilirubin Levels Value Should be Between 0.1 - 200, it should have only two decimal positions."
      );
      //document.getElementById("BilirubinLevels").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          bilirubin_levels: true,
        })
      );
    }
  };

  const verifyCordPH = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 1,
      maxValue = 20;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          cord_ph: false,
        })
      );
      setShowCordPHErrorMessage("");
    } else {
      setShowCordPHErrorMessage(
        "Cord PH Value Should be Between 1 - 20, it should have only two decimal positions."
      );
      //document.getElementById("CordPH").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          cord_ph: true,
        })
      );
    }
  };

  const verifyCSFCultureTSBValue = (event) => {
    if (event.target.value >= 1 && event.target.value <= 300000) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          csf_culture_tsb_value: false,
        })
      );
      setShowCSFCultureTSBValueErrorMessage("");
    } else {
      setShowCSFCultureTSBValueErrorMessage(
        "CSF Culture TSB Value Should be Between 1 - 300000, it should have only two decimal positions."
      );
      //document.getElementById("CSFCultureTSBValue").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          csf_culture_tsb_value: true,
        })
      );
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      {isLoading ? (
        <div
          style={{
            height: "100px",
            paddingTop: "230px",
            textAlign: "center",
          }}
        >
          <CircularProgress color="success" />
        </div>
      ) : (
        <Card variant="none">
          <CardContent>
            <form onSubmit={HandleAddFormSubmit} id="mother-medical-form">
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="PatientRecordID"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Record Id
                  </label>
                  <TextField
                    id="study_id"
                    name="study_id"
                    label=""
                    variant="outlined"
                    value={patient.bmrndata?.study_id}
                    onChange={handleAddFormChange}
                    className="dataentry_input"
                    size="small"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Thyroid Status"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Thyroid Status
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyInvData?.baby_thyroid_status}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    //required
                  >
                    <ToggleButton
                      name="baby_thyroid_status"
                      value="Normal"
                      size="small"
                      className="dataentry_input"
                    >
                      Normal
                    </ToggleButton>
                    <ToggleButton
                      name="baby_thyroid_status"
                      value="Abnormal"
                      size="small"
                      className="dataentry_input"
                    >
                      Abnormal
                    </ToggleButton>
                    <ToggleButton
                      name="baby_thyroid_status"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Thyroid Result"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Thyroid Result (TSH)
                  </label>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                  <TextField
                    id="BabyThyroidResult"
                    name="baby_thyroid_result"
                    label=""
                    variant="outlined"
                    value={addBabyInvData?.baby_thyroid_result}
                    className={
                      "dataentry_input " +
                      (errorFields.baby_thyroid_result ? "errorField" : "")
                    }
                    size="small"
                    //required

                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyBabyThyroidResult(e);
                    }}
                    error={
                      errorFields.baby_thyroid_result &&
                      showBabyThyroidResultErrorMessage
                    }
                    errorText={showBabyThyroidResultErrorMessage}
                    helperText={
                      errorFields.baby_thyroid_result &&
                      showBabyThyroidResultErrorMessage
                        ? showBabyThyroidResultErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowBabyThyroidResultErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            baby_thyroid_result: true,
                          })
                        );
                      }
                    }}
                   /*  InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          mIU/L
                        </InputAdornment>
                      ),
                    }} */
                  />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Select
                      labelId="BabyThyroidResultUnit"
                      id="BabyThyroidResultUnit"
                      name="baby_thyroid_result_unit"
                      //value={addMotherData?.maternal_fever_unit}
                      value={addBabyInvData?.baby_thyroid_result_unit ? addBabyInvData?.baby_thyroid_result_unit : "mIU/L"}
                      
                      label=""
                      onChange={handleAddFormChange}
                      //disabled={!defaultCheck.MaternalFeverChecked}
                      size="small"
                      className="dataentry_select1"
                    >
                      <MenuItem value="mIU/L">mIU/L</MenuItem>
                      <MenuItem value="µIU/mL">µIU/mL</MenuItem>
                    </Select>
                  </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Thyroid Result (T3)"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Thyroid Result (T3)
                  </label>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                  <TextField
                    id="BabyThyroidResultT3"
                    name="baby_thyroid_t3"
                    label=""
                    variant="outlined"
                    value={addBabyInvData?.baby_thyroid_t3}
                    className={
                      "dataentry_input " +
                      (errorFields.baby_thyroid_t3 ? "errorField" : "")
                    }
                    size="small"
                    /* InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          ng/dL
                        </InputAdornment>
                      ),
                    }} */
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyBabyThyroidResultT3(e);
                    }}
                    error={
                      errorFields.baby_thyroid_t3 &&
                      showBabyThyroidResultT3ErrorMessage
                    }
                    errorText={showBabyThyroidResultT3ErrorMessage}
                    helperText={
                      errorFields.baby_thyroid_t3 &&
                      showBabyThyroidResultT3ErrorMessage
                        ? showBabyThyroidResultT3ErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowBabyThyroidResultT3ErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            baby_thyroid_t3: true,
                          })
                        );
                      }
                    }}
                  />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Select
                      labelId="BabyThyroidResultT3Unit"
                      id="BabyThyroidResultT3Unit"
                      name="baby_thyroid_result_t3_unit"
                      //value={addMotherData?.maternal_fever_unit}
                      value={addBabyInvData?.baby_thyroid_result_t3_unit ? addBabyInvData?.baby_thyroid_result_t3_unit : "ng/dL"}
                      
                      label=""
                      onChange={handleAddFormChange}
                      //disabled={!defaultCheck.MaternalFeverChecked}
                      size="small"
                      className="dataentry_select1"
                    >
                      <MenuItem value="ng/dL">ng/dL</MenuItem>
                      <MenuItem value="nmol/L">nmol/L</MenuItem>
                      <MenuItem value="ng/mL">ng/mL</MenuItem>
                      <MenuItem value="ng/L">ng/L</MenuItem>
                    </Select>
                  </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Thyroid Result (T4)"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Thyroid Result (T4)
                  </label>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                  <TextField
                    id="BabyThyroidResultT4"
                    name="baby_thyroid_t4"
                    label=""
                    variant="outlined"
                    value={addBabyInvData?.baby_thyroid_t4}
                    className={
                      "dataentry_input " +
                      (errorFields.baby_thyroid_t4 ? "errorField" : "")
                    }
                    size="small"
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyBabyThyroidResultT4(e);
                    }}
                    error={
                      errorFields.baby_thyroid_t4 &&
                      showBabyThyroidResultT4ErrorMessage
                    }
                    errorText={showBabyThyroidResultT4ErrorMessage}
                    helperText={
                      errorFields.baby_thyroid_t4 &&
                      showBabyThyroidResultT4ErrorMessage
                        ? showBabyThyroidResultT4ErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowBabyThyroidResultT4ErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            baby_thyroid_t4: true,
                          })
                        );
                      }
                    }}
                  />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Select
                      labelId="BabyThyroidResultT4Unit"
                      id="BabyThyroidResultT4Unit"
                      name="baby_thyroid_result_t4_unit"
                      //value={addMotherData?.maternal_fever_unit}
                      value={addBabyInvData?.baby_thyroid_result_t4_unit ? addBabyInvData?.baby_thyroid_result_t4_unit : "ng/dL"}
                      
                      label=""
                      onChange={handleAddFormChange}
                      //disabled={!defaultCheck.MaternalFeverChecked}
                      size="small"
                      className="dataentry_select1"
                    >
                      <MenuItem value="ng/dL">ng/dL</MenuItem>
                      <MenuItem value="nmol/L">nmol/L</MenuItem>
                      <MenuItem value="ng/mL">ng/mL</MenuItem>
                      <MenuItem value="ng/L">ng/L</MenuItem>
                    </Select>
                  </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Blood Glucose"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Blood Glucose
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                  <TextField
                    id="BabyBloodGlucose"
                    name="baby_blood_glucose"
                    label=""
                    //required
                    variant="outlined"
                    value={addBabyInvData?.baby_blood_glucose}
                    className={
                      "dataentry_input " +
                      (errorFields.baby_blood_glucose ? "errorField" : "")
                    }
                    size="small"
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyBabyBloodGlucose(e);
                    }}
                    error={
                      errorFields.baby_blood_glucose &&
                      showBabyBloodGlucoseErrorMessage
                    }
                    errorText={showBabyBloodGlucoseErrorMessage}
                    helperText={
                      errorFields.baby_blood_glucose &&
                      showBabyBloodGlucoseErrorMessage
                        ? showBabyBloodGlucoseErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowBabyBloodGlucoseErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            baby_blood_glucose: true,
                          })
                        );
                      }
                    }}
                  />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Select
                      labelId="BabyBloodGlucoseUnit"
                      id="BabyBloodGlucoseUnit"
                      name="baby_blood_glucose_unit"
                      value={addBabyInvData?.baby_blood_glucose_unit ? addBabyInvData?.baby_blood_glucose_unit : "mmol/L"}
                      onChange={handleAddFormChange}
                      size="small"
                      className="dataentry_select1"
                    >
                      <MenuItem value="mmol/L">mmol/L</MenuItem>
                      <MenuItem value="mg/dl">mg/dl</MenuItem>
                    </Select>
                  </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Haemoglobin Level"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Haemoglobin Level
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                  <TextField
                    id="BabyHaemoglobinLevels"
                    name="baby_haemoglobin_levels"
                    label=""
                    variant="outlined"
                    //required
                    value={addBabyInvData?.baby_haemoglobin_levels}
                    className={
                      "dataentry_input " +
                      (errorFields.baby_haemoglobin_levels ? "errorField" : "")
                    }
                    size="small"
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyBabyHaemoglobinLevels(e);
                    }}
                    error={
                      errorFields.baby_haemoglobin_levels &&
                      showBabyHaemoglobinLevelsErrorMessage
                    }
                    errorText={showBabyHaemoglobinLevelsErrorMessage}
                    helperText={
                      errorFields.baby_haemoglobin_levels &&
                      showBabyHaemoglobinLevelsErrorMessage
                        ? showBabyHaemoglobinLevelsErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowBabyHaemoglobinLevelsErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            baby_haemoglobin_levels: true,
                          })
                        );
                      }
                    }}
                  />
                  
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Select
                      labelId="BabyHaemoglobinLevelsUnit"
                      id="BabyHaemoglobinLevelsUnit"
                      name="baby_haemoglobin_levels_unit"
                      value={addBabyInvData?.baby_haemoglobin_levels_unit ? addBabyInvData?.baby_haemoglobin_levels_unit : "g/dL"}
                      onChange={handleAddFormChange}
                      size="small"
                      className="dataentry_select1"
                    >
                      <MenuItem value="g/dL">g/dL</MenuItem>
                      <MenuItem value="g/L">g/L</MenuItem>
                    </Select>
                  </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="C-Reactive Protein Result"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    C-Reactive Protein Result
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyInvData?.baby_c_reactive_protien_result}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    //required
                  >
                    <ToggleButton
                      name="baby_c_reactive_protien_result"
                      value="Positive"
                      size="small"
                      className="dataentry_input"
                    >
                      Positive
                    </ToggleButton>
                    <ToggleButton
                      name="baby_c_reactive_protien_result"
                      value="Negative"
                      size="small"
                      className="dataentry_input"
                    >
                      Negative
                    </ToggleButton>
                    <ToggleButton
                      name="baby_c_reactive_protien_result"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="C-Reactive Protein Level"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    C-Reactive Protein Level
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                  <TextField
                    id="CReactiveProtien"
                    name="baby_c_reactive_protien_levels"
                    label=""
                    //required
                    variant="outlined"
                    value={addBabyInvData?.baby_c_reactive_protien_levels}
                    className={
                      "dataentry_input " +
                      (errorFields.baby_c_reactive_protien_levels
                        ? "errorField"
                        : "")
                    }
                    size="small"
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyCReactiveProtien(e);
                    }}
                    error={
                      errorFields.baby_c_reactive_protien_levels &&
                      showCRPLevelErrorMessage
                    }
                    errorText={showCRPLevelErrorMessage}
                    helperText={
                      errorFields.baby_c_reactive_protien_levels &&
                      showCRPLevelErrorMessage
                        ? showCRPLevelErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowCRPLevelErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            baby_c_reactive_protien_levels: true,
                          })
                        );
                      }
                    }}
                  />
                  
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Select
                      labelId="BabyCRPLevelUnit"
                      id="BabyCRPLevelUnit"
                      name="baby_c_reactive_protien_levels_unit"
                      value={addBabyInvData?.baby_c_reactive_protien_levels_unit ? addBabyInvData?.baby_c_reactive_protien_levels_unit : "mg/L"}
                      onChange={handleAddFormChange}
                      size="small"
                      className="dataentry_select1"
                    >
                      <MenuItem value="mg/L">mg/L</MenuItem>
                      <MenuItem value="mg/dL">mg/dL</MenuItem>
                    </Select>
                  </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Micro ESR"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Micro ESR Level (1st Hr)
                  </label>
                  <TextField
                    id="MicroESR"
                    name="micro_esr"
                    label=""
                    variant="outlined"
                    value={addBabyInvData?.micro_esr}
                    className={
                      "dataentry_input " +
                      (errorFields.micro_esr ? "errorField" : "")
                    }
                    //className="dataentry_input"
                    size="small"
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyMicroESR(e);
                    }}
                    error={errorFields.micro_esr && showMicroESRErrorMessage}
                    errorText={showMicroESRErrorMessage}
                    helperText={
                      errorFields.micro_esr && showMicroESRErrorMessage
                        ? showMicroESRErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowMicroESRErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            micro_esr: true,
                          })
                        );
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          mm
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Procalcitonin"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Procalcitonin Level
                  </label>
                  <TextField
                    id="ProcalcitoninLevel"
                    name="baby_procalcitonin_levels"
                    label=""
                    variant="outlined"
                    value={addBabyInvData?.baby_procalcitonin_levels}
                    className={
                      "dataentry_input " +
                      (errorFields.baby_procalcitonin_levels
                        ? "errorField"
                        : "")
                    }
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          ng/mL
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyProcalcitoninLevel(e);
                    }}
                    error={
                      errorFields.baby_procalcitonin_levels &&
                      showProcalcitoninLevelErrorMessage
                    }
                    errorText={showProcalcitoninLevelErrorMessage}
                    helperText={
                      errorFields.baby_procalcitonin_levels &&
                      showProcalcitoninLevelErrorMessage
                        ? showProcalcitoninLevelErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowProcalcitoninLevelErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            baby_procalcitonin_levels: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Prothrombin Type(PT)"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Prothrombin Time (PT)
                  </label>
                  <TextField
                    id="ProthrombinType"
                    name="prothrombin_type"
                    label=""
                    variant="outlined"
                    value={addBabyInvData?.prothrombin_type}
                    className={
                      "dataentry_input " +
                      (errorFields.prothrombin_type ? "errorField" : "")
                    }
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          Secs
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyProthrombinType(e);
                    }}
                    error={
                      errorFields.prothrombin_type &&
                      showProthrombinTypeErrorMessage
                    }
                    errorText={showProthrombinTypeErrorMessage}
                    helperText={
                      errorFields.prothrombin_type &&
                      showProthrombinTypeErrorMessage
                        ? showProthrombinTypeErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowProthrombinTypeErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            prothrombin_type: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Activated Partial Prothrombin"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Activated Partial Prothrombin
                  </label>
                  <TextField
                    id="ActivatedPartialProthrombin"
                    name="activated_partial_prothrombine_type"
                    label=""
                    variant="outlined"
                    value={addBabyInvData?.activated_partial_prothrombine_type}
                    className={
                      "dataentry_input " +
                      (errorFields.activated_partial_prothrombine_type
                        ? "errorField"
                        : "")
                    }
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          Secs
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyActivatedPartialProthrombin(e);
                    }}
                    error={
                      errorFields.activated_partial_prothrombine_type &&
                      showActivatedPartialProthrombinErrorMessage
                    }
                    errorText={showActivatedPartialProthrombinErrorMessage}
                    helperText={
                      errorFields.activated_partial_prothrombine_type &&
                      showActivatedPartialProthrombinErrorMessage
                        ? showActivatedPartialProthrombinErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowActivatedPartialProthrombinErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            activated_partial_prothrombine_type: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Total Leucocytes Count"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Total Leucocytes Count
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    id="TotalLeucocytesCount"
                    name="total_leucocute_count"
                    label=""
                    variant="outlined"
                    //required
                    value={addBabyInvData?.total_leucocute_count}
                    className={
                      "dataentry_input " +
                      (errorFields.total_leucocute_count ? "errorField" : "")
                    }
                    size="small"
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyTotalLeucocytesCount(e);
                    }}
                    error={
                      errorFields.total_leucocute_count &&
                      showTotalLeucocuteCountErrorMessage
                    }
                    errorText={showTotalLeucocuteCountErrorMessage}
                    helperText={
                      errorFields.total_leucocute_count &&
                      showTotalLeucocuteCountErrorMessage
                        ? showTotalLeucocuteCountErrorMessage
                        : ""
                    }
                    
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          mm
                        </InputAdornment>
                      ),
                    }}
                    onKeyDown={(event) => {
                      if (
                        !Numerics.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowTotalLeucocuteCountErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            total_leucocute_count: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Absolute Neutrophil Count"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Absolute Neutrophil Count
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                  <TextField
                    id="AbsoluteNeutrophilCount"
                    name="absolute_neutrophil_count"
                    label=""
                    variant="outlined"
                    //required
                    value={addBabyInvData?.absolute_neutrophil_count}
                    className={
                      "dataentry_input " +
                      (errorFields.absolute_neutrophil_count
                        ? "errorField"
                        : "")
                    }
                    size="small"
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyAbsoluteNeutrophilCount(e);
                    }}
                    error={
                      errorFields.absolute_neutrophil_count &&
                      showAbsoluteNeutrophilCountErrorMessage
                    }
                    errorText={showAbsoluteNeutrophilCountErrorMessage}
                    helperText={
                      errorFields.absolute_neutrophil_count &&
                      showAbsoluteNeutrophilCountErrorMessage
                        ? showAbsoluteNeutrophilCountErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowAbsoluteNeutrophilCountErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            absolute_neutrophil_count: true,
                          })
                        );
                      }
                    }}
                  />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Select
                      labelId="AbsoluteNeutrophilCountUnit"
                      id="AbsoluteNeutrophilCountUnit"
                      name="absolute_neutrophil_count_unit"
                      value={addBabyInvData?.absolute_neutrophil_count_unit ? addBabyInvData?.absolute_neutrophil_count_unit : "µL"}
                      onChange={handleAddFormChange}
                      size="small"
                      className="dataentry_select1"
                    >
                      <MenuItem value="µL">µL</MenuItem>
                      <MenuItem value="mL">mL</MenuItem>
                    </Select>
                  </Grid>
                  </Grid>
              </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Immature To Mature Neutrophil(I/T) Ratio"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Immature to mature neutrophil ratios {">"} 0.2
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyInvData?.immature_to_mature_neutrophil_ratios}
                    exclusive
                    //required
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="immature_to_mature_neutrophil_ratios"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="immature_to_mature_neutrophil_ratios"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="immature_to_mature_neutrophil_ratios"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Thrombocytopenia (Platelet Count)"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Thrombocytopenia (Platelet Count)
                   {/*  <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    id="Thrombocytopenia"
                    label=""
                    name="thrombocytopenia"
                    value={addBabyInvData?.thrombocytopenia}
                    variant="outlined"
                    size="small"
                    //required
                    className={
                      "dataentry_input " +
                      (errorFields.thrombocytopenia ? "errorField" : "")
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          Count
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyThrombocytopenia(e);
                    }}
                    error={
                      errorFields.thrombocytopenia &&
                      showThrombocytopeniaErrorMessage
                    }
                    errorText={showThrombocytopeniaErrorMessage}
                    helperText={
                      errorFields.thrombocytopenia &&
                      showThrombocytopeniaErrorMessage
                        ? showThrombocytopeniaErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowThrombocytopeniaErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            thrombocytopenia: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Urine test for Pus Cells"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Urine test for Pus Cells (If {">"} 4 pus cells/HPF)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyInvData?.urine_rest_for_pus_cells}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="urine_rest_for_pus_cells"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="urine_rest_for_pus_cells"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="urine_rest_for_pus_cells"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Urine culture test"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Urine Culture Test Result
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyInvData?.urine_culture_test}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="urine_culture_test"
                      value="Positive"
                      size="small"
                      className="dataentry_input"
                    >
                      Positive
                    </ToggleButton>
                    <ToggleButton
                      name="urine_culture_test"
                      value="Negative"
                      size="small"
                      className="dataentry_input"
                    >
                      Negative
                    </ToggleButton>
                    <ToggleButton
                      name="urine_culture_test"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Blood Culture Result"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Blood Culture Test Result (BCTR)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyInvData?.blood_culture_report}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="blood_culture_report"
                      value="Positive"
                      size="small"
                      className="dataentry_input"
                    >
                      Positive
                    </ToggleButton>
                    <ToggleButton
                      name="blood_culture_report"
                      value="Negative"
                      size="small"
                      className="dataentry_input"
                    >
                      Negative
                    </ToggleButton>
                    <ToggleButton
                      name="blood_culture_report"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Gram Positive Bacteria"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Gram Positive Bacteria (If BCTR Positive)
                  </label>
                  {addBabyInvData ? (
                    <Select
                      labelId="gram_positive_bacteria"
                      id="gram_positive_bacteria"
                      className="dataentry_select_edu"
                      name="gram_positive_bacteria"
                      onChange={handleChange}
                      multiple
                      value={
                        addBabyInvData?.gram_positive_bacteria
                          ? addBabyInvData?.gram_positive_bacteria
                          : []
                      }
                      //value={addBabyInvData?.gram_positive_bacteria}
                      renderValue={(selected) =>
                        selected.map((s) => s).join(",")
                      }
                      size="small"
                    >
                      {GRAM_POSITIVE.map((name) => (
                        <MenuItem key={name.id} value={name}>
                          <Checkbox
                            checked={
                              addBabyInvData?.gram_positive_bacteria.indexOf(
                                name.itemName
                              ) > -1
                            }
                          />
                          <ListItemText primary={name.itemName} />
                        </MenuItem>
                      ))}
                    </Select>
                  ) : undefined}
                </Grid>
                {addBabyInvData
                  ? hasOthers(addBabyInvData?.gram_positive_bacteria) && (
                      <Grid item xs={12} sm={4}>
                        <label
                          htmlFor="Gram Positive Bacteria (Other)"
                          className="dataentry_label"
                          style={{ display: "block" }}
                        >
                          Gram Positive Bacteria (Other)
                        </label>
                        <TextField
                          id="gram_positive_bacteria_if_other"
                          name="gram_positive_bacteria_if_other"
                          variant="outlined"
                          value={
                            addBabyInvData?.gram_positive_bacteria_if_other
                          }
                          onChange={handleAddFormChange}
                          className="dataentry_input"
                          size="small"
                          InputProps={{
                            inputProps: {
                              maxLength: 100,
                              minLength: 1,
                            },
                          }}
                        />
                      </Grid>
                    )
                  : undefined}
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Gram Negative Bacteria"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Gram Negative Bacteria (If BCTR Positive)
                  </label>
                  {addBabyInvData ? (
                    <Select
                      labelId="gram_negative_bacteria"
                      id="gram_negative_bacteria"
                      name="gram_negative_bacteria"
                      className="dataentry_select_edu"
                      onChange={handleChange}
                      multiple
                      value={
                        addBabyInvData?.gram_negative_bacteria
                          ? addBabyInvData?.gram_negative_bacteria
                          : []
                      }
                      //value={addBabyInvData?.gram_negative_bacteria}
                      renderValue={(selected) =>
                        selected.map((s) => s).join(",")
                      }
                      size="small"
                    >
                      {GRAM_NEGATIVE.map((name) => (
                        <MenuItem key={name.id} value={name}>
                          <Checkbox
                            checked={
                              addBabyInvData?.gram_negative_bacteria.indexOf(
                                name.itemName
                              ) > -1
                            }
                          />
                          <ListItemText primary={name.itemName} />
                        </MenuItem>
                      ))}
                    </Select>
                  ) : undefined}
                </Grid>
                {addBabyInvData
                  ? gnOthers(addBabyInvData?.gram_negative_bacteria) && (
                      <Grid item xs={12} sm={4}>
                        <label
                          htmlFor="Gram Negative Bacteria (Other)"
                          className="dataentry_label"
                          style={{ display: "block" }}
                        >
                          Gram Negative Bacteria (Other)
                        </label>
                        <TextField
                          id="gram_negative_bacteria_if_other"
                          name="gram_negative_bacteria_if_other"
                          variant="outlined"
                          value={
                            addBabyInvData?.gram_negative_bacteria_if_other
                          }
                          onChange={handleAddFormChange}
                          className="dataentry_input"
                          size="small"
                          InputProps={{
                            inputProps: {
                              maxLength: 100,
                              minLength: 1,
                            },
                          }}
                        />
                      </Grid>
                    )
                  : undefined}
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="FUNGI"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    FUNGI (If BCTR Positive)
                  </label>
                  {addBabyInvData ? (
                    <Select
                      labelId="fungi"
                      id="fungi"
                      name="fungi"
                      className="dataentry_select_edu"
                      onChange={handleChange}
                      multiple
                      value={addBabyInvData?.fungi ? addBabyInvData?.fungi : []}
                      //value={addBabyInvData?.fungi}
                      size="small"
                      renderValue={(selected) =>
                        selected.map((s) => s).join(",")
                      }
                    >
                      {FUNGI.map((name) => (
                        <MenuItem key={name.id} value={name}>
                          <Checkbox
                            checked={
                              addBabyInvData?.fungi.indexOf(name.itemName) > -1
                            }
                          />
                          <ListItemText primary={name.itemName} />
                        </MenuItem>
                      ))}
                    </Select>
                  ) : undefined}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Other Organism"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Other Organism (If BCTR Positive)
                  </label>
                  <Select
                    labelId="Other Organism"
                    id="other_organism"
                    name="other_organism"
                    label=""
                    onChange={handleAddFormChange}
                    size="small"
                    className="dataentry_select_edu"
                    // value={
                    //   addBabyInvData?.other_organism
                    //     ? addBabyInvData?.other_organism
                    //     : []
                    // }
                    value={addBabyInvData?.other_organism}
                  >
                    {OTH_ORG.map((type) => (
                      <MenuItem value={type}>{type}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Antibiotic Status Sensitive"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Antimicrobial Testing ( Sensitive)
                  </label>
                  {addBabyInvData ? (
                    <Select
                      name="antibiotic_status"
                      labelId="antibiotic_status"
                      id="antibiotic_status"
                      className="dataentry_select_edu"
                      onChange={handleChange}
                      multiple
                      //value={addBabyInvData?.antibiotic_status}
                      renderValue={(selected) =>
                        selected.map((s) => s).join(",")
                      }
                      size="small"
                      value={
                        addBabyInvData?.antibiotic_status
                          ? addBabyInvData?.antibiotic_status
                          : []
                      }
                    >
                      {ANTI_SENSITIVE.map((name) => (
                        <MenuItem key={name.id} value={name}>
                          <Checkbox
                            checked={
                              addBabyInvData?.antibiotic_status.indexOf(
                                name.itemName
                              ) > -1
                            }
                          />
                          <ListItemText primary={name.itemName} />
                        </MenuItem>
                      ))}
                    </Select>
                  ) : undefined}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Antibiotic Status Resisitant"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Antimicrobial Testing ( Resisitant )
                  </label>
                  {addBabyInvData ? (
                    <Select
                      labelId="antibiotic_status_resisitant"
                      id="antibiotic_status_resisitant"
                      className="dataentry_select_edu"
                      name="antibiotic_status_resisitant"
                      onChange={handleChange}
                      multiple
                      value={
                        addBabyInvData?.antibiotic_status_resisitant
                          ? addBabyInvData?.antibiotic_status_resisitant
                          : []
                      }
                      //value={addBabyInvData?.antibiotic_status_resisitant}
                      renderValue={(selected) =>
                        selected.map((s) => s).join(",")
                      }
                      size="small"
                    >
                      {ANTI_RESISTANT.map((name) => (
                        <MenuItem key={name.id} value={name}>
                          <Checkbox
                            checked={
                              addBabyInvData?.antibiotic_status_resisitant.indexOf(
                                name.itemName
                              ) > -1
                            }
                          />
                          <ListItemText primary={name.itemName} />
                        </MenuItem>
                      ))}
                    </Select>
                  ) : undefined}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Antibiotic Status Intermediate"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Antimicrobial Testing ( Intermediate )
                  </label>
                  {addBabyInvData ? (
                    <Select
                      labelId="antibiotic_status_intermediate"
                      id="antibiotic_status_intermediate"
                      className="dataentry_select_edu"
                      name="antibiotic_status_intermediate"
                      onChange={handleChange}
                      multiple
                      value={
                        addBabyInvData?.antibiotic_status_intermediate
                          ? addBabyInvData?.antibiotic_status_intermediate
                          : []
                      }
                      //value={addBabyInvData?.antibiotic_status_intermediate}
                      renderValue={(selected) =>
                        selected.map((s) => s).join(",")
                      }
                      size="small"
                    >
                      {ANTI_INTERMEDIATE.map((name) => (
                        <MenuItem key={name.id} value={name}>
                          <Checkbox
                            checked={
                              addBabyInvData?.antibiotic_status_intermediate.indexOf(
                                name.itemName
                              ) > -1
                            }
                          />
                          <ListItemText primary={name.itemName} />
                        </MenuItem>
                      ))}
                    </Select>
                  ) : undefined}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Sodium (Na)"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Sodium Level (Na)
                  </label>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                  <TextField
                    id="Sodium"
                    name="sodium"
                    label=""
                    variant="outlined"
                    value={addBabyInvData?.sodium}
                    className={
                      "dataentry_input " +
                      (errorFields.sodium ? "errorField" : "")
                    }
                    size="small"
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifySodium(e);
                    }}
                    error={errorFields.sodium && showSodiumErrorMessage}
                    errorText={showSodiumErrorMessage}
                    helperText={
                      errorFields.sodium && showSodiumErrorMessage
                        ? showSodiumErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowSodiumErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            sodium: true,
                          })
                        );
                      }
                    }}
                  />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Select
                      labelId="SodiumUnit"
                      id="SodiumUnit"
                      name="Sodium_unit"
                      value={addBabyInvData?.Sodium_unit ? addBabyInvData?.Sodium_unit : "mEq/L"}
                      onChange={handleAddFormChange}
                      size="small"
                      className="dataentry_select1"
                    >
                      <MenuItem value="mEq/L">mEq/L</MenuItem>
                      <MenuItem value="mmol/L">mmol/L</MenuItem>
                      <MenuItem value="mg/dL">mg/dL</MenuItem>
                    </Select>
                  </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Potassium (K)"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Potassium Level(K)
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                  <TextField
                    id="Potassium"
                    name="potassium"
                    label=""
                    variant="outlined"
                    value={addBabyInvData?.potassium}
                    //required
                    className={
                      "dataentry_input " +
                      (errorFields.potassium ? "errorField" : "")
                    }
                    size="small"
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyPotassium(e);
                    }}
                    error={errorFields.potassium && showPotassiumErrorMessage}
                    errorText={showPotassiumErrorMessage}
                    helperText={
                      errorFields.potassium && showPotassiumErrorMessage
                        ? showPotassiumErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowPotassiumErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            potassium: true,
                          })
                        );
                      }
                    }}
                  />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Select
                      labelId="PotassiumUnit"
                      id="PotassiumUnit"
                      name="potassium_unit"
                      value={addBabyInvData?.potassium_unit ? addBabyInvData?.potassium_unit : "mmol/L"}
                      onChange={handleAddFormChange}
                      size="small"
                      className="dataentry_select1"
                    >
                      <MenuItem value="mmol/L">mmol/L</MenuItem>
                      <MenuItem value="mEq/L">mEq/L</MenuItem>
                      <MenuItem value="mg/dL">mg/dL</MenuItem>
                    </Select>
                  </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Chloride"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Chloride Level
                  </label>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                  <TextField
                    id="Chlorine"
                    name="chlorine"
                    label=""
                    variant="outlined"
                    value={addBabyInvData?.chlorine}
                    className={
                      "dataentry_input " +
                      (errorFields.chlorine ? "errorField" : "")
                    }
                    size="small"
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyChlorine(e);
                    }}
                    error={errorFields.chlorine && showChlorineErrorMessage}
                    errorText={showChlorineErrorMessage}
                    helperText={
                      errorFields.chlorine && showChlorineErrorMessage
                        ? showChlorineErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowChlorineErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            chlorine: true,
                          })
                        );
                      }
                    }}
                  />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Select
                      labelId="chlorineUnit"
                      id="chlorineUnit"
                      name="chlorine_unit"
                      value={addBabyInvData?.chlorine_unit ? addBabyInvData?.chlorine_unit : "mmol/L"}
                      onChange={handleAddFormChange}
                      size="small"
                      className="dataentry_select1"
                    >
                      <MenuItem value="mmol/L">mmol/L</MenuItem>
                      <MenuItem value="mEq/L">mEq/L</MenuItem>
                      <MenuItem value="mg/dL">mg/dL</MenuItem>
                    </Select>
                  </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Calcium (Ca)"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Calcium Level(Ca)
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                  <TextField
                    id="Calcium"
                    name="calcium"
                    label=""
                    variant="outlined"
                    value={addBabyInvData?.calcium}
                    //required
                    className={
                      "dataentry_input " +
                      (errorFields.calcium ? "errorField" : "")
                    }
                    size="small"
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyCalcium(e);
                    }}
                    error={errorFields.calcium && showCalciumErrorMessage}
                    errorText={showCalciumErrorMessage}
                    helperText={
                      errorFields.calcium && showCalciumErrorMessage
                        ? showCalciumErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowCalciumErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            calcium: true,
                          })
                        );
                      }
                    }}
                  />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Select
                      labelId="calciumUnit"
                      id="calciumUnit"
                      name="calcium_unit"
                      value={addBabyInvData?.calcium_unit ? addBabyInvData?.calcium_unit : "mmol/L"}
                      onChange={handleAddFormChange}
                      size="small"
                      className="dataentry_select1"
                    >
                      <MenuItem value="mmol/L">mmol/L</MenuItem>
                      <MenuItem value="mEq/L">mEq/L</MenuItem>
                      <MenuItem value="mg/dL">mg/dL</MenuItem>
                    </Select>
                  </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Phosphate (PO4)"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Phosphate Level(PO4)
                  </label>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                  <TextField
                    id="Phosphate"
                    name="phosphate"
                    label=""
                    variant="outlined"
                    value={addBabyInvData?.phosphate}
                    className="dataentry_input"
                    size="small"
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyPhosphate(e);
                    }}
                    error={errorFields.phosphate && showPhosphateErrorMessage}
                    errorText={showPhosphateErrorMessage}
                    helperText={
                      errorFields.phosphate && showPhosphateErrorMessage
                        ? showPhosphateErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowPhosphateErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            phosphate: true,
                          })
                        );
                      }
                    }}
                  />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Select
                      labelId="phosphateUnit"
                      id="phosphateUnit"
                      name="phosphate_unit"
                      value={addBabyInvData?.phosphate_unit ? addBabyInvData?.phosphate_unit : "mmol/L"}
                      onChange={handleAddFormChange}
                      size="small"
                      className="dataentry_select1"
                    >
                      <MenuItem value="mmol/L">mmol/L</MenuItem>
                      <MenuItem value="mEq/L">mEq/L</MenuItem>
                      <MenuItem value="mg/dL">mg/dL</MenuItem>
                    </Select>
                  </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Magnesium (Mg)"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Magnesium Level(Mg)
                  </label>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                  <TextField
                    id="Magnesium"
                    name="magnesium"
                    label=""
                    variant="outlined"
                    value={addBabyInvData?.magnesium}
                    className={
                      "dataentry_input " +
                      (errorFields.magnesium ? "errorField" : "")
                    }
                    size="small"
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyMagnesium(e);
                    }}
                    error={errorFields.magnesium && showMagnesiumErrorMessage}
                    errorText={showMagnesiumErrorMessage}
                    helperText={
                      errorFields.magnesium && showMagnesiumErrorMessage
                        ? showMagnesiumErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowMagnesiumErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            magnesium: true,
                          })
                        );
                      }
                    }}
                  />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Select
                      labelId="magnesiumUnit"
                      id="magnesiumUnit"
                      name="magnesium_unit"
                      value={addBabyInvData?.magnesium_unit ? addBabyInvData?.magnesium_unit : "mmol/L"}
                      onChange={handleAddFormChange}
                      size="small"
                      className="dataentry_select1"
                    >
                      <MenuItem value="mmol/L">mmol/L</MenuItem>
                      <MenuItem value="mEq/L">mEq/L</MenuItem>
                      <MenuItem value="mg/dL">mg/dL</MenuItem>
                    </Select>
                  </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Urea"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Urea Level
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                  <TextField
                    id="Urea"
                    name="urea"
                    label=""
                    variant="outlined"
                    value={addBabyInvData?.urea}
                    //required
                    className={
                      "dataentry_input " +
                      (errorFields.urea ? "errorField" : "")
                    }
                    size="small"
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyUrea(e);
                    }}
                    error={errorFields.urea && showUreaErrorMessage}
                    errorText={showUreaErrorMessage}
                    helperText={
                      errorFields.urea && showUreaErrorMessage
                        ? showUreaErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowUreaErrorMessage("Only Numerics are Acceptable");
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            urea: true,
                          })
                        );
                      }
                    }}
                  />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Select
                      labelId="ureaUnit"
                      id="ureaUnit"
                      name="urea_unit"
                      value={addBabyInvData?.urea_unit ? addBabyInvData?.urea_unit : "mmol/L"}
                      onChange={handleAddFormChange}
                      size="small"
                      className="dataentry_select1"
                    >
                      <MenuItem value="mmol/L">mmol/L</MenuItem>
                      <MenuItem value="mEq/L">mEq/L</MenuItem>
                      <MenuItem value="mg/dL">mg/dL</MenuItem>
                    </Select>
                  </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Creatinine"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Creatinine Level
                   {/*  <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                  <TextField
                    id="Creatinine"
                    name="creatinine"
                    label=""
                    variant="outlined"
                    value={addBabyInvData?.creatinine}
                    //required
                    className={
                      "dataentry_input " +
                      (errorFields.creatinine ? "errorField" : "")
                    }
                    size="small"
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyCreatinine(e);
                    }}
                    error={errorFields.creatinine && showCreatinineErrorMessage}
                    errorText={showCreatinineErrorMessage}
                    helperText={
                      errorFields.creatinine && showCreatinineErrorMessage
                        ? showCreatinineErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowCreatinineErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            creatinine: true,
                          })
                        );
                      }
                    }}
                  />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Select
                      labelId="creatinineUnit"
                      id="creatinineUnit"
                      name="creatinine_unit"
                      value={addBabyInvData?.creatinine_unit ? addBabyInvData?.creatinine_unit : "mmol/L"}
                      onChange={handleAddFormChange}
                      size="small"
                      className="dataentry_select1"
                    >
                      <MenuItem value="mmol/L">mmol/L</MenuItem>
                      <MenuItem value="mEq/L">mEq/L</MenuItem>
                      <MenuItem value="mg/dL">mg/dL</MenuItem>
                    </Select>
                  </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Lactate level"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Lactate level
                  </label>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                  <TextField
                    id="LactateLevels"
                    name="lactate_levels"
                    label=""
                    variant="outlined"
                    value={addBabyInvData?.lactate_levels}
                    className={
                      "dataentry_input " +
                      (errorFields.lactate_levels ? "errorField" : "")
                    }
                    size="small"
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyLactateLevels(e);
                    }}
                    error={
                      errorFields.lactate_levels &&
                      showLactateLevelsErrorMessage
                    }
                    errorText={showLactateLevelsErrorMessage}
                    helperText={
                      errorFields.lactate_levels &&
                      showLactateLevelsErrorMessage
                        ? showLactateLevelsErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowLactateLevelsErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            lactate_levels: true,
                          })
                        );
                      }
                    }}
                  />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Select
                      labelId="lactateUnit"
                      id="lactateUnit"
                      name="lactate_unit"
                      value={addBabyInvData?.lactate_unit ? addBabyInvData?.lactate_unit : "mmol/L"}
                      onChange={handleAddFormChange}
                      size="small"
                      className="dataentry_select1"
                    >
                      <MenuItem value="mmol/L">mmol/L</MenuItem>
                      <MenuItem value="mEq/L">mEq/L</MenuItem>
                      <MenuItem value="mg/dL">mg/dL</MenuItem>
                    </Select>
                  </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Bilirubin level"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Bilirubin level
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                  <TextField
                    id="BilirubinLevels"
                    name="bilirubin_levels"
                    label=""
                    variant="outlined"
                    //required
                    value={addBabyInvData?.bilirubin_levels}
                    className={
                      "dataentry_input " +
                      (errorFields.bilirubin_levels ? "errorField" : "")
                    }
                    size="small"
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyBilirubinLevels(e);
                    }}
                    error={
                      errorFields.bilirubin_levels &&
                      showBilirubinLevelsErrorMessage
                    }
                    errorText={showBilirubinLevelsErrorMessage}
                    helperText={
                      errorFields.bilirubin_levels &&
                      showBilirubinLevelsErrorMessage
                        ? showBilirubinLevelsErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowBilirubinLevelsErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            bilirubin_levels: true,
                          })
                        );
                      }
                    }}
                  />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Select
                      labelId="bilirubinUnit"
                      id="bilirubinUnit"
                      name="bilirubin_unit"
                      value={addBabyInvData?.bilirubin_unit ? addBabyInvData?.bilirubin_unit : "mumol/L"}
                      onChange={handleAddFormChange}
                      size="small"
                      className="dataentry_select1"
                    >
                      <MenuItem value="mumol/L">mumol/L</MenuItem>
                      <MenuItem value="mg/dL">mg/dL</MenuItem>
                    </Select>
                  </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Cord pH"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Cord pH
                  </label>
                  <TextField
                    id="CordPH"
                    name="cord_ph"
                    label=""
                    variant="outlined"
                    value={addBabyInvData?.cord_ph}
                    className={
                      "dataentry_input " +
                      (errorFields.cord_ph ? "errorField" : "")
                    }
                    size="small"
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyCordPH(e);
                    }}
                    error={errorFields.cord_ph && showCordPHErrorMessage}
                    errorText={showCordPHErrorMessage}
                    helperText={
                      errorFields.cord_ph && showCordPHErrorMessage
                        ? showCordPHErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics1.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowCordPHErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            cord_ph: true,
                          })
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="ECG - Arrhythmia (Present)"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    ECG - Arrhythmia (Present)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyInvData?.arrhythmia}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="arrhythmia"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="arrhythmia"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="arrhythmia"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="CSF Culture Value"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    CSF Culture Result
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyInvData?.csf_culture}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    //required
                  >
                    <ToggleButton
                      name="csf_culture"
                      value="Positive"
                      size="small"
                      className="dataentry_input"
                    >
                      Positive
                    </ToggleButton>
                    <ToggleButton
                      name="csf_culture"
                      value="Negative"
                      size="small"
                      className="dataentry_input"
                    >
                      Negative
                    </ToggleButton>
                    <ToggleButton
                      name="csf_culture"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="TSB Value (mg/dl)"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    TSB Value
                  </label>
                  <TextField
                    id="CSFCultureTSBValue"
                    name="csf_culture_tsb_value"
                    label=""
                    variant="outlined"
                    value={addBabyInvData?.csf_culture_tsb_value}
                    className={
                      "dataentry_input " +
                      (errorFields.csf_culture_tsb_value ? "errorField" : "")
                    }
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="InputAdornment"
                        >
                          mg/dL
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      //verifyCSFCultureTSBValue(e);
                    }}
                    error={
                      errorFields.csf_culture_tsb_value &&
                      showCSFCultureTSBValueErrorMessage
                    }
                    errorText={showCSFCultureTSBValueErrorMessage}
                    helperText={
                      errorFields.csf_culture_tsb_value &&
                      showCSFCultureTSBValueErrorMessage
                        ? showCSFCultureTSBValueErrorMessage
                        : ""
                    }
                    /* onKeyDown={(event) => {
                      if (
                        !Numerics.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowCSFCultureTSBValueErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            csf_culture_tsb_value: true,
                          })
                        );
                      }
                    }} */
                  />
                </Grid>
              </Grid>

              <div
                style={{
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                  marginTop: "20px",
                }}
              >
                {isValid ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    aria-label="add"
                    className="buttonStyle sign-up-button"
                    type="submit"
                  >
                    Save {"&"} Next
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    color="gray"
                    aria-label="add"
                    className="buttonStyle1 sign-up-button"
                    disabled
                  >
                    Save {"&"} Next
                  </Button>
                )}
              </div>
            </form>
          </CardContent>
        </Card>
      )}
    </React.Fragment>
  );
};

export default BabyInvestigation;
