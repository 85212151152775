import { createSlice } from "@reduxjs/toolkit";
import * as PatientThunk from "./patientDataThunk";
import { initialState } from "./InitialState";
import {
  babyFormMock,
  motherFormMock,
  babyHealthParametersFormMock,
  babyCNSDataMock,
  BABY_FORM_INCOMP,
  MOTHER_FORM_INCOMP,
  babyGITDataMock,
  babyCVDataMock,
  babyFinalDiagnosis,
} from "../utils/mockData";

const patientSlice = createSlice({
  name: "patient",
  initialState,
  extraReducers: {
    [PatientThunk.setFormTab.fulfilled]: (state, action) => {
      state.formTab = action.payload;
      // console.log("Changed form tab", action.payload);
    },
    [PatientThunk.setMotherEdit.fulfilled]: (state, action) => {
      state.isMotherEdit = action.payload;
    },
    [PatientThunk.setBabyCVEdit.fulfilled]: (state, action) => {
      state.isBabyCVEdit = action.payload;
    },
    [PatientThunk.seteditBmrnData.fulfilled]: (state, action) => {
      state.isBmrnEdit = action.payload;
    },
    [PatientThunk.setBabyEdit.fulfilled]: (state, action) => {
      state.isBabyEdit = action.payload;
    },

    [PatientThunk.setBabyApperanceEdit.fulfilled]: (state, action) => {
      state.isBabyApperance = action.payload;
    },
    [PatientThunk.setBabyRespiratoryEdit.fulfilled]: (state, action) => {
      state.isBabyRespiratory = action.payload;
    },
    [PatientThunk.setErrorMessage.fulfilled]: (state, action) => {
      state.errorMessage = action.payload;
    },
    [PatientThunk.setsuccessMessage.fulfilled]: (state, action) => {
      state.successMessage = action.payload;
    },
    [PatientThunk.setBabyCNSEdit.fulfilled]: (state, action) => {
      state.isBabyCNS = action.payload;
      // state.formTab = 7;
    },
    [PatientThunk.setAddReferralData.fulfilled]: (state, action) => {
      state.referralDoctorData = action.payload;
    },
    [PatientThunk.setBabyGITEdit.fulfilled]: (state, action) => {
      state.isBabyGIT = action.payload;
    },

    [PatientThunk.setBabyInvEdit.fulfilled]: (state, action) => {
      state.isBabyInv = action.payload;
    },

    [PatientThunk.setBabyAntibioticEdit.fulfilled]: (state, action) => {
      state.isBabyAntibiotic = action.payload;
      // state.formTab = 12;
    },
    [PatientThunk.createPatientProfile.pending]: (state, action) => {},
    [PatientThunk.createPatientProfile.fulfilled]: (state, action) => {
      state.getBabyProfile = action.payload.data.response[0];
      // state.formTab = 2;
      state.isBabyEdit = true;
    },
    [PatientThunk.babyMedicalRecordAdd.fulfilled]: (state, action) => {
      state.bmrndata = action.payload.response;
      // state.formTab = 1;
    },
    [PatientThunk.getPatientBasicDetails.fulfilled]: (state, action) => {
      state.basicData = action.payload.response;
      // state.formTab = 1;
    },
    [PatientThunk.getIndividualBabyMedicalRecord.fulfilled]: (
      state,
      action
    ) => {
      state.babyData = action.payload.response
        ? action.payload.response[0]
        : {};
      // state.formTab = 1;
    },
    [PatientThunk.babydataInfo.fulfilled]: (state, action) => {
      state.bmrndata = action.payload;
      // state.formTab = 1;
    },
    [PatientThunk.addNewGlobalRecord.fulfilled]: (state, action) => {
      state.bmrndata = action.payload.response;
      state.formTab = 3;
    },

    [PatientThunk.setSelectedBMRN.fulfilled]: (state, action) => {
      state.selectedBMRN = action.payload;
    },

    [PatientThunk.headerBMRN.fulfilled]: (state, action) => {
      state.bmrndata = action.payload;
    },

    [PatientThunk.createPatientProfile.rejected]: (state, action) => {
      state.errorMessage = action.payload;
    },
    /* [PatientThunk.createPatientProfile.fulfilled]: (state, action) => {
      state.babyData.id = action.payload.id;
      state.formTab = 2; 
    }, */
    [PatientThunk.patientProfileSignUp.fulfilled]: (state, action) => {
      state = action.payload;
    },
    [PatientThunk.setBabyData.fulfilled]: (state, action) => {
      state.getBabyProfile = action.payload;
    },
    [PatientThunk.setRefDocData.fulfilled]: (state, action) => {
      state.refDocSelect = action.payload;
    },
    [PatientThunk.setMotherData.fulfilled]: (state, action) => {
      state.getMotherProfile = action.payload;
    },
    [PatientThunk.setBabyApperanceData.fulfilled]: (state, action) => {
      state.babyappearance = action.payload;
    },
    [PatientThunk.setBabyRespiratoryData.fulfilled]: (state, action) => {
      state.babyRespiratoryData = JSON.parse(action.payload);
    },
    [PatientThunk.setBabyInvData.fulfilled]: (state, action) => {
      state.babyInvData = action.payload;
    },
    [PatientThunk.setBabyCNSData.fulfilled]: (state, action) => {
      state.babyCNSData = action.payload;
    },
    [PatientThunk.setBabyGITData.fulfilled]: (state, action) => {
      state.babyGITData = action.payload;
    },

    [PatientThunk.setBabyAntibioticData.fulfilled]: (state, action) => {
      state.babyAntibioticData = action.payload;
    },
    [PatientThunk.setBabyHealthParameters.fulfilled]: (state, action) => {
      state.babyHealthParameters = action.payload;
      //state.formTab = 11;
    },

    [PatientThunk.setBMRNData.fulfilled]: (state, action) => {
      state.bmrndata = action.payload;
    },
    [PatientThunk.showBabyRecordForm.fulfilled]: (state, action) => {
      const { bmrnSelected, entityTypeSel } = action.payload;
      state.selectedEntityType = entityTypeSel;
      state.bmrndata = bmrnSelected;
      // state.formTab = 1;
    },
    [PatientThunk.getPatientDetails.fulfilled]: (state, action) => {
      if (action.payload.error) {
        state.errorMessage = action.payload.message;
      } else {
        state.bmrnList = action.payload.response;
      }
    },

    [PatientThunk.getPatientBasicInfo.fulfilled]: (state, action) => {
      state.bmrndata1 = action.payload.response;
    },

    [PatientThunk.getDashboardListing.fulfilled]: (state, action) => {
      if (action.payload.error) {
        state.errorMessage = action.payload.message;
      } else {
        state.patientList = action.payload.response;
      }
    },
    [PatientThunk.getDashboardListingBySearch.fulfilled]: (state, action) => {
      if (action.payload.error) {
        state.errorMessage = action.payload.message;
      } else {
        state.patientList = action.payload.response;
      }
    },
    [PatientThunk.getListingByBranch.fulfilled]: (state, action) => {
      state.patientList = action.payload.response;
    },

    [PatientThunk.getPatientReadings.fulfilled]: (state, action) => {
      state.patientReadings = action.payload.response;
    },

    [PatientThunk.setPatientDetails.fulfilled]: (state, action) => {
      state.bmrndata = action.payload;
    },
    [PatientThunk.getPatientLevel.fulfilled]: (state, action) => {
      state = action.payload;
    },
    [PatientThunk.patientBasicInfoUpdated.fulfilled]: (state, action) => {
      state = action.payload;
    },
    [PatientThunk.patientBasicInfoDupUpdation.fulfilled]: (state, action) => {
      state = action.payload;
    },
    [PatientThunk.patientGeneralInfoUpdation.fulfilled]: (state, action) => {
      state = action.payload;
    },
    [PatientThunk.maternalAddition.pending]: (state, action) => {
      // console.log("Action pending inside slice maternalAddition");
    },
    [PatientThunk.createBabyHealthParameters.fulfilled]: (state, action) => {
      state.babyHealthParameters = action.payload.response;
      state.isHealthParameters = true;
    },

    [PatientThunk.maternalAddition.fulfilled]: (state, action) => {
      state.getMotherProfile = action.payload.response;
      // state.formTab = 3;
      state.isMotherEdit = true;
    },
    [PatientThunk.babyGitAddition.fulfilled]: (state, action) => {
      state.babyGITData = action.payload.response;
      state.isBabyGIT = true;
      // state.formTab = 8;
    },
    [PatientThunk.babyAppearAddition.fulfilled]: (state, action) => {
      state.babyappearance = action.payload.response;
      // state.formTab = 4;
      state.isBabyApperance = true;
    },
    [PatientThunk.babyRespAddition.fulfilled]: (state, action) => {
      const respData = action.payload.response;
      respData.baby_respiratory_support_if_yes = JSON.parse(
        respData.baby_respiratory_support_if_yes
      );
      state.babyRespiratoryData = respData;
      // state.formTab = 5;
      state.isBabyRespiratory = true;
    },
    [PatientThunk.babyCnsAddition.fulfilled]: (state, action) => {
      state.babyCNSData = action.payload.response;
      state.isBabyCNS = true;
      // state.formTab = 7;
    },
    [PatientThunk.babyCvAddition.fulfilled]: (state, action) => {
      state.babyCVData = action.payload.response;
      state.isBabyCVEdit = true;
      // state.formTab = 6;
    },
    [PatientThunk.antibioticAddition.fulfilled]: (state, action) => {
      const antiData = action.payload.response;
      antiData.antibiotic_name = JSON.parse(antiData.antibiotic_name);
      state.babyAntibioticData = antiData;
      // state.formTab = 12;
      state.isBabyAntibiotic = true;
    },

    [PatientThunk.generateScore.fulfilled]: (state, action) => {
      state.generateScore = action.payload.response;
      state.isBabyCVEdit = true;
    },
    [PatientThunk.babyInvestigationAddition.fulfilled]: (state, action) => {
      const invData = action.payload.response;
      invData.gram_positive_bacteria = JSON.parse(
        invData.gram_positive_bacteria
      );
      invData.gram_negative_bacteria = JSON.parse(
        invData.gram_negative_bacteria
      );
      invData.fungi = JSON.parse(invData.fungi);
      invData.antibiotic_status = JSON.parse(invData.antibiotic_status);
      invData.antibiotic_status_resisitant = JSON.parse(
        invData.antibiotic_status_resisitant
      );
      invData.antibiotic_status_intermediate = JSON.parse(
        invData.antibiotic_status_intermediate
      );
      state.babyInvData = invData;
      // state.formTab = 9;
      state.isBabyInv = true;
    },
    [PatientThunk.finalAddition.fulfilled]: (state, action) => {
      state.babyFinalDiagData = action.payload.response;
      state.isBabyFinalDiagEdit = true;
      // state.formTab = 12;
    },
    [PatientThunk.searchPatientDetails.fulfilled]: (state, action) => {
      state = action.payload;
    },
    [PatientThunk.createPatientNewReading.fulfilled]: (state, action) => {
      state = action.payload;
    },
    [PatientThunk.getPatientNewReading.fulfilled]: (state, action) => {
      state = action.payload;
    },
    [PatientThunk.updatePatientFormData.fulfilled]: (state, action) => {
      state.babyData = action.payload.response;
      // state.formTab = state.formTab + 1;
    },
    [PatientThunk.getPatientLastReadingData.fulfilled]: (state, action) => {
      state = action.payload;
    },
    [PatientThunk.babyProfileUpdate.fulfilled]: (state, action) => {
      state.getBabyProfile = action.payload.response;
      // state.formTab = 2;
      state.isMotherEdit = true;
    },
    [PatientThunk.maternalProfileUpdate.fulfilled]: (state, action) => {
      state.getMotherProfile = action.payload.response;
      // state.formTab = 3;
    },
    [PatientThunk.setBabyCVData.fulfilled]: (state, action) => {
      state.babyCVData = action.payload;
    },
    [PatientThunk.setBabyFinalDiagEdit.fulfilled]: (state, action) => {
      state.isBabyFinalDiagEdit = action.payload;
    },
    [PatientThunk.setBabyFinalDiagData.fulfilled]: (state, action) => {
      state.babyFinalDiagData = action.payload;
    },
    [PatientThunk.setCheckedBabyFinalDiag.fulfilled]: (state, action) => {
      state.checkedFinalDiagnosis = action.payload;
    },
    [PatientThunk.setSelectedreading.fulfilled]: (state, action) => {
      state.Selectedreadingid = action.payload;
      state.formTab = 3;
      state.isBabyEdit = true;
      state.isMotherEdit = true;
      state.isBabyCNS = true;
      state.isBabyCVEdit = true;
      state.checkedFinalDiagnosis = false;
      state.isBabyFinalDiagEdit = true;
      state.isBabyApperance = true;
      state.isBabyRespiratory = true;
      state.isBabyGIT = true;
      state.isBabyInv = true;
      state.isBabyAntibiotic = true;
    },
    [PatientThunk.babyHealthParametersUpdate.pending]: (state, action) => {},
    [PatientThunk.babyHealthParametersUpdate.fulfilled]: (state, action) => {
      state.babyHealthParametersUpdate = action.payload;
    },
    [PatientThunk.babyPredictionScore.fulfilled]: (state, action) => {
      state.score = action.payload;
    },
    [PatientThunk.babyNonInvasivePredictionScore.fulfilled]: (
      state,
      action
    ) => {
      state.NonInvasivescore = action.payload;
    },

    [PatientThunk.babyQuickSepsisScorePrediction.fulfilled]: (
      state,
      action
    ) => {
      state.QuickSepsisScore = action.payload;
    },

    [PatientThunk.updateScore.fulfilled]: (state, action) => {
      state.updateScore = action.payload.response;
    },
    [PatientThunk.DownloadBabyBMRReport.fulfilled]: (state, action) => {
      state = action.payload;
    },
    [PatientThunk.babyAddMedicalRecord.fulfilled]: (state, action) => {
      state.bmrn1data = action.payload;
      state.isBmrnEdit = true;
      state.checkedFinalDiagnosis = false;
    },

    [PatientThunk.updateBabyMedicalRecord.fulfilled]: (state, action) => {
      //state.globalRecord = action.payload.data.response;
      state.bmrn1data = action.payload.response;
      state.isBmrnEdit = true;
      state.checkedFinalDiagnosis = false;
    },
    [PatientThunk.reqRefferralOpinion.fulfilled]: (state, action) => {
      state = action.payload;
    },

    [PatientThunk.linkReferralDoctor.fulfilled]: (state, action) => {
      state = action.payload.response;
    },
    [PatientThunk.sendForRefDrOpinion.fulfilled]: (state, action) => {
      state = action.payload;
    },
    [PatientThunk.submitDrOpinion.fulfilled]: (state, action) => {
      state = action.payload;
    },
    [PatientThunk.saveglobalrecordid.fulfilled]: (state, action) => {
      state.globalRecord = action.payload.response;
      state.successMessage = "";
      state.errorMessage = "";
      state.isMotherEdit = false;
      state.isBabyEdit = false;
      state.isBabyCNS = false;
      state.isBabyCVEdit = false;
      state.checkedFinalDiagnosis = false;
      state.isBabyFinalDiagEdit = false;
      state.isBabyApperance = false;
      state.isBabyRespiratory = false;
      state.isBabyGIT = false;
      state.isBabyInv = false;
      state.isBabyAntibiotic = false;
      state.babyData = babyFormMock;
      state.motherData = motherFormMock;
      //state.babyHealthParameters = babyHealthParametersFormMock;
      state.babyCNSData = babyCNSDataMock;
      state.babyCVData = babyCVDataMock;
      state.babyGITData = babyGITDataMock;
      state.babyFinalDiagData = babyFinalDiagnosis;
    },
    [PatientThunk.getNewReading.fulfilled]: (state, action) => {
      state = action.payload;
    },
    [PatientThunk.getbabyAppearbyreadingid.fulfilled]: (state, action) => {
      //state.getbabyAppearbyreadingid = action.payload;
      state.babyappearance = action.payload.response;
    },
    [PatientThunk.getBabyProfileByReadingId.fulfilled]: (state, action) => {
      state.getBabyProfile = action.payload.response;
    },
    [PatientThunk.getMotherProfileByReadingId.fulfilled]: (state, action) => {
      state.getMotherProfile = action.payload.response;
    },

    [PatientThunk.getBabyHealthParametersByReadingId.fulfilled]: (
      state,
      action
    ) => {
      state.babyHealthParameters = action.payload.response[0];
    },
    [PatientThunk.getBabyRespiratoryByReadingId.fulfilled]: (state, action) => {
      state.babyRespiratoryData = action.payload.response;
    },
    [PatientThunk.getBabyCNSDataByReadingId.fulfilled]: (state, action) => {
      state.babyCNSData = action.payload.response;
    },
    [PatientThunk.getBabyCVFByReadingId.fulfilled]: (state, action) => {
      state.babyCVData = action.payload.response;
    },

    [PatientThunk.multiPartFileUpload.fulfilled]: (state, action) => {
      state = action.payload.response;
    },
    [PatientThunk.getFilesDetailsFromTable.fulfilled]: (state, action) => {
      state = action.payload.response;
    },
    [PatientThunk.getSendOpinion.fulfilled]: (state, action) => {
      state.sendOpinion = action.payload.response[0];
    },
    [PatientThunk.getDrOpinionByReading.fulfilled]: (state, action) => {
      state.DrOpinionByReading = action.payload.response[0];
    },
    [PatientThunk.downloadReport.fulfilled]: (state, action) => {
      state = action.payload.response;
    },
    [PatientThunk.deleteReport.fulfilled]: (state, action) => {
      state = action.payload.response;
    },
    [PatientThunk.addReferralDoctorToTable.fulfilled]: (state, action) => {
      state = action.payload.response;
    },
    [PatientThunk.getRefDoctorsFromTable.fulfilled]: (state, action) => {
      state = action.payload.response;
    },
    [PatientThunk.getVerifiedReferralDoctors.fulfilled]: (state, action) => {
      state = action.payload.response;
    },
    [PatientThunk.addOtpToTable.fulfilled]: (state, action) => {
      state = action.payload.response;
    },
    [PatientThunk.verifyOTP.fulfilled]: (state, action) => {
      state = action.payload.response;
    },
    [PatientThunk.ResendOTP.fulfilled]: (state, action) => {
      state = action.payload.response;
    },
    [PatientThunk.getbmrnlist.fulfilled]: (state, action) => {
      state.getbmrnlist = {};
    },
    [PatientThunk.getBabyGITByReadingId.fulfilled]: (state, action) => {
      state.babyGITData = action.payload.response;
    },
    [PatientThunk.getBabyAntibioticDataByReadingId.fulfilled]: (
      state,
      action
    ) => {
      state.babyAntibioticData = action.payload.response;
    },
    [PatientThunk.getBabyInvDataByReadingId.fulfilled]: (state, action) => {
      state.babyInvData = action.payload.response;
    },
    [PatientThunk.getBabyFinalDiagByReadingId.fulfilled]: (state, action) => {
      state.BabyFinalDiagByReadingId = action.payload;
    },
    [PatientThunk.deletePatientReading.fulfilled]: (state, action) => {
      state.DeleteReading = action.payload;
    },
    [PatientThunk.updateScore.fulfilled]: (state, action) => {
      state.updateScore = action.payload;
    },

    [PatientThunk.getDataEntryScreenByStudyID.fulfilled]: (state, action) => {
      state.bmrndata = action.payload.response;
      state.formTab = 3;
    },
    [PatientThunk.getPatientDetailsByStudyId.fulfilled]: (state, action) => {
      state.ptDetailsVyStdId = action.payload.response;
    },
    [PatientThunk.getPatientDetailsView.fulfilled]: (state, action) => {
      state.PtDetailsView = action.payload.response;
    },
    [PatientThunk.clearBasicInfos.fulfilled]: (state, action) => {
      state.bmrndata = {};
      state.babyData = {};
      state.bmrndata1 = {};
      state.motherData = {};
    },

    [PatientThunk.createBabyQuickSepsis.fulfilled]: (state, action) => {
      state.QuickSepsis = action.payload.response;
    },
    [PatientThunk.updateBabyQuickSepsis.fulfilled]: (state, action) => {
      state.QuickSepsis = action.payload.response;
    },

    [PatientThunk.getBabyQuickSepsis.fulfilled]: (state, action) => {
      state.getBabyQuickSepsis = action.payload.data.response[0];
    },
    /*  [PatientThunk.setQuickSepsisParameters.fulfilled]: (state, action) => {
      state.quickSepsisParameters = action.payload;
    }, */

    [PatientThunk.createBabySemiInvasive.fulfilled]: (state, action) => {
      state.BabySemiInvasive = action.payload.response;
    },
    [PatientThunk.updateBabySemiInvasive.fulfilled]: (state, action) => {
      state.BabySemiInvasiveUpdate = action.payload.response;
    },
    [PatientThunk.getBabySemiInvasive.fulfilled]: (state, action) => {
      state.BabySemiInvasive = action.payload.data.response[0];
    },
  },
});

export default patientSlice.reducer;
