import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Card,
  CardContent,
  MenuItem,
  Select,
  Button,
  Snackbar,
  InputAdornment,
} from "@mui/material";

import "../styles/DataEntry.scss";

const ALPHA = /^[a-zA-Z ]+$/;
const Numerics = /^[0-9]*$/;
const Numerics1 = /^[0-9.]*$/;
import { ToastContainer, toast } from "react-toastify";

import {
  maternalAddition,
  maternalProfileUpdate,
  getMotherProfileByReadingId,
  setMotherData,
} from "../reducers/patientDataThunk";

const MotherProfile = (props) => {
  const patient = useSelector((state) => state.patient);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const study_id = patient.bmrndata?.study_id;
  const loggedUserId = user.userProfile?.user_id;

  const [updateRecord, setUpdateRecord] = useState();
  const [motherHeight, setMotherHeight] = useState();
  const [motherWeight, setMotherWeight] = useState();
  const [motherHeightType, setMotherHeightType] = useState("Ft");
  const [motherWeightType, setMotherWeightType] = useState("Kgs");
  // console.log(motherWeightType);
  const [convertHeight, setconvertHeight] = useState();
  const [convertWeight, setconvertWeight] = useState();
  const [BMI, setBMI] = useState();
  const [errorFields, setErrorFields] = useState({});
  const [showMotherAgeErrorMessage, setShowMotherAgeErrorMessage] = useState();
  const [showHaemoglobinErrorMessage, setShowHaemoglobinErrorMessage] =
    useState();
  const [showMotherHeightErrorMessage, setShowMotherHeightErrorMessage] =
    useState();
  const [showMotherWeightErrorMessage, setShowMotherWeightErrorMessage] =
    useState();
  const [showBPSystolicErrorMessage, setShowBPSystolicErrorMessage] =
    useState();

  const [showBPDiastolicErrorMessage, setShowBPDiastolicErrorMessage] =
    useState();
  const [
    showMaternalFeverDurationErrorMessage,
    setShowMaternalFeverDurationErrorMessage,
  ] = useState();
  const [showMaternalFeverErrorMessage, setShowMaternalFeverErrorMessage] =
    useState();
  const [showMaternalTSHErrorMessage, setShowMaternalTSHErrorMessage] =
    useState();
  const [showMaternalT3ErrorMessage, setShowMaternalT3ErrorMessage] =
    useState();
  const [showMaternalT4ErrorMessage, setShowMaternalT4ErrorMessage] =
    useState();
  const [showVSC3ErrorMessage, setShowVSC3ErrorMessage] = useState();
  const [showAFC3ErrorMessage, setShowAFC3ErrorMessage] = useState();
  useEffect(() => {
    setBMI(Math.round(convertWeight / (convertHeight * convertHeight)));
  }, [convertHeight, convertWeight]);
  const [addMotherData, setAddMotherData] = useState(patient.motherData);

  useEffect(() => {
    const newFormData = { ...addMotherData };
    newFormData["mother_bmi"] = BMI;

    // console.log("Field Value is ");
    // console.log(newFormData);

    setAddMotherData(newFormData);
    setMotherData(newFormData);
    props.updateBabyMaternalData(newFormData);
  }, [BMI]);

  useEffect(() => {
    if (motherWeightType == "lbs") {
      const convert_Weight = motherWeight * 0.453;
      setconvertWeight(convert_Weight);
    } else if (motherWeightType == "kgs") {
      setconvertWeight(motherWeight);
    }
  });

  useEffect(() => {
    if (motherHeightType == "Ft") {
      const convert_height = motherHeight * 0.3048;
      setconvertHeight(convert_height);
    } else if (motherHeightType == "Cms") {
      const convert_height = motherHeight / 100;
      setconvertHeight(convert_height);
    }
  });

  useEffect(() => {
    setAddMotherData(patient.motherData);
  }, [patient.motherData]);

  useEffect(() => {
    updateData();
  }, [patient?.babyData?.study_id]);

  useEffect(() => {
    props.updateBabyMaternalData(addMotherData);
  }, [addMotherData]);

  const updateData = (event) => {
    const req = { study_id };
    dispatch(getMotherProfileByReadingId(req))
      .unwrap()
      .then((resdata) => {
        let MotherData = {
          response: Object.assign({}, resdata.response),
        };

        const data =
          MotherData.response && MotherData.response
            ? MotherData.response
            : undefined;
        const data1 =
          MotherData.response && MotherData.response.id
            ? setUpdateRecord(true)
            : setUpdateRecord(false);

        if (resdata.response) {
          setAddMotherData(
            MotherData.response && MotherData.response
              ? MotherData.response
              : {}
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleAddFormChange = (event) => {
    // console.log("We are in handle form change");
    const fieldname = event.target.name;
    // console.log("Field Name is ");
    // console.log(fieldname);

    const fieldvalue = event.target.value;
    // console.log("Field Value is ");
    // console.log(fieldvalue);

    const newFormData = { ...addMotherData };
    newFormData[fieldname] = fieldvalue;

    // console.log("Field Value is ");
    // console.log(newFormData);

    setAddMotherData(newFormData);
    setMotherData(newFormData);
    props.updateBabyMaternalData(newFormData);
  };

  const HandleAddFormSubmit = (event) => {
    event.preventDefault();
    const newFormData = { ...addMotherData };
    // console.log("Data That the Application is sending is,");
    // console.log(newFormData);
    // console.log("About to call Axios API");
    for (const key in newFormData) {
      if (!newFormData[key]) {
        newFormData[key] = "NA";
      }
    }
    newFormData.study_id = patient.bmrndata?.study_id;
    newFormData.loggedUserId = loggedUserId;
    newFormData.mother_bmi = BMI;
    if (!updateRecord) {
      //const req = {study_id, loggedIdUserId, newFormData};
      dispatch(maternalAddition(newFormData))
        .unwrap()
        .then((resdata) => {
          // console.log(resdata);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      const req = { study_id, data: newFormData, loggedUserId };
      dispatch(maternalProfileUpdate(req))
        .unwrap()
        .then((resdata) => {
          // console.log(resdata);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const verifyMotherAge = (event) => {
    if (event.target.value >= 10 && event.target.value <= 100) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          mother_age: false,
        })
      );
      setShowMotherAgeErrorMessage("");
    } else {
      setShowMotherAgeErrorMessage("Mother Age Should Be Between 10 To 100");
      setErrorFields(
        Object.assign({}, errorFields, {
          mother_age: true,
        })
      );
    }
  };
  const verifyWeightType = (event) => {
if(motherWeight){
    if (event.target.value == "kgs") {
      let temp1 = motherWeight.toString();
      let decimalValue1 = temp1.split(".");
      let minValue = 1,
        maxValue = 99;
      if (
        motherWeight <= maxValue &&
        motherWeight >= minValue &&
        (!decimalValue1[1] || decimalValue1[1].length <= 2)
      ) {
        handleAddFormChange(event);
        setErrorFields(
          Object.assign({}, errorFields, {
            mother_weight: false,
          })
        );

        setShowMotherWeightErrorMessage("");
      } else {
        setShowMotherWeightErrorMessage(
          "Weight Of Mother Should Be Between 19 To 99 Kgs, it should have only two decimal positions."
        );
        document.getElementById("WeightOfMother").focus();
        setErrorFields(
          Object.assign({}, errorFields, {
            mother_weight: true,
          })
        );
      }
    }
    else if (event.target.value == "lbs") {
      let temp1 = motherWeight.toString();
      let decimalValue1 = temp1.split(".");
      let minValue = 45,
        maxValue = 220;
      if (
        motherWeight <= maxValue &&
        motherWeight >= minValue &&
        (!decimalValue1[1] || decimalValue1[1].length <= 2)
      ) {
        handleAddFormChange(event);
        setErrorFields(
          Object.assign({}, errorFields, {
            mother_weight: false,
          })
        );

        setShowMotherWeightErrorMessage("");
      } else {
        setShowMotherWeightErrorMessage(
          "Weight Of Mother Should Be Between 45 To 220 Lbs, it should have only two decimal positions."
        );
        document.getElementById("WeightOfMother").focus();
        setErrorFields(
          Object.assign({}, errorFields, {
            mother_weight: true,
          })
        );
      }
    }
  }
    
  }

  const verifyWeightOfMother = (event) => {
    if (motherWeightType == "kgs") {
      let temp1 = event.target.value.toString();
      let decimalValue1 = temp1.split(".");
      let minValue = 1,
        maxValue = 99;
      if (
        event.target.value <= maxValue &&
        event.target.value >= minValue &&
        (!decimalValue1[1] || decimalValue1[1].length <= 2)
      ) {
        handleAddFormChange(event);
        setErrorFields(
          Object.assign({}, errorFields, {
            mother_weight: false,
          })
        );

        setShowMotherWeightErrorMessage("");
      } else {
        setShowMotherWeightErrorMessage(
          "Weight Of Mother Should Be Between 19 To 99 Kgs, it should have only two decimal positions."
        );
        document.getElementById("WeightOfMother").focus();
        setErrorFields(
          Object.assign({}, errorFields, {
            mother_weight: true,
          })
        );
      }
    } 
    else if (motherWeightType == "lbs") {
      let temp1 = event.target.value.toString();
      let decimalValue1 = temp1.split(".");
      let minValue = 45,
        maxValue = 220;
      if (
        event.target.value <= maxValue &&
        event.target.value >= minValue &&
        (!decimalValue1[1] || decimalValue1[1].length <= 2)
      ) {
        handleAddFormChange(event);
        setErrorFields(
          Object.assign({}, errorFields, {
            mother_weight: false,
          })
        );

        setShowMotherWeightErrorMessage("");
      } else {
        setShowMotherWeightErrorMessage(
          "Weight Of Mother Should Be Between 45 To 220 Lbs, it should have only two decimal positions."
        );
        document.getElementById("WeightOfMother").focus();
        setErrorFields(
          Object.assign({}, errorFields, {
            mother_weight: true,
          })
        );
      }
    }
  };

  const verifyMotherHeightType = (event) => {
    if(motherHeight){
    if (event.target.value == "Ft") {
      let temp1 = motherHeight.toString();
      let decimalValue1 = temp1.split(".");
      let minValue = 3,
        maxValue = 8;
      if (
        motherHeight <= maxValue &&
        motherHeight >= minValue &&
        (!decimalValue1[1] || decimalValue1[1].length <= 2)
      ) {
        handleAddFormChange(event);
        setErrorFields(
          Object.assign({}, errorFields, {
            mother_height: false,
          })
        );
        setShowMotherHeightErrorMessage("");
      } else {
        setShowMotherHeightErrorMessage(
          "Height Of Mother Should Be Between 3 To 8 Ft, it should have only two decimal positions."
        );
        setErrorFields(
          Object.assign({}, errorFields, {
            mother_height: true,
          })
        );
      }
    }
    else if (event.target.value == "Cms") {
      let temp1 = motherHeight.toString();
      let decimalValue1 = temp1.split(".");
      let minValue = 40,
        maxValue = 250;
      if (
        motherHeight <= maxValue &&
        motherHeight >= minValue &&
        (!decimalValue1[1] || decimalValue1[1].length <= 2)
      ) {
        handleAddFormChange(event);
        setErrorFields(
          Object.assign({}, errorFields, {
            mother_height: false,
          })
        );
        setShowMotherHeightErrorMessage("");
      } else {
        setShowMotherHeightErrorMessage(
          "Height Of Mother Should Be Between 40 To 250 Cms, it should have only two decimal positions."
        );
        setErrorFields(
          Object.assign({}, errorFields, {
            mother_height: true,
          })
        );
      }
    }
  }
  }

  const verifyHeightofMother = (event) => {
    if (motherHeightType == "Ft") {
      let temp1 = event.target.value.toString();
      let decimalValue1 = temp1.split(".");
      let minValue = 3,
        maxValue = 8;
      if (
        event.target.value <= maxValue &&
        event.target.value >= minValue &&
        (!decimalValue1[1] || decimalValue1[1].length <= 2)
      ) {
        handleAddFormChange(event);
        setErrorFields(
          Object.assign({}, errorFields, {
            mother_height: false,
          })
        );
        setShowMotherHeightErrorMessage("");
      } else {
        setShowMotherHeightErrorMessage(
          "Height Of Mother Should Be Between 3 To 8 Ft, it should have only two decimal positions."
        );
        setErrorFields(
          Object.assign({}, errorFields, {
            mother_height: true,
          })
        );
      }
    } 
    else if (motherHeightType == "Cms") {
      let temp1 = event.target.value.toString();
      let decimalValue1 = temp1.split(".");
      let minValue = 40,
        maxValue = 250;
      if (
        event.target.value <= maxValue &&
        event.target.value >= minValue &&
        (!decimalValue1[1] || decimalValue1[1].length <= 2)
      ) {
        handleAddFormChange(event);
        setErrorFields(
          Object.assign({}, errorFields, {
            mother_height: false,
          })
        );
        setShowMotherHeightErrorMessage("");
      } else {
        setShowMotherHeightErrorMessage(
          "Height Of Mother Should Be Between 40 To 250 Cms, it should have only two decimal positions."
        );
        setErrorFields(
          Object.assign({}, errorFields, {
            mother_height: true,
          })
        );
      }
    }
  };

  const verifyMotherHaemoglobin = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 1,
      maxValue = 50;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          mother_haemoglobin: false,
        })
      );
      setShowHaemoglobinErrorMessage("");
    } else {
      setShowHaemoglobinErrorMessage(
        "Mother Haemoglobin Should Be Between 0 To 50, it should have only two decimal positions."
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          mother_haemoglobin: true,
        })
      );
    }
  };

  const verifyMaternalBloodPressureSystolic = (event) => {
    if (event.target.value >= 50 && event.target.value <= 200) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          maternal_blood_pressure: false,
        })
      );
      setShowBPSystolicErrorMessage("");
    } else {
      setShowBPSystolicErrorMessage(
        "Maternal Blood Pressure Systolic Value Should Be Between 50 To 200"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          maternal_blood_pressure: true,
        })
      );
    }
  };

  const verifyMaternalBloodPressureDiastolic = (event) => {
    if (event.target.value >= 50 && event.target.value <= 200) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          maternal_blood_pressure_diastolic: false,
        })
      );
      setShowBPDiastolicErrorMessage("");
    } else {
      setShowBPDiastolicErrorMessage(
        "Maternal Blood Pressure Diastolic Should Be Between 50 To 200"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          maternal_blood_pressure_diastolic: true,
        })
      );
    }
  };

  const verifyMaternalFeverUnitValue = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 10,
      maxValue = 150;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          maternal_fever: false,
        })
      );

      setShowMaternalFeverErrorMessage("");
    } else {
      setShowMaternalFeverErrorMessage(
        "Maternal Fever Unit Value Should Be Between 10 To 150, it should have only two decimal positions."
      );
      document.getElementById("MaternalFeverUnitValue").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          maternal_fever: true,
        })
      );
    }
  };

  const verifyMaternalFeverDuration = (event) => {
    if (event.target.value >= 0 && event.target.value <= 100) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          maternal_fever_duration: false,
        })
      );
      setShowMaternalFeverDurationErrorMessage("");
    } else {
      setShowMaternalFeverDurationErrorMessage(
        "Maternal Fever Duration Should Be Between 0 To 100"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          maternal_fever_duration: true,
        })
      );
    }
  };

  const verifyMaternalThyroidFunctionUnit = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 1,
      maxValue = 25;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          maternal_thyroid_function_unit_basic: false,
        })
      );
      setShowMaternalTSHErrorMessage("");
    } else {
      setShowMaternalTSHErrorMessage(
        "Maternal Thyroid Function Unit Should Be Between 1 To 25, it should have only two decimal positions."
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          maternal_thyroid_function_unit_basic: true,
        })
      );
    }
  };

  const verifyMaternalThyroidFunctionT3 = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 1,
      maxValue = 25;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          maternal_thyroid_t3: false,
        })
      );
      setShowMaternalT3ErrorMessage("");
    } else {
      setShowMaternalT3ErrorMessage(
        "Maternal Thyroid Function T3 Should Be Between 1 To 25, it should have only two decimal positions."
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          maternal_thyroid_t3: true,
        })
      );
    }
  };

  const verifyMaternalThyroidFunctionT4 = (event) => {
    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 1,
      maxValue = 25;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          maternal_thyroid_t4: false,
        })
      );
      setShowMaternalT4ErrorMessage("");
    } else {
      setShowMaternalT4ErrorMessage(
        "Maternal Thyroid Function T4 Should Be Between 1 To 25, it should have only two decimal positions."
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          maternal_thyroid_t4: true,
        })
      );
    }
  };

  const verifyAmnioticFluidCultureThree = (event) => {

    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 1,
      maxValue = 25;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          amniotic_fluid_culture_three: false,
        })
      );
      setShowAFC3ErrorMessage("");
    } else {
      setShowAFC3ErrorMessage(
        "AF Index Culture Should Be Between 1 To 25, it should have only two decimal positions"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          amniotic_fluid_culture_three: true,
        })
      );
    }
  };

  const verifyVaginalSwabCultureThree = (event) => {

    let temp1 = event.target.value.toString();
    let decimalValue1 = temp1.split(".");
    let minValue = 1,
      maxValue = 25;
    if (
      event.target.value <= maxValue &&
      event.target.value >= minValue &&
      (!decimalValue1[1] || decimalValue1[1].length <= 2)
    ) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          vaginal_swab_culture_three: false,
        })
      );
      setShowVSC3ErrorMessage("");
    } else {
      setShowVSC3ErrorMessage(
        "Vaginal Swab Culture Should Be Between 1 To  25, it should have only two decimal positions"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          vaginal_swab_culture_three: true,
        })
      );
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Card variant="none">
        <CardContent>
          <form onSubmit={HandleAddFormSubmit} id="mother-medical-form">
            <Grid container>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="Mother Record ID"
                  className="dataentry_label"
                  style={{ display: "block" }}
                >
                  Record Id
                </label>
                <TextField
                  id="study_id"
                  name="study_id"
                  label=""
                  variant="outlined"
                  value={patient.bmrndata?.study_id}
                  onChange={handleAddFormChange}
                  className="dataentry_input"
                  size="small"
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="Mother Age"
                  className="dataentry_label"
                  style={{ display: "block" }}
                >
                  Age
                </label>
                <TextField
                  id="MotherAge"
                  name="mother_age"
                  label=""
                  type="number"
                  value={addMotherData?.mother_age}
                  variant="outlined"
                  className={
                    "dataentry_input " +
                    (errorFields.mother_age ? "errorField" : "")
                  }
                  size="small"
                  onChange={(e) => {
                    handleAddFormChange(e);
                    verifyMotherAge(e);
                  }}
                  error={errorFields.mother_age && showMotherAgeErrorMessage}
                  errorText={showMotherAgeErrorMessage}
                  helperText={
                    errorFields.mother_age && showMotherAgeErrorMessage
                      ? showMotherAgeErrorMessage
                      : ""
                  }
                  onKeyDown={(event) => {
                    if (
                      !Numerics1.test(event.key) &&
                      event.key != "Backspace"
                    ) {
                      event.preventDefault();
                      setShowMotherAgeErrorMessage(
                        "Only Numerics are Acceptable"
                      );
                      setErrorFields(
                        Object.assign({}, errorFields, {
                          mother_age: true,
                        })
                      );
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" className="InputAdornment">
                        Years
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="Weight of the mother"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  Weight
                </label>
                <Grid container>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      id="WeightOfMother"
                      label=""
                      name="mother_weight"
                      value={addMotherData?.mother_weight}
                      onChange={(e) => {
                        handleAddFormChange(e);
                        verifyWeightOfMother(e);
                        setMotherWeight(e.target.value);
                      }}
                      error={
                        errorFields.mother_weight &&
                        showMotherWeightErrorMessage
                      }
                      errorText={showMotherWeightErrorMessage}
                      helperText={
                        errorFields.mother_weight &&
                        showMotherWeightErrorMessage
                          ? showMotherWeightErrorMessage
                          : ""
                      }
                      onKeyDown={(event) => {
                        if (
                          !Numerics1.test(event.key) &&
                          event.key != "Backspace"
                        ) {
                          event.preventDefault();
                          setShowMotherWeightErrorMessage(
                            "Only Numerics are Acceptable"
                          );
                          setErrorFields(
                            Object.assign({}, errorFields, {
                              mother_weight: true,
                            })
                          );
                        }
                      }}
                      variant="outlined"
                      size="small"
                      className={
                        "dataentry_input " +
                        (errorFields.mother_weight ? "errorField" : "")
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Select
                      labelId="weight"
                      id="weight"
                      name="mother_weight_unit"
                      //value={addMotherData?.mother_weight_unit}
                      value={addMotherData?.mother_weight_unit ? addMotherData?.mother_weight_unit : "kgs"}
                      label=""
                      onChange={(e) => {
                        handleAddFormChange(e);
                        setMotherWeightType(e.target.value);
                        verifyWeightType(e);
                      }}
                      size="small"
                      className="dataentry_select"
                    >
                      <MenuItem value="kgs">Kgs</MenuItem>
                      <MenuItem value="lbs">Lbs</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="Height"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  Height
                </label>
                <Grid container>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      id="HeightofMother"
                      label=""
                      name="mother_height"
                      value={addMotherData?.mother_height}
                      //onChange={handleAddFormChange}
                      variant="outlined"
                      size="small"
                      className={
                        "dataentry_input " +
                        (errorFields.mother_height ? "errorField" : "")
                      }
                      onChange={(e) => {
                        handleAddFormChange(e);
                        verifyHeightofMother(e);
                        setMotherHeight(e.target.value);
                      }}
                      error={
                        errorFields.mother_height &&
                        showMotherHeightErrorMessage
                      }
                      errorText={showMotherHeightErrorMessage}
                      helperText={
                        errorFields.mother_height &&
                        showMotherHeightErrorMessage
                          ? showMotherHeightErrorMessage
                          : ""
                      }
                      onKeyDown={(event) => {
                        if (
                          !Numerics1.test(event.key) &&
                          event.key != "Backspace"
                        ) {
                          event.preventDefault();
                          setShowMotherHeightErrorMessage(
                            "Only Numerics are Acceptable"
                          );
                          setErrorFields(
                            Object.assign({}, errorFields, {
                              mother_height: true,
                            })
                          );
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Select
                      labelId="Height"
                      id="MotherHeightUnit"
                      name="mother_height_unit"
                      //value={addMotherData?.mother_height_unit}
                      value={addMotherData?.mother_height_unit ? addMotherData?.mother_height_unit : "Ft"}
                      
                      label=""
                      //onChange={handleAddFormChange}
                      size="small"
                      className="dataentry_select"
                      onChange={(e) => {
                        handleAddFormChange(e);
                        setMotherHeightType(e.target.value);
                        verifyMotherHeightType(e);
                      }}
                    >
                      <MenuItem value="Ft">Ft</MenuItem>
                      <MenuItem value="Cms">cms</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="BMI"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  BMI
                </label>
                <TextField
                  id="mother_bmi"
                  name="mother_bmi"
                  label=""
                  value={BMI}
                  variant="outlined"
                  onChange={handleAddFormChange}
                  className="dataentry_input"
                  size="small"
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="Haemoglobin"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  Haemoglobin
                </label>
                <TextField
                  name="mother_haemoglobin"
                  id="MotherHaemoglobin"
                  className={
                    "dataentry_input " +
                    (errorFields.mother_haemoglobin ? "errorField" : "")
                  }
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  value={addMotherData?.mother_haemoglobin}
                  onChange={(e) => {
                    handleAddFormChange(e);
                    verifyMotherHaemoglobin(e);
                  }}
                  error={
                    errorFields.mother_haemoglobin &&
                    showHaemoglobinErrorMessage
                  }
                  errorText={showHaemoglobinErrorMessage}
                  helperText={
                    errorFields.mother_haemoglobin &&
                    showHaemoglobinErrorMessage
                      ? showHaemoglobinErrorMessage
                      : ""
                  }
                  onKeyDown={(event) => {
                    if (
                      !Numerics1.test(event.key) &&
                      event.key != "Backspace"
                    ) {
                      event.preventDefault();
                      setShowHaemoglobinErrorMessage(
                        "Only Numerics are Acceptable"
                      );
                      setErrorFields(
                        Object.assign({}, errorFields, {
                          mother_haemoglobin: true,
                        })
                      );
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" className="InputAdornment">
                        g/dL
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="BPSystolic"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  Blood Pressure(Systolic)
                </label>
                <TextField
                  id="maternalBloodPressureSystolic"
                  name="maternal_blood_pressure"
                  className={
                    "dataentry_input " +
                    (errorFields.maternal_blood_pressure ? "errorField" : "")
                  }
                  variant="outlined"
                  size="small"
                  value={addMotherData?.maternal_blood_pressure}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" className="InputAdornment">
                        mmHg
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    handleAddFormChange(e);
                    verifyMaternalBloodPressureSystolic(e);
                  }}
                  error={
                    errorFields.maternal_blood_pressure &&
                    showBPSystolicErrorMessage
                  }
                  errorText={showBPSystolicErrorMessage}
                  helperText={
                    errorFields.maternal_blood_pressure &&
                    showBPSystolicErrorMessage
                      ? showBPSystolicErrorMessage
                      : ""
                  }
                  onKeyDown={(event) => {
                    if (!Numerics.test(event.key) && event.key != "Backspace") {
                      event.preventDefault();
                      setShowBPSystolicErrorMessage(
                        "Only Numerics are Acceptable"
                      );
                      setErrorFields(
                        Object.assign({}, errorFields, {
                          maternal_blood_pressure: true,
                        })
                      );
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="BPDiastolic"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  Blood Pressure (Diastolic)
                </label>
                <TextField
                  id="MaternalBloodPressureDiastolic"
                  name="maternal_blood_pressure_diastolic"
                  className={
                    "dataentry_input " +
                    (errorFields.maternal_blood_pressure_diastolic
                      ? "errorField"
                      : "")
                  }
                  variant="outlined"
                  size="small"
                  //required
                  //disabled={!defaultCheck.BPDiastolicChecked}
                  value={addMotherData?.maternal_blood_pressure_diastolic}
                  onChange={(e) => {
                    handleAddFormChange(e);
                    verifyMaternalBloodPressureDiastolic(e);
                  }}
                  error={
                    errorFields.maternal_blood_pressure_diastolic &&
                    showBPDiastolicErrorMessage
                  }
                  errorText={showBPDiastolicErrorMessage}
                  helperText={
                    errorFields.maternal_blood_pressure_diastolic &&
                    showBPDiastolicErrorMessage
                      ? showBPDiastolicErrorMessage
                      : ""
                  }
                  onKeyDown={(event) => {
                    if (!Numerics.test(event.key) && event.key != "Backspace") {
                      event.preventDefault();
                      setShowBPDiastolicErrorMessage(
                        "Only Numerics are Acceptable"
                      );
                      setErrorFields(
                        Object.assign({}, errorFields, {
                          maternal_blood_pressure_diastolic: true,
                        })
                      );
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" className="InputAdornment">
                        mmHg
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="Diabetes"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  Diabetes (Present)
                  {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                </label>
                <ToggleButtonGroup
                  color="primary"
                  value={addMotherData?.maternal_diabetes}
                  exclusive
                  onChange={handleAddFormChange}
                  className="Toggle_input"
                  //required
                >
                  <ToggleButton
                    name="maternal_diabetes"
                    value="YES"
                    size="small"
                    className="dataentry_input"
                  >
                    Yes
                  </ToggleButton>
                  <ToggleButton
                    name="maternal_diabetes"
                    value="NO"
                    size="small"
                    className="dataentry_input"
                  >
                    No
                  </ToggleButton>
                  <ToggleButton
                    name="maternal_diabetes"
                    value="NA"
                    size="small"
                    className="dataentry_input"
                  >
                    NA
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="Fever_present"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  Fever (Present)
                </label>

                <ToggleButtonGroup
                  color="primary"
                  value={addMotherData?.maternal_fever_basic}
                  exclusive
                  onChange={handleAddFormChange}
                  className="Toggle_input"
                >
                  <ToggleButton
                    name="maternal_fever_basic"
                    value="YES"
                    size="small"
                    className="dataentry_input"
                  >
                    Yes
                  </ToggleButton>
                  <ToggleButton
                    name="maternal_fever_basic"
                    value="NO"
                    size="small"
                    className="dataentry_input"
                  >
                    No
                  </ToggleButton>
                  <ToggleButton
                    name="maternal_fever_basic"
                    value="NA"
                    size="small"
                    className="dataentry_input"
                  >
                    NA
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="Fever Units"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  Fever (Units)
                </label>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="MaternalFeverUnitValue"
                      label=""
                      name="maternal_fever"
                      value={addMotherData?.maternal_fever}
                      variant="outlined"
                      size="small"
                      className={
                        "dataentry_input " +
                        (errorFields.maternal_fever ? "errorField" : "")
                      }
                      onChange={(e) => {
                        handleAddFormChange(e);
                        verifyMaternalFeverUnitValue(e);
                      }}
                      error={
                        errorFields.maternal_fever &&
                        showMaternalFeverErrorMessage
                      }
                      errorText={showMaternalFeverErrorMessage}
                      helperText={
                        errorFields.maternal_fever &&
                        showMaternalFeverErrorMessage
                          ? showMaternalFeverErrorMessage
                          : ""
                      }
                      onKeyDown={(event) => {
                        if (
                          !Numerics1.test(event.key) &&
                          event.key != "Backspace"
                        ) {
                          event.preventDefault();
                          setShowMaternalFeverErrorMessage(
                            "Only Numerics are Acceptable"
                          );
                          setErrorFields(
                            Object.assign({}, errorFields, {
                              maternal_fever: true,
                            })
                          );
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Select
                      labelId="feverUnit"
                      id="feverUnit"
                      name="maternal_fever_unit"
                      //value={addMotherData?.maternal_fever_unit}
                      value={addMotherData?.maternal_fever_unit ? addMotherData?.maternal_fever_unit : "centrigrade"}
                      
                      label=""
                      onChange={handleAddFormChange}
                      //disabled={!defaultCheck.MaternalFeverChecked}
                      size="small"
                      className="dataentry_select1"
                    >
                      <MenuItem value="centrigrade">Centrigrade</MenuItem>
                      <MenuItem value="Fahrenheit">Fahrenheit</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="MaternalFevereDurationCheckBox"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  Fever Duration
                </label>
                <TextField
                  id="MaternalFeverDuration"
                  name="maternal_fever_duration"
                  variant="outlined"
                  size="small"
                  className={
                    "dataentry_input " +
                    (errorFields.maternal_fever_duration ? "errorField" : "")
                  }
                  //disabled={!defaultCheck.FeverDurationChecked}
                  value={addMotherData?.maternal_fever_duration}
                  onChange={(e) => {
                    handleAddFormChange(e);
                    verifyMaternalFeverDuration(e);
                  }}
                  error={
                    errorFields.maternal_fever_duration &&
                    showMaternalFeverDurationErrorMessage
                  }
                  errorText={showMaternalFeverDurationErrorMessage}
                  helperText={
                    errorFields.maternal_fever_duration &&
                    showMaternalFeverDurationErrorMessage
                      ? showMaternalFeverDurationErrorMessage
                      : ""
                  }
                  onKeyDown={(event) => {
                    if (!Numerics.test(event.key) && event.key != "Backspace") {
                      event.preventDefault();
                      setShowMaternalFeverDurationErrorMessage(
                        "Only Numerics are Acceptable"
                      );
                      setErrorFields(
                        Object.assign({}, errorFields, {
                          maternal_fever_duration: true,
                        })
                      );
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" className="InputAdornment">
                        Days
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="ThyroidFunction"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  Thyroid Function
                </label>
                <ToggleButtonGroup
                  color="primary"
                  value={addMotherData?.maternal_thyroid_function}
                  exclusive
                  onChange={handleAddFormChange}
                  className="Toggle_input"
                >
                  <ToggleButton
                    name="maternal_thyroid_function"
                    value="Abnormal"
                    size="small"
                    className="dataentry_input"
                  >
                    Abnormal
                  </ToggleButton>
                  <ToggleButton
                    name="maternal_thyroid_function"
                    value="Normal"
                    size="small"
                    className="dataentry_input"
                  >
                    Normal
                  </ToggleButton>
                  <ToggleButton
                    name="maternal_thyroid_function"
                    value="NA"
                    size="small"
                    className="dataentry_input"
                  >
                    NA
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="ThyroidFunctionIfAbnormal"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  Thyroid Function (If ABNORMAL)
                </label>
                <ToggleButtonGroup
                  color="primary"
                  value={addMotherData?.maternal_thyroid_function_basic}
                  exclusive
                  onChange={handleAddFormChange}
                  className="Toggle_input"
                >
                  <ToggleButton
                    name="maternal_thyroid_function_basic"
                    value="HYPO"
                    size="small"
                    className="dataentry_input"
                  >
                    Hypo
                  </ToggleButton>
                  <ToggleButton
                    name="maternal_thyroid_function_basic"
                    value="HYPER"
                    size="small"
                    className="dataentry_input"
                  >
                    Hyper
                  </ToggleButton>
                  <ToggleButton
                    name="maternal_thyroid_function_basic"
                    value="NA"
                    size="small"
                    className="dataentry_input"
                  >
                    NA
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="ThyroidFunctionUnits"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  Thyroid Function (TSH)
                </label>
                <TextField
                  id="MaternalThyroidFunctionUnit"
                  name="maternal_thyroid_function_unit_basic"
                  variant="outlined"
                  size="small"
                  className={
                    "dataentry_input " +
                    (errorFields.time_of_reading_hours ? "errorField" : "")
                  }
                  //disabled={!defaultCheck.FeverDurationChecked}
                  value={addMotherData?.maternal_thyroid_function_unit_basic}
                  onChange={(e) => {
                    handleAddFormChange(e);
                    verifyMaternalThyroidFunctionUnit(e);
                  }}
                  error={
                    errorFields.maternal_thyroid_function_unit_basic &&
                    showMaternalTSHErrorMessage
                  }
                  errorText={showMaternalTSHErrorMessage}
                  helperText={
                    errorFields.maternal_thyroid_function_unit_basic &&
                    showMaternalTSHErrorMessage
                      ? showMaternalTSHErrorMessage
                      : ""
                  }
                  onKeyDown={(event) => {
                    if (
                      !Numerics1.test(event.key) &&
                      event.key != "Backspace"
                    ) {
                      event.preventDefault();
                      setShowMaternalTSHErrorMessage(
                        "Only Numerics are Acceptable"
                      );
                      setErrorFields(
                        Object.assign({}, errorFields, {
                          maternal_thyroid_function_unit_basic: true,
                        })
                      );
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" className="InputAdornment">
                        mU/L
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="ThyroidFunctionUnits"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  Thyroid Function (T3)
                </label>
                <TextField
                  id="MaternalThyroidFunctionT3"
                  name="maternal_thyroid_t3"
                  variant="outlined"
                  size="small"
                  className={
                    "dataentry_input " +
                    (errorFields.maternal_thyroid_t3 ? "errorField" : "")
                  }
                  //disabled={!defaultCheck.FeverDurationChecked}
                  value={addMotherData?.maternal_thyroid_t3}
                  onChange={(e) => {
                    handleAddFormChange(e);
                    verifyMaternalThyroidFunctionT3(e);
                  }}
                  error={
                    errorFields.maternal_thyroid_t3 &&
                    showMaternalT3ErrorMessage
                  }
                  errorText={showMaternalT3ErrorMessage}
                  helperText={
                    errorFields.maternal_thyroid_t3 &&
                    showMaternalT3ErrorMessage
                      ? showMaternalT3ErrorMessage
                      : ""
                  }
                  onKeyDown={(event) => {
                    if (
                      !Numerics1.test(event.key) &&
                      event.key != "Backspace"
                    ) {
                      event.preventDefault();
                      setShowMaternalT3ErrorMessage(
                        "Only Numerics are Acceptable"
                      );
                      setErrorFields(
                        Object.assign({}, errorFields, {
                          maternal_thyroid_t3: true,
                        })
                      );
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" className="InputAdornment">
                        mU/L
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="ThyroidFunctionUnits"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  Thyroid Function (T4)
                </label>
                <TextField
                  id="MaternalThyroidFunctionT4"
                  name="maternal_thyroid_t4"
                  variant="outlined"
                  size="small"
                  className={
                    "dataentry_input " +
                    (errorFields.maternal_thyroid_t4 ? "errorField" : "")
                  }
                  //disabled={!defaultCheck.FeverDurationChecked}
                  value={addMotherData?.maternal_thyroid_t4}
                  onChange={(e) => {
                    handleAddFormChange(e);
                    verifyMaternalThyroidFunctionT4(e);
                  }}
                  error={
                    errorFields.maternal_thyroid_t4 &&
                    setShowMaternalT3ErrorMessage
                  }
                  errorText={showMaternalT4ErrorMessage}
                  helperText={
                    errorFields.maternal_thyroid_t4 &&
                    showMaternalT4ErrorMessage
                      ? showMaternalT4ErrorMessage
                      : ""
                  }
                  onKeyDown={(event) => {
                    if (
                      !Numerics1.test(event.key) &&
                      event.key != "Backspace"
                    ) {
                      event.preventDefault();
                      setShowMaternalT4ErrorMessage(
                        "Only Numerics are Acceptable"
                      );
                      setErrorFields(
                        Object.assign({}, errorFields, {
                          maternal_thyroid_t4: true,
                        })
                      );
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" className="InputAdornment">
                        mU/L
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="MoreThan3VaginalExaminationDuringLabor"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  More than 3 Vaginal Examinations During Labor
                </label>
                <ToggleButtonGroup
                  color="primary"
                  value={
                    addMotherData?.more_than_3_vaginal_examinations_during_labor
                  }
                  exclusive
                  onChange={handleAddFormChange}
                  className="Toggle_input"
                >
                  <ToggleButton
                    name="more_than_3_vaginal_examinations_during_labor"
                    value="YES"
                    size="small"
                    className="dataentry_input"
                  >
                    Yes
                  </ToggleButton>
                  <ToggleButton
                    name="more_than_3_vaginal_examinations_during_labor"
                    value="NO"
                    size="small"
                    className="dataentry_input"
                  >
                    No
                  </ToggleButton>
                  <ToggleButton
                    name="more_than_3_vaginal_examinations_during_labor"
                    value="NA"
                    size="small"
                    className="dataentry_input"
                  >
                    NA
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="LeakingPV"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  Leaking PV
                  {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                </label>
                <ToggleButtonGroup
                  color="primary"
                  id="leakingPV"
                  value={addMotherData?.leaking_pv}
                  exclusive
                  onChange={handleAddFormChange}
                  className="Toggle_input"
                  //required
                >
                  <ToggleButton
                    name="leaking_pv"
                    value="YES"
                    size="small"
                    className="dataentry_input"
                  >
                    Yes
                  </ToggleButton>
                  <ToggleButton
                    name="leaking_pv"
                    value="NO"
                    size="small"
                    className="dataentry_input"
                  >
                    No
                  </ToggleButton>
                  <ToggleButton
                    name="leaking_pv"
                    value="NA"
                    size="small"
                    className="dataentry_input"
                  >
                    NA
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="PIH"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  Pregnancy-induced hypertension (PIH)
                </label>
                <ToggleButtonGroup
                  color="primary"
                  value={addMotherData?.pih}
                  exclusive
                  onChange={handleAddFormChange}
                  className="Toggle_input"
                >
                  <ToggleButton
                    name="pih"
                    value="YES"
                    size="small"
                    className="dataentry_input"
                  >
                    Yes
                  </ToggleButton>
                  <ToggleButton
                    name="pih"
                    value="NO"
                    size="small"
                    className="dataentry_input"
                  >
                    No
                  </ToggleButton>
                  <ToggleButton
                    name="pih"
                    value="NA"
                    size="small"
                    className="dataentry_input"
                  >
                    NA
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="Rupture of Membranes"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  Rupture Of Membranes (ROM)
                  {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                </label>
                <ToggleButtonGroup
                  color="primary"
                  value={addMotherData?.rupture_of_membranes_rom}
                  exclusive
                  onChange={handleAddFormChange}
                  className="Toggle_input"
                  //required
                >
                  <ToggleButton
                    name="rupture_of_membranes_rom"
                    value="YES"
                    size="small"
                    className="dataentry_input"
                  >
                    Yes
                  </ToggleButton>
                  <ToggleButton
                    name="rupture_of_membranes_rom"
                    value="NO"
                    size="small"
                    className="dataentry_input"
                  >
                    No
                  </ToggleButton>
                  <ToggleButton
                    name="rupture_of_membranes_rom"
                    value="NA"
                    size="small"
                    className="dataentry_input"
                  >
                    NA
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="ROM Type"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  Rupture Of Membranes (Type)
                  {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                </label>
                <ToggleButtonGroup
                  color="primary"
                  value={addMotherData?.rupture_of_membranes_rom_one}
                  exclusive
                  onChange={handleAddFormChange}
                  className="Toggle_input"
                  //required
                >
                  <ToggleButton
                    name="rupture_of_membranes_rom_one"
                    value="PROM"
                    size="small"
                    className="dataentry_input"
                  >
                    Prom
                  </ToggleButton>
                  <ToggleButton
                    name="rupture_of_membranes_rom_one"
                    value="SROM"
                    size="small"
                    className="dataentry_input"
                  >
                    Srom
                  </ToggleButton>
                  <ToggleButton
                    name="rupture_of_membranes_rom_one"
                    value="ARM"
                    size="small"
                    className="dataentry_input"
                  >
                    Arm
                  </ToggleButton>
                  <ToggleButton
                    name="rupture_of_membranes_rom_one"
                    value="NA"
                    size="small"
                    className="dataentry_input"
                  >
                    NA
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="ROM If PROM"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  Rupture Of Membranes(IF PROM)
                </label>
                <ToggleButtonGroup
                  color="primary"
                  value={addMotherData?.rupture_of_membranes_rom_two}
                  name="rupture_of_membranes_rom_two"
                  id="diagnosis"
                  exclusive
                  onChange={handleAddFormChange}
                  className="Toggle_input"
                >
                  <ToggleButton
                    name="rupture_of_membranes_rom_two"
                    value="<18 hours"
                    size="small"
                    className="dataentry_input"
                  >
                    {"<"} 18 hours
                  </ToggleButton>
                  <ToggleButton
                    name="rupture_of_membranes_rom_two"
                    value=">18 hours"
                    size="small"
                    className="dataentry_input"
                  >
                    {">"} 18 hours
                  </ToggleButton>
                  <ToggleButton
                    name="rupture_of_membranes_rom_two"
                    value="NA"
                    size="small"
                    className="dataentry_input"
                  >
                    NA
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="Diagnosis (EOS/LOS/NA)"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  Smelly Amniotic Fluid
                </label>
                <ToggleButtonGroup
                  color="primary"
                  value={addMotherData?.smelly_amniotic_fluid}
                  exclusive
                  onChange={handleAddFormChange}
                  className="Toggle_input"
                >
                  <ToggleButton
                    name="smelly_amniotic_fluid"
                    value="YES"
                    size="small"
                    className="dataentry_input"
                  >
                    Yes
                  </ToggleButton>
                  <ToggleButton
                    name="smelly_amniotic_fluid"
                    value="NO"
                    size="small"
                    className="dataentry_input"
                  >
                    No
                  </ToggleButton>
                  <ToggleButton
                    name="smelly_amniotic_fluid"
                    value="NA"
                    size="small"
                    className="dataentry_input"
                  >
                    NA
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="Diagnosis Chorioamnionitis"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  Chorioamnionitis
                </label>
                <ToggleButtonGroup
                  color="primary"
                  value={addMotherData?.chorioamnionitis}
                  exclusive
                  onChange={handleAddFormChange}
                  className="Toggle_input"
                  //required
                >
                  <ToggleButton
                    name="chorioamnionitis"
                    value="YES"
                    size="small"
                    className="dataentry_input"
                  >
                    Yes
                  </ToggleButton>
                  <ToggleButton
                    name="chorioamnionitis"
                    value="NO"
                    size="small"
                    className="dataentry_input"
                  >
                    No
                  </ToggleButton>
                  <ToggleButton
                    name="chorioamnionitis"
                    value="NA"
                    size="small"
                    className="dataentry_input"
                  >
                    NA
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="GBS Infection"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  GBS Infection
                </label>
                <ToggleButtonGroup
                  color="primary"
                  value={addMotherData?.gbs_infection}
                  id="diagnosis"
                  exclusive
                  onChange={handleAddFormChange}
                  className="Toggle_input"
                >
                  <ToggleButton
                    name="gbs_infection"
                    value="YES"
                    size="small"
                    className="dataentry_input"
                  >
                    Yes
                  </ToggleButton>
                  <ToggleButton
                    name="gbs_infection"
                    value="NO"
                    size="small"
                    className="dataentry_input"
                  >
                    No
                  </ToggleButton>
                  <ToggleButton
                    name="gbs_infection"
                    value="NA"
                    size="small"
                    className="dataentry_input"
                  >
                    NA
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="Urinary Tract Infection"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  Urinary Tract Infection
                </label>
                <ToggleButtonGroup
                  color="primary"
                  value={addMotherData?.colonisation_or_urinary_tract_infection}
                  exclusive
                  onChange={handleAddFormChange}
                  className="Toggle_input"
                >
                  <ToggleButton
                    name="colonisation_or_urinary_tract_infection"
                    value="YES"
                    size="small"
                    className="dataentry_input"
                  >
                    Yes
                  </ToggleButton>
                  <ToggleButton
                    name="colonisation_or_urinary_tract_infection"
                    value="NO"
                    size="small"
                    className="dataentry_input"
                  >
                    No
                  </ToggleButton>
                  <ToggleButton
                    name="colonisation_or_urinary_tract_infection"
                    value="NA"
                    size="small"
                    className="dataentry_input"
                  >
                    NA
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="Torch Infection"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  Torch Infection
                </label>
                <ToggleButtonGroup
                  color="primary"
                  value={addMotherData?.torch_infections}
                  exclusive
                  onChange={handleAddFormChange}
                  className="Toggle_input"
                  //required
                >
                  <ToggleButton
                    name="torch_infections"
                    value="YES"
                    size="small"
                    className="dataentry_input"
                  >
                    Yes
                  </ToggleButton>
                  <ToggleButton
                    name="torch_infections"
                    value="NO"
                    size="small"
                    className="dataentry_input"
                  >
                    No
                  </ToggleButton>
                  <ToggleButton
                    name="torch_infections"
                    value="NA"
                    size="small"
                    className="dataentry_input"
                  >
                    NA
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="Type of Delivery"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  Type Of Delivery
                </label>
                <ToggleButtonGroup
                  color="primary"
                  value={addMotherData?.type_of_delivery}
                  id="diagnosis"
                  exclusive
                  onChange={handleAddFormChange}
                  className="Toggle_input"
                >
                  <ToggleButton
                    name="type_of_delivery"
                    value="Cesarean"
                    size="small"
                    className="dataentry_input"
                  >
                    Cesarean
                  </ToggleButton>
                  <ToggleButton
                    name="type_of_delivery"
                    value="Normal"
                    size="small"
                    className="dataentry_input"
                  >
                    Normal
                  </ToggleButton>
                  <ToggleButton
                    name="type_of_delivery"
                    value="NA"
                    size="small"
                    className="dataentry_input"
                  >
                    NA
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="Delayed Cord Clamping"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  Delayed Cord Clamping
                  {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                </label>
                <ToggleButtonGroup
                  color="primary"
                  value={addMotherData?.delayed_cord_clamping}
                  exclusive
                  onChange={handleAddFormChange}
                  className="Toggle_input"
                  //required
                >
                  <ToggleButton
                    name="delayed_cord_clamping"
                    value="YES"
                    size="small"
                    className="dataentry_input"
                  >
                    Yes
                  </ToggleButton>
                  <ToggleButton
                    name="delayed_cord_clamping"
                    value="NO"
                    size="small"
                    className="dataentry_input"
                  >
                    No
                  </ToggleButton>
                  <ToggleButton
                    name="delayed_cord_clamping"
                    value="NA"
                    size="small"
                    className="dataentry_input"
                  >
                    NA
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="Vaginal Swab Culture (Done)"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  Vaginal Swab Culture (Done)
                </label>
                <ToggleButtonGroup
                  color="primary"
                  value={addMotherData?.vaginal_swab_culture}
                  exclusive
                  onChange={handleAddFormChange}
                  className="Toggle_input"
                >
                  <ToggleButton
                    name="vaginal_swab_culture"
                    value="YES"
                    size="small"
                    className="dataentry_input"
                  >
                    Yes
                  </ToggleButton>
                  <ToggleButton
                    name="vaginal_swab_culture"
                    value="NO"
                    size="small"
                    className="dataentry_input"
                  >
                    No
                  </ToggleButton>
                  <ToggleButton
                    name="vaginal_swab_culture"
                    value="NA"
                    size="small"
                    className="dataentry_input"
                  >
                    NA
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="Vaginal Swab Culture (Result)"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  Vaginal Swab Culture (Result)
                </label>
                <ToggleButtonGroup
                  color="primary"
                  value={addMotherData?.vaginal_swab_culture_two}
                  id="diagnosis"
                  exclusive
                  onChange={handleAddFormChange}
                  className="Toggle_input"
                >
                  <ToggleButton
                    name="vaginal_swab_culture_two"
                    value="POSITIVE"
                    size="small"
                    className="dataentry_input"
                  >
                    Positive
                  </ToggleButton>
                  <ToggleButton
                    name="vaginal_swab_culture_two"
                    value="NEGATIVE"
                    size="small"
                    className="dataentry_input"
                  >
                    Negative
                  </ToggleButton>
                  <ToggleButton
                    name="vaginal_swab_culture_two"
                    value="NA"
                    size="small"
                    className="dataentry_input"
                  >
                    NA
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="Vaginal Swab Culture"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  Vaginal Swab Culture (pH Value)
                </label>
                <TextField
                  id="VaginalSwabCultureThree"
                  name="vaginal_swab_culture_three"
                  variant="outlined"
                  size="small"
                  className={
                    "dataentry_input " +
                    (errorFields.vaginal_swab_culture_three ? "errorField" : "")
                  }
                  //disabled={!defaultCheck.VaginalSwabCultureChecked}
                  value={addMotherData?.vaginal_swab_culture_three}
                  onChange={(e) => {
                    handleAddFormChange(e);
                    verifyVaginalSwabCultureThree(e);
                  }}
                  error={
                    errorFields.vaginal_swab_culture_three &&
                    showVSC3ErrorMessage
                  }
                  errorText={showVSC3ErrorMessage}
                  helperText={
                    errorFields.vaginal_swab_culture_three &&
                    showVSC3ErrorMessage
                      ? showVSC3ErrorMessage
                      : ""
                  }
                  onKeyDown={(event) => {
                    if (
                      !Numerics1.test(event.key) &&
                      event.key != "Backspace"
                    ) {
                      event.preventDefault();
                      setShowVSC3ErrorMessage("Only Numerics are Acceptable");
                      setErrorFields(
                        Object.assign({}, errorFields, {
                          vaginal_swab_culture_three: true,
                        })
                      );
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="Amniotic Fluid Culture (Done)"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  Amniotic Fluid Culture (Done)
                </label>
                <ToggleButtonGroup
                  color="primary"
                  name="amniotic_fluid_culture"
                  value={addMotherData?.amniotic_fluid_culture}
                  exclusive
                  onChange={handleAddFormChange}
                  className="Toggle_input"
                  //required
                >
                  <ToggleButton
                    name="amniotic_fluid_culture"
                    value="YES"
                    size="small"
                    className="dataentry_input"
                  >
                    Yes
                  </ToggleButton>
                  <ToggleButton
                    name="amniotic_fluid_culture"
                    value="NO"
                    size="small"
                    className="dataentry_input"
                  >
                    No
                  </ToggleButton>
                  <ToggleButton
                    name="amniotic_fluid_culture"
                    value="NA"
                    size="small"
                    className="dataentry_input"
                  >
                    NA
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor="Amniotic Fluid Culture (Result)"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  Amniotic Fluid Culture (Result)
                </label>
                <ToggleButtonGroup
                  color="primary"
                  value={addMotherData?.amniotic_fluid_culture_two}
                  id="amniotic_fluid_culture_two"
                  name="amniotic_fluid_culture_two"
                  exclusive
                  onChange={handleAddFormChange}
                  className="Toggle_input"
                >
                  <ToggleButton
                    name="amniotic_fluid_culture_two"
                    value="POSITIVE"
                    className="dataentry_input"
                    size="small"
                  >
                    Positive
                  </ToggleButton>
                  <ToggleButton
                    name="amniotic_fluid_culture_two"
                    value="NEGATIVE"
                    className="dataentry_input"
                    size="small"
                  >
                    Negative
                  </ToggleButton>
                  <ToggleButton
                    name="amniotic_fluid_culture_two"
                    value="NA"
                    className="dataentry_input"
                    size="small"
                  >
                    NA
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={3}>
                <label
                  htmlFor=" AF Culture (If Positive)"
                  style={{ display: "block" }}
                  className="dataentry_label"
                >
                  AF Index Culture (if Positive)
                </label>
                <TextField
                  id="AmnioticFluidCultureThree"
                  name="amniotic_fluid_culture_three"
                  variant="outlined"
                  size="small"
                  className={
                    "dataentry_input " +
                    (errorFields.amniotic_fluid_culture_three
                      ? "errorField"
                      : "")
                  }
                  //disabled={!defaultCheck.AFCultureIfPositiveChecked}
                  value={addMotherData?.amniotic_fluid_culture_three}
                  onChange={(e) => {
                    handleAddFormChange(e);
                    verifyAmnioticFluidCultureThree(e);
                  }}
                  error={
                    errorFields.amniotic_fluid_culture_three &&
                    showAFC3ErrorMessage
                  }
                  errorText={showAFC3ErrorMessage}
                  helperText={
                    errorFields.amniotic_fluid_culture_three &&
                    showAFC3ErrorMessage
                      ? showAFC3ErrorMessage
                      : ""
                  }
                  /* onKeyDown={(event) => {
                    if (!Numerics.test(event.key) && event.key != "Backspace") {
                      event.preventDefault();
                      setShowAFC3ErrorMessage("Only Numerics are Acceptable");
                      setErrorFields(
                        Object.assign({}, errorFields, {
                          amniotic_fluid_culture_three: true,
                        })
                      );
                    }
                  }} */
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" className="InputAdornment">
                        cms
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default MotherProfile;
