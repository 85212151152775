const updateValue = (value) => {
  switch (value) {
    case "YES":
      return "Yes";
    case "NO":
      return "No";
    case "NA":
      return "NA";
    default:
      return value;
  }
};
export const generateScore = (patient) => {
  const genScoreData = new FormData();
  const YES = "1";
  const NO = "0";
if(patient.babyGITData?.abdominal_dystension){
  if (patient.babyGITData?.abdominal_dystension == "YES") {
    genScoreData.append("Abdominal Dystension_Yes", YES);
    genScoreData.append("Abdominal Dystension_No", NO);
  }
  if (patient.babyGITData?.abdominal_dystension == "NO") {
    genScoreData.append("Abdominal Dystension_Yes", NO);
    genScoreData.append("Abdominal Dystension_No", YES);
  }
  if (patient.babyGITData?.abdominal_dystension == "NA") {
    genScoreData.append("Abdominal Dystension_Yes", NO);
    genScoreData.append("Abdominal Dystension_No", NO);
  }
}
else{
    genScoreData.append("Abdominal Dystension_Yes", NO);
    genScoreData.append("Abdominal Dystension_No", NO);
}
  if(patient.babyInvData?.absolute_neutrophil_count == "NA"){
    genScoreData.append(
      "Absolute Neutrophil Count_value","8.60"
    );
  }
  else{
    if( patient.babyInvData?.absolute_neutrophil_count_unit == "mL")
      {
        const abnc_Value = "1000"* patient.babyInvData?.absolute_neutrophil_count;
        genScoreData.append(
          "Absolute Neutrophil Count_value",
          abnc_Value
            ? abnc_Value
            : "8.60"
        );
      }
      else{
  genScoreData.append(
    "Absolute Neutrophil Count_value",
    patient.babyInvData?.absolute_neutrophil_count
      ? patient.babyInvData?.absolute_neutrophil_count
      : "8.60"
  );
}
  }

if(patient.babyCNSData?.af_bulge){ 
  if (patient.babyCNSData?.af_bulge == "YES") {
    genScoreData.append("Af Bulge_Yes", YES);
    genScoreData.append("Af Bulge_No", NO);
  }
  if (patient.babyCNSData?.af_bulge == "NO") {
    genScoreData.append("Af Bulge_Yes", NO);
    genScoreData.append("Af Bulge_No", YES);
  }
  if (patient.babyCNSData?.af_bulge == "NA") {
    genScoreData.append("Af Bulge_Yes", NO);
    genScoreData.append("Af Bulge_No", NO);
  }
}
else{
  genScoreData.append("Af Bulge_Yes", NO);
    genScoreData.append("Af Bulge_No", NO);
}
if(patient.babyappearance?.baby_appearance){
  if (patient.babyappearance?.baby_appearance == "Dull") {
    genScoreData.append("Baby Appearance_value_Dull", YES);
    genScoreData.append("Baby Appearance_value_Lethargic",NO);
    genScoreData.append("Baby Appearance_value_Normal", NO);
  }
  if (patient.babyappearance?.baby_appearance == "Lethargic") {
    genScoreData.append("Baby Appearance_value_Dull", NO);
    genScoreData.append("Baby Appearance_value_Lethargic", YES);
    genScoreData.append("Baby Appearance_value_Normal", NO);
  }
  if (patient.babyappearance?.baby_appearance == "Normal") {
    genScoreData.append("Baby Appearance_value_Dull", NO);
    genScoreData.append("Baby Appearance_value_Lethargic", NO);
    genScoreData.append("Baby Appearance_value_Normal", YES);
  }
  if (patient.babyappearance?.baby_appearance == "NA") {
    genScoreData.append("Baby Appearance_value_Dull", NO);
    genScoreData.append("Baby Appearance_value_Lethargic", NO);
    genScoreData.append("Baby Appearance_value_Normal", NO);
  }
}
else{
    genScoreData.append("Baby Appearance_value_Dull", NO);
    genScoreData.append("Baby Appearance_value_Lethargic", NO);
    genScoreData.append("Baby Appearance_value_Normal", NO);
}

  if(patient.babyCVData?.baby_blood_pressure_lower_limb == "NA"){
    genScoreData.append(
      "Baby Blood Pressure Lower Limb_value","16.67"
    );
  }
  else{
  genScoreData.append(
    "Baby Blood Pressure Lower Limb_value",
    patient.babyCVData?.baby_blood_pressure_lower_limb
      ? patient.babyCVData?.baby_blood_pressure_lower_limb
      : "16.67"
  );
}
  if(patient.babyCVData?.baby_blood_pressure_upper_limb == "NA"){
    genScoreData.append(
      "Baby Blood Pressure Upper Limb_value","23.10"
    );
  }
  else{
  genScoreData.append(
    "Baby Blood Pressure Upper Limb_value",
    patient.babyCVData?.baby_blood_pressure_upper_limb
      ? patient.babyCVData?.baby_blood_pressure_upper_limb
      : "23.10"
  );
}
  if(patient.babyCVData?.baby_blood_pressure_mean_arterial_bp == "NA"){
    genScoreData.append(
      "Baby Blood Pressure Mean Arterial Bp_value","17.33"
    );
  }
  else{
  genScoreData.append(
    "Baby Blood Pressure Mean Arterial Bp_value",
    patient.babyCVData?.baby_blood_pressure_mean_arterial_bp
      ? patient.babyCVData?.baby_blood_pressure_mean_arterial_bp
      : "17.33"
  );
  }
  if  (patient.babyappearance?.baby_cry_sound){
  if (patient.babyappearance?.baby_cry_sound == "High Pitch") {
    genScoreData.append("Baby Cry Sound_value_High Pitch", YES);
    genScoreData.append("Baby Cry Sound_value_Low Pitch", NO);
    genScoreData.append("Baby Cry Sound_value_Normal", NO);
    genScoreData.append("Baby Cry Sound_value_Not Cried", NO);
  }
  if (patient.babyappearance?.baby_cry_sound == "Low Pitch") {
    genScoreData.append("Baby Cry Sound_value_High Pitch", NO);
    genScoreData.append("Baby Cry Sound_value_Low Pitch", YES);
    genScoreData.append("Baby Cry Sound_value_Normal", NO);
    genScoreData.append("Baby Cry Sound_value_Not Cried", NO);
  }
  if (patient.babyappearance?.baby_cry_sound == "Normal") {
    genScoreData.append("Baby Cry Sound_value_High Pitch", NO);
    genScoreData.append("Baby Cry Sound_value_Low Pitch", NO);
    genScoreData.append("Baby Cry Sound_value_Normal", YES);
    genScoreData.append("Baby Cry Sound_value_Not Cried", NO);
  }
  if (patient.babyappearance?.baby_cry_sound == "Not Cried") {
    genScoreData.append("Baby Cry Sound_value_High Pitch", NO);
    genScoreData.append("Baby Cry Sound_value_Low Pitch", NO);
    genScoreData.append("Baby Cry Sound_value_Normal", NO);
    genScoreData.append("Baby Cry Sound_value_Not Cried", YES);
  }
  if (patient.babyappearance?.baby_cry_sound == "NA") {
    genScoreData.append("Baby Cry Sound_value_High Pitch", NO);
    genScoreData.append("Baby Cry Sound_value_Low Pitch", NO);
    genScoreData.append("Baby Cry Sound_value_Normal", NO);
    genScoreData.append("Baby Cry Sound_value_Not Cried", NO);
  }
  }
  else{
    genScoreData.append("Baby Cry Sound_value_High Pitch", NO);
    genScoreData.append("Baby Cry Sound_value_Low Pitch", NO);
    genScoreData.append("Baby Cry Sound_value_Normal", NO);
    genScoreData.append("Baby Cry Sound_value_Not Cried", NO);
  }
  if(patient.babyappearance?.baby_feeding_status){
  if (patient.babyappearance?.baby_feeding_status == "Poor") {
    genScoreData.append("Baby Feeding Status_value_Poor", YES);
    genScoreData.append("Baby Feeding Status_value_No Feeding", NO);
    genScoreData.append("Baby Feeding Status_value_Normal", NO);
  }
  if (patient.babyappearance?.baby_feeding_status == "Normal") {
    genScoreData.append("Baby Feeding Status_value_Normal", YES);
    genScoreData.append("Baby Feeding Status_value_No Feeding", NO);
    genScoreData.append("Baby Feeding Status_value_Poor", NO);
  }
  if (patient.babyappearance?.baby_feeding_status == "No Feeding") {
    genScoreData.append("Baby Feeding Status_value_No Feeding", YES);
    genScoreData.append("Baby Feeding Status_value_Normal", NO);
    genScoreData.append("Baby Feeding Status_value_Poor", NO);
  }
  if (patient.babyappearance?.baby_feeding_status == "NA") {
    genScoreData.append("Baby Feeding Status_value_No Feeding", NO);
    genScoreData.append("Baby Feeding Status_value_Normal", NO);
    genScoreData.append("Baby Feeding Status_value_Poor", NO);
  }
}
else{
    genScoreData.append("Baby Feeding Status_value_No Feeding", NO);
    genScoreData.append("Baby Feeding Status_value_Normal", NO);
    genScoreData.append("Baby Feeding Status_value_Poor", NO);  
}
  if(patient.getBabyProfile?.baby_gestational_age == "NA"){
    genScoreData.append(
      "Baby Gestational Age","36.00"
    );
  }
  else{
  genScoreData.append(
    "Baby Gestational Age",
    patient.getBabyProfile?.baby_gestational_age
      ? patient.getBabyProfile?.baby_gestational_age
      : "36.00"
  );
}
  if(patient.babyInvData?.baby_haemoglobin_levels == "NA"){
    genScoreData.append("Baby Haemoglobin Levels_value","5.62");
  }
  else{ 
  if( patient.babyInvData?.baby_haemoglobin_levels_unit == "g/L")
    {
      const BHL_Value = patient.babyInvData?.baby_haemoglobin_levels * "0.10";
      genScoreData.append(
        "Baby Haemoglobin Levels_value",
        BHL_Value
          ? BHL_Value
          : "5.62"
      );
    }
    else{
  genScoreData.append(
    "Baby Haemoglobin Levels_value",
    patient.babyInvData?.baby_haemoglobin_levels
      ? patient.babyInvData?.baby_haemoglobin_levels
      : "5.62"
  );
  }
}
if(patient.babyappearance?.baby_jaundice){
  if (patient.babyappearance?.baby_jaundice == "YES") {
    genScoreData.append("Baby Jaundice_Yes", YES);
    genScoreData.append("Baby Jaundice_No", NO);
  }
  if (patient.babyappearance?.baby_jaundice == "NO") {
    genScoreData.append("Baby Jaundice_Yes", NO);
    genScoreData.append("Baby Jaundice_No", YES);
  }
  if (patient.babyappearance?.baby_jaundice == "NA") {
    genScoreData.append("Baby Jaundice_Yes", NO);
    genScoreData.append("Baby Jaundice_No", NO);
  }
}
else{
  genScoreData.append("Baby Jaundice_Yes", NO);
  genScoreData.append("Baby Jaundice_No", NO);
}

if(patient.babyCNSData?.baby_movement){
  if (patient.babyCNSData?.baby_movement == "YES") {
    genScoreData.append("Baby Movement_Yes", YES);
    genScoreData.append("Baby Movement_No", NO);
  }
  if (patient.babyCNSData?.baby_movement == "NO") {
    genScoreData.append("Baby Movement_Yes", NO);
    genScoreData.append("Baby Movement_No", YES);
  }

  if (patient.babyCNSData?.baby_movement == "NA") {
    genScoreData.append("Baby Movement_Yes", NO);
    genScoreData.append("Baby Movement_No", NO);
  }
}
else{
  genScoreData.append("Baby Movement_Yes", NO);
  genScoreData.append("Baby Movement_No", NO);
}
  if(patient.babyInvData?.baby_c_reactive_protien_levels == "NA"){
    genScoreData.append(
      "Baby Reactive Protien Levels_value","0.86"
    );
  }
  else{
    
  if( patient.babyInvData?.baby_c_reactive_protien_levels_unit == "mg/dL")
    {
      const CRPL_Value = patient.babyInvData?.baby_c_reactive_protien_levels * "10";
      genScoreData.append(
        "Baby Reactive Protien Levels_value",
        CRPL_Value
          ? CRPL_Value
          : "0.86"
      );
    }
    else{
    genScoreData.append(
      "Baby Reactive Protien Levels_value",
      patient.babyInvData?.baby_c_reactive_protien_levels
        ? patient.babyInvData?.baby_c_reactive_protien_levels
        : "0.86"
    );
  }
  }
  if(patient.babyappearance?.baby_skin_colour){
  if (patient.babyappearance?.baby_skin_colour == "Central Cyanosis") {
    genScoreData.append("Baby Skin Colour_value_Acrocyanosis", NO);
    genScoreData.append("Baby Skin Colour_value_Central Cyanosis", YES);
    genScoreData.append("Baby Skin Colour_value_Pale", NO);
    genScoreData.append("Baby Skin Colour_value_Peripheral Duskiness", NO);
    genScoreData.append("Baby Skin Colour_value_Pink", NO);
  }
  if (patient.babyappearance?.baby_skin_colour == "Peripheral Duskiness") {
    genScoreData.append("Baby Skin Colour_value_Acrocyanosis", NO);
    genScoreData.append("Baby Skin Colour_value_Central Cyanosis", NO);
    genScoreData.append("Baby Skin Colour_value_Pale", NO);
    genScoreData.append("Baby Skin Colour_value_Peripheral Duskiness", YES);
    genScoreData.append("Baby Skin Colour_value_Pink", NO);
  }
  if (patient.babyappearance?.baby_skin_colour == "Acrocyanosis") {
    genScoreData.append("Baby Skin Colour_value_Acrocyanosis", YES);
    genScoreData.append("Baby Skin Colour_value_Central Cyanosis", NO);
    genScoreData.append("Baby Skin Colour_value_Pale", NO);
    genScoreData.append("Baby Skin Colour_value_Peripheral Duskiness", NO);
    genScoreData.append("Baby Skin Colour_value_Pink", NO);
  }
  if (patient.babyappearance?.baby_skin_colour == "Pink") {
    genScoreData.append("Baby Skin Colour_value_Acrocyanosis", NO);
    genScoreData.append("Baby Skin Colour_value_Central Cyanosis", NO);
    genScoreData.append("Baby Skin Colour_value_Pale", NO);
    genScoreData.append("Baby Skin Colour_value_Peripheral Duskiness", NO);
    genScoreData.append("Baby Skin Colour_value_Pink", YES);
  }
  if (patient.babyappearance?.baby_skin_colour == "Pale") {
    genScoreData.append("Baby Skin Colour_value_Acrocyanosis", NO);
    genScoreData.append("Baby Skin Colour_value_Central Cyanosis", NO);
    genScoreData.append("Baby Skin Colour_value_Pale", YES);
    genScoreData.append("Baby Skin Colour_value_Peripheral Duskiness", NO);
    genScoreData.append("Baby Skin Colour_value_Pink", NO);
  }
  if (patient.babyappearance?.baby_skin_colour == "NA") {
    genScoreData.append("Baby Skin Colour_value_Acrocyanosis", NO);
    genScoreData.append("Baby Skin Colour_value_Central Cyanosis", NO);
    genScoreData.append("Baby Skin Colour_value_Pale", NO);
    genScoreData.append("Baby Skin Colour_value_Peripheral Duskiness", NO);
    genScoreData.append("Baby Skin Colour_value_Pink", NO);
  }
}
else{
  genScoreData.append("Baby Skin Colour_value_Acrocyanosis", NO);
  genScoreData.append("Baby Skin Colour_value_Central Cyanosis", NO);
  genScoreData.append("Baby Skin Colour_value_Pale", NO);
  genScoreData.append("Baby Skin Colour_value_Peripheral Duskiness", NO);
  genScoreData.append("Baby Skin Colour_value_Pink", NO);
}
  
  if(patient.getBabyProfile?.baby_weight_at_birth == "NA"){
    genScoreData.append(
      "Baby Weight At Birth","2.30"
    );
  }
  else{
  genScoreData.append(
    "Baby Weight At Birth",
    patient.getBabyProfile?.baby_weight_at_birth
      ? patient.getBabyProfile?.baby_weight_at_birth
      : "2.30"
  );
}
  if(patient.babyInvData?.bilirubin_levels == "NA"){
    genScoreData.append(
      "Bilirubin Levels_value","0.96"
    );
  }
  else{
    
  if( patient.babyInvData?.bilirubin_unit == "mg/dL")
    {
      const BLR_Value = "0.0555"* patient.babyInvData?.bilirubin_levels;
      genScoreData.append(
        "Bilirubin Levels_value",
        BLR_Value
          ? BLR_Value
          : "0.96"
      );  
    }
    else{
  
  genScoreData.append(
    "Bilirubin Levels_value",
    patient.babyInvData?.bilirubin_levels
      ? patient.babyInvData?.bilirubin_levels
      : "0.96"
  );
}
  }
  if(patient.babyInvData?.calcium == "NA"){
    genScoreData.append(
      "Calcium_value","0.41"
    );
  }
  else{
    
  if( patient.babyInvData?.calcium_unit == "mg/dL")
    {
      const calcium_Value = "0.0555"* patient.babyInvData?.calcium;
      genScoreData.append(
        "Calcium_value",
        calcium_Value
          ? calcium_Value
          : "0.41"
      );
    }
    else{
  genScoreData.append(
    "Calcium_value",
    patient.babyInvData?.calcium ? patient.babyInvData?.calcium : "0.41"
  );
  }
}
  if(patient.babyInvData?.creatinine == "NA"){
    genScoreData.append(
      "Creatinine_value","0.48"
    );
  }
  else{
    
  if( patient.babyInvData?.creatinine_unit == "mg/dL")
    {
      const creatinine_Value = "0.0555"* patient.babyInvData?.creatinine;
      genScoreData.append(
        "Creatinine_value",
        creatinine_Value
          ? creatinine_Value
          : "0.48"
      );
    }
    else{
  genScoreData.append(
    "Creatinine_value",
    patient.babyInvData?.creatinine ? patient.babyInvData?.creatinine : "0.48"
  );
}
  }
  if(patient.babyInvData?.potassium == "NA"){
    genScoreData.append(
      "Potassium_value","1.90"
    );
  }
  else{
    
  
  if( patient.babyInvData?.potassium_unit == "mg/dL")
    {
      const potassium_Value = "0.0555"* patient.babyInvData?.potassium;
      genScoreData.append(
        "potassium_value",
        potassium_Value
          ? potassium_Value
          : "1.90"
      );
    }
    else{
  genScoreData.append(
    "Potassium_value",
    patient.babyInvData?.potassium ? patient.babyInvData?.potassium : "1.90"
  );
}
  }
  
  if(patient.babyInvData?.urea == "NA"){
    genScoreData.append(
      "Urea_value","7.62"
    );
  }
  else{
    
  if( patient.babyInvData?.urea_unit == "mg/dL")
    {
      const urea_Value = "0.0555"* patient.babyInvData?.urea;
      genScoreData.append(
        "Urea_value",
        urea_Value
          ? urea_Value
          : "7.62"
      );
    }
    else{
  genScoreData.append(
    "Urea_value",
    patient.babyInvData?.urea ? patient.babyInvData?.urea : "7.62"
  );
  }
  }
if(patient.babyappearance?.breast_feeding_initiation){
  if (patient.babyappearance?.breast_feeding_initiation == "YES") {
    genScoreData.append("Breast Feeding Initiation_Yes", YES);
    genScoreData.append("Breast Feeding Initiation_No", NO);
  }
  if (patient.babyappearance?.breast_feeding_initiation == "NO") {
    genScoreData.append("Breast Feeding Initiation_Yes", NO);
    genScoreData.append("Breast Feeding Initiation_No", YES);
  }
  if (patient.babyappearance?.breast_feeding_initiation == "NA") {
    genScoreData.append("Breast Feeding Initiation_Yes", NO);
    genScoreData.append("Breast Feeding Initiation_No", NO);
  }
}
else{
  genScoreData.append("Breast Feeding Initiation_Yes", NO);
  genScoreData.append("Breast Feeding Initiation_No", NO);
}
if(patient.babyCVData?.capillary_refill_unit){
  if (patient.babyCVData?.capillary_refill_unit == "> 3 Sec") {
    genScoreData.append("Capillary Refill Unit_> 3 Sec", YES);
    genScoreData.append("Capillary Refill Unit_< 3 Sec", NO);
  }
  if (patient.babyCVData?.capillary_refill_unit == "< 3 Sec") {
    genScoreData.append("Capillary Refill Unit_> 3 Sec", NO);
    genScoreData.append("Capillary Refill Unit_< 3 Sec", YES);
  }
  if (patient.babyCVData?.capillary_refill_unit == "= 3 Sec") {
    genScoreData.append("Capillary Refill Unit_> 3 Sec", YES);
    genScoreData.append("Capillary Refill Unit_< 3 Sec", NO);
  }
  if (patient.babyCVData?.capillary_refill_unit == "= 0 Sec") {
    genScoreData.append("Capillary Refill Unit_> 3 Sec", NO);
    genScoreData.append("Capillary Refill Unit_< 3 Sec", YES);
  }
  if (patient.babyCVData?.capillary_refill_unit == "NA") {
    genScoreData.append("Capillary Refill Unit_> 3 Sec", NO);
    genScoreData.append("Capillary Refill Unit_< 3 Sec", NO);
  }
}
else{
  genScoreData.append("Capillary Refill Unit_> 3 Sec", NO);
  genScoreData.append("Capillary Refill Unit_< 3 Sec", NO);
}
  if(patient.babyRespiratoryData?.breathing_rate == "NA"){
    genScoreData.append(
      "Breathing Rate_value","43.17"
    );
  }
  else{
  genScoreData.append(
    "Breathing Rate_value",
    patient.babyRespiratoryData?.breathing_rate
      ? patient.babyRespiratoryData?.breathing_rate
      : "43.17"
  );
}
  if(patient.babyInvData?.baby_blood_glucose == "NA"){
    genScoreData.append(
      "Baby Blood Glucose_value","18.80"
    );
  }
  else{
    
  if( patient.babyInvData?.baby_blood_glucose_unit == "mg/dL")
    {
      const BBGC_Value = "0.0555"* patient.babyInvData?.baby_blood_glucose;
      genScoreData.append(
        "Baby Blood Glucose_value",
        BBGC_Value
          ? BBGC_Value
          : "18.80"
      );
    }
    else{
  genScoreData.append(
    "Baby Blood Glucose_value",
    patient.babyInvData?.baby_blood_glucose
      ? patient.babyInvData?.baby_blood_glucose
      : "18.80"
  );
}
  }
if(patient.getMotherProfile?.delayed_cord_clamping){
  if (patient.getMotherProfile?.delayed_cord_clamping == "YES") {
    genScoreData.append("Delayed Cord Clamping_Yes", YES);
    genScoreData.append("Delayed Cord Clamping_No", NO);
  }
  if (patient.getMotherProfile?.delayed_cord_clamping == "NO") {
    genScoreData.append("Delayed Cord Clamping_Yes", NO);
    genScoreData.append("Delayed Cord Clamping_No", YES);
  }
  if (patient.getMotherProfile?.delayed_cord_clamping == "NA") {
    genScoreData.append("Delayed Cord Clamping_Yes", NO);
    genScoreData.append("Delayed Cord Clamping_No", NO);
  }
}
else{
  genScoreData.append("Delayed Cord Clamping_Yes", NO);
  genScoreData.append("Delayed Cord Clamping_No", NO);
}
if(patient.babyRespiratoryData?.fast_breathing){
if (patient.babyRespiratoryData?.fast_breathing == "YES") {
    genScoreData.append("Fast Breathing_Yes", YES);
    genScoreData.append("Fast Breathing_No", NO);
  }
  if (patient.babyRespiratoryData?.fast_breathing == "NO") {
    genScoreData.append("Fast Breathing_Yes", NO);
    genScoreData.append("Fast Breathing_No", YES);
  }
  if (patient.babyRespiratoryData?.fast_breathing == "NA") {
    genScoreData.append("Fast Breathing_Yes", NO);
    genScoreData.append("Fast Breathing_No", NO);
  }
}
else{
  genScoreData.append("Fast Breathing_Yes", NO);
  genScoreData.append("Fast Breathing_No", NO);
}
  if(patient.babyGITData?.frequency_of_stools == "NA"){
    genScoreData.append(
      "Frequency Of Stools_value","1.00"
    );
  }
  else{
  genScoreData.append(
    "Frequency Of Stools_value",
    patient.babyGITData?.frequency_of_stools
      ? patient.babyGITData?.frequency_of_stools
      : "1.00"
  );
}
if(patient.babyRespiratoryData?.groaning){
  if (patient.babyRespiratoryData?.groaning == "YES") {
    genScoreData.append("Groaning_Yes", YES);
    genScoreData.append("Groaning_No", NO);
  }
  if (patient.babyRespiratoryData?.groaning == "NO") {
    genScoreData.append("Groaning_Yes", NO);
    genScoreData.append("Groaning_No", YES);
  }
  if (patient.babyRespiratoryData?.groaning == "NA") {
    genScoreData.append("Groaning_Yes", NO);
    genScoreData.append("Groaning_No", NO);
  }
}
else{
  genScoreData.append("Groaning_Yes", NO);
  genScoreData.append("Groaning_No", NO);
}
if(patient.babyRespiratoryData?.grunting){
  if (patient.babyRespiratoryData?.grunting == "YES") {
    genScoreData.append("Grunting_Yes", YES);
    genScoreData.append("Grunting_No", NO);
  }
  if (patient.babyRespiratoryData?.grunting == "NO") {
    genScoreData.append("Grunting_Yes", NO);
    genScoreData.append("Grunting_No", YES);
  }
  if (patient.babyRespiratoryData?.grunting == "NA") {
    genScoreData.append("Grunting_Yes", NO);
    genScoreData.append("Grunting_No", NO);
  }
}
else{
  genScoreData.append("Grunting_Yes", NO);
  genScoreData.append("Grunting_No", NO);
}
  
  if(patient.babyCVData?.heart_rate == "NA"){
    genScoreData.append(
      "Heart Rate_value","140.00"
    );
  }
  else{
  genScoreData.append(
    "Heart Rate_value",
    patient.babyCVData?.heart_rate
     ? patient.babyCVData?.heart_rate : "140.00"
  );
}
if(patient.getMotherProfile?.leaking_pv){
  if (patient.getMotherProfile?.leaking_pv == "YES") {
    genScoreData.append("Leaking pv_Yes", YES);
    genScoreData.append("Leaking pv_No", NO);
  }
  if (patient.getMotherProfile?.leaking_pv == "NO") {
    genScoreData.append("Leaking pv_Yes", NO);
    genScoreData.append("Leaking pv_No", YES);
  }
  if (patient.getMotherProfile?.leaking_pv == "NA") {
    genScoreData.append("Leaking pv_Yes", NO);
    genScoreData.append("Leaking pv_No", NO);
  }
}
else{
  genScoreData.append("Leaking pv_Yes", NO);
  genScoreData.append("Leaking pv_No", NO);
}
if(patient.getMotherProfile?.maternal_diabetes){
  if (patient.getMotherProfile?.maternal_diabetes == "YES") {
    genScoreData.append("Maternal Diabetes_Yes", YES);
    genScoreData.append("Maternal Diabetes_No", NO);
  }
  if (patient.getMotherProfile?.maternal_diabetes == "NO") {
    genScoreData.append("Maternal Diabetes_Yes", NO);
    genScoreData.append("Maternal Diabetes_No", YES);
  }
  if (patient.getMotherProfile?.maternal_diabetes == "NA") {
    genScoreData.append("Maternal Diabetes_Yes", NO);
    genScoreData.append("Maternal Diabetes_No", NO);
  }
}
else{
  genScoreData.append("Maternal Diabetes_Yes", NO);
  genScoreData.append("Maternal Diabetes_No", NO);
}
  if(patient.babyRespiratoryData?.oxygen_saturation == "NA"){
    genScoreData.append(
      "Oxygen Saturation_value","95.00"
    );
  }
  else{
  genScoreData.append(
    "Oxygen Saturation_value",
    patient.babyRespiratoryData?.oxygen_saturation
      ? patient.babyRespiratoryData?.oxygen_saturation
      : "95.00"
  );
}
if(patient.getMotherProfile?.rupture_of_membranes_rom_one){
  if (patient.getMotherProfile?.rupture_of_membranes_rom_one == "PROM") {
    genScoreData.append("Rupture Of Membranes Rom One_PROM", YES);
    genScoreData.append("Rupture Of Membranes Rom One_SROM", NO);
    genScoreData.append("Rupture Of Membranes Rom One_ARM", NO);
  }
  if (patient.getMotherProfile?.rupture_of_membranes_rom_one == "SROM") {
    genScoreData.append("Rupture Of Membranes Rom One_PROM", NO);
    genScoreData.append("Rupture Of Membranes Rom One_SROM", YES);
    genScoreData.append("Rupture Of Membranes Rom One_ARM", NO);
  }
  if (patient.getMotherProfile?.rupture_of_membranes_rom_one == "ARM") {
    genScoreData.append("Rupture Of Membranes Rom One_PROM", NO);
    genScoreData.append("Rupture Of Membranes Rom One_SROM", NO);
    genScoreData.append("Rupture Of Membranes Rom One_ARM", YES);
  }
  if (patient.getMotherProfile?.rupture_of_membranes_rom_one == "NA") {
    genScoreData.append("Rupture Of Membranes Rom One_PROM", NO);
    genScoreData.append("Rupture Of Membranes Rom One_SROM", NO);
    genScoreData.append("Rupture Of Membranes Rom One_ARM", NO);
  }
}
else{
  genScoreData.append("Rupture Of Membranes Rom One_PROM", NO);
  genScoreData.append("Rupture Of Membranes Rom One_SROM", NO);
  genScoreData.append("Rupture Of Membranes Rom One_ARM", NO);
}
if(patient.getMotherProfile?.rupture_of_membranes_rom){
  if (patient.getMotherProfile?.rupture_of_membranes_rom == "YES") {
    genScoreData.append("Rupture Of Membranes Rom_Yes", YES);
    genScoreData.append("Rupture Of Membranes Rom_No", NO);
  }
  if (patient.getMotherProfile?.rupture_of_membranes_rom == "NO") {
    genScoreData.append("Rupture Of Membranes Rom_Yes", NO);
    genScoreData.append("Rupture Of Membranes Rom_No", YES);
  }
  if (patient.getMotherProfile?.rupture_of_membranes_rom == "NA") {
    genScoreData.append("Rupture Of Membranes Rom_Yes", NO);
    genScoreData.append("Rupture Of Membranes Rom_No", NO);
  }
}
else{
  genScoreData.append("Rupture Of Membranes Rom_Yes", NO);
  genScoreData.append("Rupture Of Membranes Rom_No", NO);
}
if(patient.babyCNSData?.seizures){
  if (patient.babyCNSData?.seizures == "YES") {
    genScoreData.append("Seizures_Yes", YES);
    genScoreData.append("Seizures_No", NO);
  }
  if (patient.babyCNSData?.seizures == "NO") {
    genScoreData.append("Seizures_Yes", NO);
    genScoreData.append("Seizures_No", YES);
  }
  if (patient.babyCNSData?.seizures == "NA") {
    genScoreData.append("Seizures_Yes", NO);
    genScoreData.append("Seizures_No", NO);
  }
}
else{
  genScoreData.append("Seizures_Yes", NO);
  genScoreData.append("Seizures_No", NO);
}
  
  if(patient.babyInvData?.thrombocytopenia == "NA"){
    genScoreData.append(
      "Thrombocytopeni_value","74787.50"
    );
  }
  else{
  genScoreData.append(
    "Thrombocytopeni_value",
    patient.babyInvData?.thrombocytopenia
      ? patient.babyInvData?.thrombocytopenia
      : "74787.50"
  );
}
  if(patient.babyInvData?.total_leucocute_count == "NA"){
    genScoreData.append(
      "Total Leucocute Count_value","5716.67"
    );
  }
  else{
  genScoreData.append(
    "Total Leucocute Count_value",
    patient.babyInvData?.total_leucocute_count
      ? patient.babyInvData?.total_leucocute_count
      : "5716.67"
  );
}
  return genScoreData;
};

export const NonInvasiveScoreData = (patient) => {
  const YES = "1";
  const NO = "0";
  const NonInvasiveScore = new FormData();
if(patient.babyHealthParameters?.abdominal_dystension){
  if (patient.babyHealthParameters?.abdominal_dystension == "YES") {
    NonInvasiveScore.append("Abdominal Dystension_Yes", YES);
    NonInvasiveScore.append("Abdominal Dystension_No", NO);
  }
  else if (patient.babyHealthParameters?.abdominal_dystension == "NO") {
    NonInvasiveScore.append("Abdominal Dystension_Yes", NO);
    NonInvasiveScore.append("Abdominal Dystension_No", YES);
  }
  else if (patient.babyHealthParameters?.abdominal_dystension == "NA") {
    NonInvasiveScore.append("Abdominal Dystension_Yes", NO);
    NonInvasiveScore.append("Abdominal Dystension_No", NO);
  }
}
  else {
    NonInvasiveScore.append("Abdominal Dystension_Yes", NO);
    NonInvasiveScore.append("Abdominal Dystension_No", NO);
  }
  if(patient.babyHealthParameters?.absolute_neutrophil_count == "NA"){
    NonInvasiveScore.append(
      "Absolute Neutrophil Count_value","8.60"
    );
  }
  else{
    if( patient.babyHealthParameters?.absolute_neutrophil_count_unit == "mL")
      {
        const abnc_Value = "1000"* patient.babyHealthParameters?.absolute_neutrophil_count;
        NonInvasiveScore.append(
          "Absolute Neutrophil Count_value",
          abnc_Value
            ? abnc_Value
            : "8.60"
        );
      }
      else{
    NonInvasiveScore.append(
      "Absolute Neutrophil Count_value",
      patient.babyHealthParameters?.absolute_neutrophil_count
        ? patient.babyHealthParameters?.absolute_neutrophil_count
        : "8.60"
    );
    }
}
if(patient.babyHealthParameters?.af_bulge){
  if (patient.babyHealthParameters?.af_bulge == "YES") {
    NonInvasiveScore.append("Af Bulge_Yes", YES);
    NonInvasiveScore.append("Af Bulge_No", NO);
  }
  else if (patient.babyHealthParameters?.af_bulge == "NO") {
    NonInvasiveScore.append("Af Bulge_Yes", NO);
    NonInvasiveScore.append("Af Bulge_No", YES);
  }
  else if (patient.babyHealthParameters?.af_bulge == "NA") {
    NonInvasiveScore.append("Af Bulge_Yes", NO);
    NonInvasiveScore.append("Af Bulge_No", YES);
  }
}
  else {
    NonInvasiveScore.append("Af Bulge_Yes", NO);
    NonInvasiveScore.append("Af Bulge_No", YES);
  }
  if (patient.babyHealthParameters?.baby_appearance){
  if (patient.babyHealthParameters?.baby_appearance == "Dull") {
    NonInvasiveScore.append("Baby Appearance_value_Dull", YES);
    NonInvasiveScore.append("Baby Appearance_value_Lethargic",NO);
    NonInvasiveScore.append("Baby Appearance_value_Normal", NO);
  }
  else if (patient.babyHealthParameters?.baby_appearance == "Lethargic") {
    NonInvasiveScore.append("Baby Appearance_value_Dull", NO);
    NonInvasiveScore.append("Baby Appearance_value_Lethargic", YES);
    NonInvasiveScore.append("Baby Appearance_value_Normal", NO);
  }
  else if (patient.babyHealthParameters?.baby_appearance == "Normal") {
    NonInvasiveScore.append("Baby Appearance_value_Dull", NO);
    NonInvasiveScore.append("Baby Appearance_value_Lethargic", NO);
    NonInvasiveScore.append("Baby Appearance_value_Normal", YES);
  }
  else if (patient.babyHealthParameters?.baby_appearance == "NA") {
    NonInvasiveScore.append("Baby Appearance_value_Dull", NO);
    NonInvasiveScore.append("Baby Appearance_value_Lethargic", NO);
    NonInvasiveScore.append("Baby Appearance_value_Normal", NO);
  }
}
  else {
    NonInvasiveScore.append("Baby Appearance_value_Dull", NO);
    NonInvasiveScore.append("Baby Appearance_value_Lethargic", NO);
    NonInvasiveScore.append("Baby Appearance_value_Normal", NO);
  }
  
  if(patient.babyHealthParameters?.baby_blood_pressure_lower_limb == "NA"){
    NonInvasiveScore.append(
      "Baby Blood Pressure Lower Limb_value","16.67"
    );
  }
  else{
  NonInvasiveScore.append(
    "Baby Blood Pressure Lower Limb_value",
    patient.babyHealthParameters?.baby_blood_pressure_lower_limb
      ? patient.babyHealthParameters?.baby_blood_pressure_lower_limb
      : "16.67"
  );
}

if(patient.babyHealthParameters?.baby_blood_pressure_upper_limb == "NA"){
  NonInvasiveScore.append(
    "Baby Blood Pressure Upper Limb_value","23.10"
  );
}
else{
  NonInvasiveScore.append(
    "Baby Blood Pressure Upper Limb_value",
    patient.babyHealthParameters?.baby_blood_pressure_upper_limb
      ? patient.babyHealthParameters?.baby_blood_pressure_upper_limb
      : "23.10"
  );
}


if(patient.babyHealthParameters?.baby_blood_pressure_mean_arterial_bp == "NA"){
  NonInvasiveScore.append(
    "Baby Blood Pressure Mean Arterial Bp_value","17.33"
  );
}
else{
  NonInvasiveScore.append(
    "Baby Blood Pressure Mean Arterial Bp_value",
    patient.babyHealthParameters?.baby_blood_pressure_mean_arterial_bp
      ? patient.babyHealthParameters?.baby_blood_pressure_mean_arterial_bp
      : "17.33"
  );
}
if(patient.babyHealthParameters?.baby_cry_sound){
  if (patient.babyHealthParameters?.baby_cry_sound == "High Pitch") {
    NonInvasiveScore.append("Baby Cry Sound_value_High Pitch", YES);
    NonInvasiveScore.append("Baby Cry Sound_value_Low Pitch", NO);
    NonInvasiveScore.append("Baby Cry Sound_value_Normal", NO);
    NonInvasiveScore.append("Baby Cry Sound_value_Not Cried", NO);
  }
  else if (patient.babyHealthParameters?.baby_cry_sound == "Low Pitch") {
    NonInvasiveScore.append("Baby Cry Sound_value_High Pitch", NO);
    NonInvasiveScore.append("Baby Cry Sound_value_Low Pitch", YES);
    NonInvasiveScore.append("Baby Cry Sound_value_Normal", NO);
    NonInvasiveScore.append("Baby Cry Sound_value_Not Cried", NO);
  }
  else if (patient.babyHealthParameters?.baby_cry_sound == "Normal") {
    NonInvasiveScore.append("Baby Cry Sound_value_High Pitch", NO);
    NonInvasiveScore.append("Baby Cry Sound_value_Low Pitch", NO);
    NonInvasiveScore.append("Baby Cry Sound_value_Normal", YES);
    NonInvasiveScore.append("Baby Cry Sound_value_Not Cried", NO);
  }
  else if (patient.babyHealthParameters?.baby_cry_sound == "Not Cried") {
    NonInvasiveScore.append("Baby Cry Sound_value_High Pitch", NO);
    NonInvasiveScore.append("Baby Cry Sound_value_Low Pitch", NO);
    NonInvasiveScore.append("Baby Cry Sound_value_Normal", NO);
    NonInvasiveScore.append("Baby Cry Sound_value_Not Cried", YES);
  }
  else if (patient.babyHealthParameters?.baby_cry_sound == "NA") {
    NonInvasiveScore.append("Baby Cry Sound_value_High Pitch", NO);
    NonInvasiveScore.append("Baby Cry Sound_value_Low Pitch", NO);
    NonInvasiveScore.append("Baby Cry Sound_value_Normal", NO);
    NonInvasiveScore.append("Baby Cry Sound_value_Not Cried", NO);
  }
  else if (patient.babyHealthParameters?.baby_cry_sound == "") {
    NonInvasiveScore.append("Baby Cry Sound_value_High Pitch", NO);
    NonInvasiveScore.append("Baby Cry Sound_value_Low Pitch", NO);
    NonInvasiveScore.append("Baby Cry Sound_value_Normal", NO);
    NonInvasiveScore.append("Baby Cry Sound_value_Not Cried", NO);
  }
}
else {
  NonInvasiveScore.append("Baby Cry Sound_value_High Pitch", NO);
  NonInvasiveScore.append("Baby Cry Sound_value_Low Pitch", NO);
  NonInvasiveScore.append("Baby Cry Sound_value_Normal", NO);
  NonInvasiveScore.append("Baby Cry Sound_value_Not Cried", NO);
}
if(patient.babyHealthParameters?.baby_feeding_status){
  if (patient.babyHealthParameters?.baby_feeding_status == "Poor") {
    NonInvasiveScore.append("Baby Feeding Status_value_Poor", YES);
    NonInvasiveScore.append("Baby Feeding Status_value_No Feeding", NO);
    NonInvasiveScore.append("Baby Feeding Status_value_Normal", NO);
  }
  else if (patient.babyHealthParameters?.baby_feeding_status == "Normal") {
    NonInvasiveScore.append("Baby Feeding Status_value_Normal", YES);
    NonInvasiveScore.append("Baby Feeding Status_value_No Feeding", NO);
    NonInvasiveScore.append("Baby Feeding Status_value_Poor", NO);
  }
  else if (patient.babyHealthParameters?.baby_feeding_status == "No Feeding") {
    NonInvasiveScore.append("Baby Feeding Status_value_No Feeding", YES);
    NonInvasiveScore.append("Baby Feeding Status_value_Normal", NO);
    NonInvasiveScore.append("Baby Feeding Status_value_Poor", NO);
  }
  else if (patient.babyHealthParameters?.baby_feeding_status == "NA") {
    NonInvasiveScore.append("Baby Feeding Status_value_No Feeding", NO);
    NonInvasiveScore.append("Baby Feeding Status_value_Normal", NO);
    NonInvasiveScore.append("Baby Feeding Status_value_Poor", NO);
  }
  else if (patient.babyHealthParameters?.baby_feeding_status == "") {
    NonInvasiveScore.append("Baby Feeding Status_value_No Feeding", NO);
    NonInvasiveScore.append("Baby Feeding Status_value_Normal", NO);
    NonInvasiveScore.append("Baby Feeding Status_value_Poor", NO);
  }
}
else{
    NonInvasiveScore.append("Baby Feeding Status_value_No Feeding", NO);
    NonInvasiveScore.append("Baby Feeding Status_value_Normal", NO);
    NonInvasiveScore.append("Baby Feeding Status_value_Poor", NO);
  
}
if(patient.getBabyProfile?.baby_gestational_age == "NA"){
  NonInvasiveScore.append(
    "Baby Gestational Age","36.00"
  );
}
else{
  NonInvasiveScore.append(
    "Baby Gestational Age",
    patient.getBabyProfile?.baby_gestational_age
      ? patient.getBabyProfile?.baby_gestational_age
      : "36.00"
  );
}
if (patient.babyHealthParameters?.baby_jaundice){
  if (patient.babyHealthParameters?.baby_jaundice == "YES") {
    NonInvasiveScore.append("Baby Jaundice_Yes", YES);
    NonInvasiveScore.append("Baby Jaundice_No", NO);
  }
  else if (patient.babyHealthParameters?.baby_jaundice == "NO") {
    NonInvasiveScore.append("Baby Jaundice_Yes", NO);
    NonInvasiveScore.append("Baby Jaundice_No", YES);
  }
  else if (patient.babyHealthParameters?.baby_jaundice == "NA") {
    NonInvasiveScore.append("Baby Jaundice_Yes", NO);
    NonInvasiveScore.append("Baby Jaundice_No", NO);
  }
  else if (patient.babyHealthParameters?.baby_jaundice == "") {
    NonInvasiveScore.append("Baby Jaundice_Yes", NO);
    NonInvasiveScore.append("Baby Jaundice_No", NO);
  }
}
  else  {
    NonInvasiveScore.append("Baby Jaundice_Yes", NO);
    NonInvasiveScore.append("Baby Jaundice_No", NO);
  }

  if(patient.babyHealthParameters?.baby_movement){
    if (patient.babyHealthParameters?.baby_movement == "YES") {
      NonInvasiveScore.append("Baby Movement_Yes", YES);
      NonInvasiveScore.append("Baby Movement_No", NO);
    }
    else if (patient.babyHealthParameters?.baby_movement == "NO") {
      NonInvasiveScore.append("Baby Movement_Yes", NO);
      NonInvasiveScore.append("Baby Movement_No", YES);
    }
    else if (patient.babyHealthParameters?.baby_movement == "NA") {
      NonInvasiveScore.append("Baby Movement_Yes", NO);
      NonInvasiveScore.append("Baby Movement_No", NO);
    }
    else if (patient.babyHealthParameters?.baby_movement == "") {
      NonInvasiveScore.append("Baby Movement_Yes", NO);
      NonInvasiveScore.append("Baby Movement_No", NO);
    }
  }
  else {
    NonInvasiveScore.append("Baby Movement_Yes", NO);
    NonInvasiveScore.append("Baby Movement_No", NO);
  }
  if(patient.babyHealthParameters?.baby_skin_colour){
  if (patient.babyHealthParameters?.baby_skin_colour == "Central Cyanosis") {
    NonInvasiveScore.append("Baby Skin Colour_value_Acrocyanosis", NO);
    NonInvasiveScore.append("Baby Skin Colour_value_Central Cyanosis", YES);
    NonInvasiveScore.append("Baby Skin Colour_value_Pale", NO);
    NonInvasiveScore.append("Baby Skin Colour_value_Peripheral Duskiness", NO);
    NonInvasiveScore.append("Baby Skin Colour_value_Pink", NO);
  }
  else if (patient.babyHealthParameters?.baby_skin_colour == "Peripheral Duskiness") {
    NonInvasiveScore.append("Baby Skin Colour_value_Acrocyanosis", NO);
    NonInvasiveScore.append("Baby Skin Colour_value_Central Cyanosis", NO);
    NonInvasiveScore.append("Baby Skin Colour_value_Pale", NO);
    NonInvasiveScore.append("Baby Skin Colour_value_Peripheral Duskiness", YES);
    NonInvasiveScore.append("Baby Skin Colour_value_Pink", NO);
  }
  else if (patient.babyHealthParameters?.baby_skin_colour == "Acrocyanosis") {
    NonInvasiveScore.append("Baby Skin Colour_value_Acrocyanosis", YES);
    NonInvasiveScore.append("Baby Skin Colour_value_Central Cyanosis", NO);
    NonInvasiveScore.append("Baby Skin Colour_value_Pale", NO);
    NonInvasiveScore.append("Baby Skin Colour_value_Peripheral Duskiness", NO);
    NonInvasiveScore.append("Baby Skin Colour_value_Pink", NO);
  }
  else if (patient.babyHealthParameters?.baby_skin_colour == "Pink") {
    NonInvasiveScore.append("Baby Skin Colour_value_Acrocyanosis", NO);
    NonInvasiveScore.append("Baby Skin Colour_value_Central Cyanosis", NO);
    NonInvasiveScore.append("Baby Skin Colour_value_Pale", NO);
    NonInvasiveScore.append("Baby Skin Colour_value_Peripheral Duskiness", NO);
    NonInvasiveScore.append("Baby Skin Colour_value_Pink", YES);
  }
  else if (patient.babyHealthParameters?.baby_skin_colour == "Pale") {
    NonInvasiveScore.append("Baby Skin Colour_value_Acrocyanosis", NO);
    NonInvasiveScore.append("Baby Skin Colour_value_Central Cyanosis", NO);
    NonInvasiveScore.append("Baby Skin Colour_value_Pale", YES);
    NonInvasiveScore.append("Baby Skin Colour_value_Peripheral Duskiness", NO);
    NonInvasiveScore.append("Baby Skin Colour_value_Pink", NO);
  }
  else if (patient.babyHealthParameters?.baby_skin_colour == "NA") {
    NonInvasiveScore.append("Baby Skin Colour_value_Acrocyanosis", NO);
    NonInvasiveScore.append("Baby Skin Colour_value_Central Cyanosis", NO);
    NonInvasiveScore.append("Baby Skin Colour_value_Pale", NO);
    NonInvasiveScore.append("Baby Skin Colour_value_Peripheral Duskiness", NO);
    NonInvasiveScore.append("Baby Skin Colour_value_Pink", NO);
  }
  else if (patient.babyHealthParameters?.baby_skin_colour == "") {
    NonInvasiveScore.append("Baby Skin Colour_value_Acrocyanosis", NO);
    NonInvasiveScore.append("Baby Skin Colour_value_Central Cyanosis", NO);
    NonInvasiveScore.append("Baby Skin Colour_value_Pale", NO);
    NonInvasiveScore.append("Baby Skin Colour_value_Peripheral Duskiness", NO);
    NonInvasiveScore.append("Baby Skin Colour_value_Pink", NO);
  }
  }
  else {
    NonInvasiveScore.append("Baby Skin Colour_value_Acrocyanosis", NO);
    NonInvasiveScore.append("Baby Skin Colour_value_Central Cyanosis", NO);
    NonInvasiveScore.append("Baby Skin Colour_value_Pale", NO);
    NonInvasiveScore.append("Baby Skin Colour_value_Peripheral Duskiness", NO);
    NonInvasiveScore.append("Baby Skin Colour_value_Pink", NO);
  }
  if(patient.getBabyProfile?.baby_weight_at_birth == "NA"){
    NonInvasiveScore.append(
      "Baby Weight At Birth","2.30"
    );
  }
  else{
  NonInvasiveScore.append(
    "Baby Weight At Birth",
    patient.getBabyProfile?.baby_weight_at_birth
      ? patient.getBabyProfile?.baby_weight_at_birth
      : "2.30"
  );
  }
  if (patient.babyHealthParameters?.breast_feeding_initiation){
    if (patient.babyHealthParameters?.breast_feeding_initiation == "YES") {
      NonInvasiveScore.append("Breast Feeding Initiation_Yes", YES);
      NonInvasiveScore.append("Breast Feeding Initiation_No", NO);
    }
    else if (patient.babyHealthParameters?.breast_feeding_initiation == "NO") {
      NonInvasiveScore.append("Breast Feeding Initiation_Yes", NO);
      NonInvasiveScore.append("Breast Feeding Initiation_No", YES);
    }
    else if (patient.babyHealthParameters?.breast_feeding_initiation == "NA") {
      NonInvasiveScore.append("Breast Feeding Initiation_Yes", NO);
      NonInvasiveScore.append("Breast Feeding Initiation_No", NO);
    }
    else if (patient.babyHealthParameters?.breast_feeding_initiation == "") {
      NonInvasiveScore.append("Breast Feeding Initiation_Yes", NO);
      NonInvasiveScore.append("Breast Feeding Initiation_No", NO);
    }
  }
  else{
    NonInvasiveScore.append("Breast Feeding Initiation_Yes", NO);
    NonInvasiveScore.append("Breast Feeding Initiation_No", NO);
  }

  if(patient.babyHealthParameters?.breathing_rate == "NA"){
    NonInvasiveScore.append(
      "Breathing Rate_value","43.17"
    );
  }
  else{
  NonInvasiveScore.append(
    "Breathing Rate_value",
    patient.babyHealthParameters?.breathing_rate
      ? patient.babyHealthParameters?.breathing_rate
      : "43.17"
  );
}
if(patient.babyHealthParameters?.capillary_refill_unit){
  if (patient.babyHealthParameters?.capillary_refill_unit == "> 3 Sec") {
    NonInvasiveScore.append("Capillary Refill Unit_> 3 Sec", YES);
    NonInvasiveScore.append("Capillary Refill Unit_< 3 Sec", NO);
  }
  else if (patient.babyHealthParameters?.capillary_refill_unit == "< 3 Sec") {
    NonInvasiveScore.append("Capillary Refill Unit_> 3 Sec", NO);
    NonInvasiveScore.append("Capillary Refill Unit_< 3 Sec", YES);
  }
  else if (patient.babyHealthParameters?.capillary_refill_unit == "= 3 Sec") {
    NonInvasiveScore.append("Capillary Refill Unit_> 3 Sec", YES);
    NonInvasiveScore.append("Capillary Refill Unit_< 3 Sec", NO);
  }
  else if (patient.babyHealthParameters?.capillary_refill_unit == "= 0 Sec") {
    NonInvasiveScore.append("Capillary Refill Unit_> 3 Sec", NO);
    NonInvasiveScore.append("Capillary Refill Unit_< 3 Sec", YES);
  }
  else if (patient.babyHealthParameters?.capillary_refill_unit == "NA") {
    NonInvasiveScore.append("Capillary Refill Unit_> 3 Sec", NO);
    NonInvasiveScore.append("Capillary Refill Unit_< 3 Sec", NO);
  }
  else if (patient.babyHealthParameters?.capillary_refill_unit == "") {
    NonInvasiveScore.append("Capillary Refill Unit_> 3 Sec", NO);
    NonInvasiveScore.append("Capillary Refill Unit_< 3 Sec", NO);
  }
}
else {
  NonInvasiveScore.append("Capillary Refill Unit_> 3 Sec", NO);
  NonInvasiveScore.append("Capillary Refill Unit_< 3 Sec", NO);
}
if (patient.getMotherProfile?.delayed_cord_clamping) {
  if (patient.getMotherProfile?.delayed_cord_clamping == "YES") {
    NonInvasiveScore.append("Delayed Cord Clamping_Yes", YES);
    NonInvasiveScore.append("Delayed Cord Clamping_No", NO);
  }
  else if (patient.getMotherProfile?.delayed_cord_clamping == "NO") {
    NonInvasiveScore.append("Delayed Cord Clamping_Yes", NO);
    NonInvasiveScore.append("Delayed Cord Clamping_No", YES);
  }
  else if (patient.getMotherProfile?.delayed_cord_clamping == "NA") {
    NonInvasiveScore.append("Delayed Cord Clamping_Yes", NO);
    NonInvasiveScore.append("Delayed Cord Clamping_No", NO);
  }
  else if (patient.getMotherProfile?.delayed_cord_clamping == "") {
    NonInvasiveScore.append("Delayed Cord Clamping_Yes", NO);
    NonInvasiveScore.append("Delayed Cord Clamping_No", NO);
  }
}
else{
  NonInvasiveScore.append("Delayed Cord Clamping_Yes", NO);
  NonInvasiveScore.append("Delayed Cord Clamping_No", NO);
}
if(patient.babyHealthParameters?.fast_breathing){
  if (patient.babyHealthParameters?.fast_breathing == "YES") {
    NonInvasiveScore.append("Fast Breathing_Yes", YES);
    NonInvasiveScore.append("Fast Breathing_No", NO);
  }
  else if (patient.babyHealthParameters?.fast_breathing == "NO") {
    NonInvasiveScore.append("Fast Breathing_Yes", NO);
    NonInvasiveScore.append("Fast Breathing_No", YES);
  }
  else if (patient.babyHealthParameters?.fast_breathing == "NA") {
    NonInvasiveScore.append("Fast Breathing_Yes", NO);
    NonInvasiveScore.append("Fast Breathing_No", NO);
  }
  else if (patient.babyHealthParameters?.fast_breathing == "") {
    NonInvasiveScore.append("Fast Breathing_Yes", NO);
    NonInvasiveScore.append("Fast Breathing_No", NO);
  }
}
else{
  NonInvasiveScore.append("Fast Breathing_Yes", NO);
  NonInvasiveScore.append("Fast Breathing_No", NO);
}
  if(patient.babyHealthParameters?.frequency_of_stools == "NA"){
    NonInvasiveScore.append(
      "Frequency Of Stools_value","1.00"
    );
  }
  else{
  NonInvasiveScore.append(
    "Frequency Of Stools_value",
    patient.babyHealthParameters?.frequency_of_stools
      ? patient.babyHealthParameters?.frequency_of_stools
      : "1.00"
  );
}
if (patient.babyHealthParameters?.groaning){
  if (patient.babyHealthParameters?.groaning == "YES") {
    NonInvasiveScore.append("Groaning_Yes", YES);
    NonInvasiveScore.append("Groaning_No", NO);
  }
  else if (patient.babyHealthParameters?.groaning == "NO") {
    NonInvasiveScore.append("Groaning_Yes", NO);
    NonInvasiveScore.append("Groaning_No", YES);
  }
  else if (patient.babyHealthParameters?.groaning == "NA") {
    NonInvasiveScore.append("Groaning_Yes", NO);
    NonInvasiveScore.append("Groaning_No", NO);
  }
  else if (patient.babyHealthParameters?.groaning == "null") {
    NonInvasiveScore.append("Groaning_Yes", NO);
    NonInvasiveScore.append("Groaning_No", NO);
  }
  else if (patient.babyHealthParameters?.groaning == "") {
    NonInvasiveScore.append("Groaning_Yes", NO);
    NonInvasiveScore.append("Groaning_No", NO);
  }
}
else{
  NonInvasiveScore.append("Groaning_Yes", NO);
  NonInvasiveScore.append("Groaning_No", NO);
}
if(patient.babyHealthParameters?.grunting){
  if (patient.babyHealthParameters?.grunting == "YES") {
    NonInvasiveScore.append("Grunting_Yes", YES);
    NonInvasiveScore.append("Grunting_No", NO);
  }
  else if (patient.babyHealthParameters?.grunting == "NO") {
    NonInvasiveScore.append("Grunting_Yes", NO);
    NonInvasiveScore.append("Grunting_No", YES);
  }
  else if (patient.babyHealthParameters?.grunting == "NA") {
    NonInvasiveScore.append("Grunting_Yes", NO);
    NonInvasiveScore.append("Grunting_No", NO);
  }
  else if (patient.babyHealthParameters?.grunting == "") {
    NonInvasiveScore.append("Grunting_Yes", NO);
    NonInvasiveScore.append("Grunting_No", NO);
  }
}
else{
  NonInvasiveScore.append("Grunting_Yes", NO);
  NonInvasiveScore.append("Grunting_No", NO);
}
  if(patient.babyHealthParameters?.heart_rate == "NA"){
    NonInvasiveScore.append(
      "Heart Rate_value","140.00"
    );
  }
  else{
  NonInvasiveScore.append(
    "Heart Rate_value",
    patient.babyHealthParameters?.heart_rate
      ? patient.babyHealthParameters?.heart_rate
      : "140.00"
  );
}
if(patient.getMotherProfile?.leaking_pv){
  if (patient.getMotherProfile?.leaking_pv == "YES") {
    NonInvasiveScore.append("Leaking pv_Yes", YES);
    NonInvasiveScore.append("Leaking pv_No", NO);
  }
  else if (patient.getMotherProfile?.leaking_pv == "NO") {
    NonInvasiveScore.append("Leaking pv_Yes", NO);
    NonInvasiveScore.append("Leaking pv_No", YES);
  }
  else if (patient.getMotherProfile?.leaking_pv == "NA") {
    NonInvasiveScore.append("Leaking pv_Yes", NO);
    NonInvasiveScore.append("Leaking pv_No", NO);
  }
  else if (patient.getMotherProfile?.leaking_pv == "") {
    NonInvasiveScore.append("Leaking pv_Yes", NO);
    NonInvasiveScore.append("Leaking pv_No", NO);
  }
}
  else{
    NonInvasiveScore.append("Leaking pv_Yes", NO);
    NonInvasiveScore.append("Leaking pv_No", NO);
  }
if(patient.getMotherProfile?.maternal_diabetes){
  if (patient.getMotherProfile?.maternal_diabetes == "YES") {
    NonInvasiveScore.append("Maternal Diabetes_Yes", YES);
    NonInvasiveScore.append("Maternal Diabetes_No", NO);
  }
  else if (patient.getMotherProfile?.maternal_diabetes == "NO") {
    NonInvasiveScore.append("Maternal Diabetes_Yes", NO);
    NonInvasiveScore.append("Maternal Diabetes_No", YES);
  }
  else if (patient.getMotherProfile?.maternal_diabetes == "NA") {
    NonInvasiveScore.append("Maternal Diabetes_Yes", NO);
    NonInvasiveScore.append("Maternal Diabetes_No", NO);
  }
  else if (patient.getMotherProfile?.maternal_diabetes == "") {
    NonInvasiveScore.append("Maternal Diabetes_Yes", NO);
    NonInvasiveScore.append("Maternal Diabetes_No", NO);
  }
}
else{
  NonInvasiveScore.append("Maternal Diabetes_Yes", NO);
  NonInvasiveScore.append("Maternal Diabetes_No", NO);
}
  if(patient.babyHealthParameters?.oxygen_saturation == "NA"){
    NonInvasiveScore.append(
      "Oxygen Saturation_value","95.00"
    );
  }
  else{
  NonInvasiveScore.append(
    "Oxygen Saturation_value",
    patient.babyHealthParameters?.oxygen_saturation
      ? patient.babyHealthParameters?.oxygen_saturation
      : "95.00"
  );
  }
if (patient.getMotherProfile?.rupture_of_membranes_rom_one){
  if (patient.getMotherProfile?.rupture_of_membranes_rom_one == "PROM") {
    NonInvasiveScore.append("Rupture Of Membranes Rom One_PROM", YES);
    NonInvasiveScore.append("Rupture Of Membranes Rom One_SROM", NO);
    NonInvasiveScore.append("Rupture Of Membranes Rom One_ARM", NO);
  }
  else if (patient.getMotherProfile?.rupture_of_membranes_rom_one == "SROM") {
    NonInvasiveScore.append("Rupture Of Membranes Rom One_PROM", NO);
    NonInvasiveScore.append("Rupture Of Membranes Rom One_SROM", YES);
    NonInvasiveScore.append("Rupture Of Membranes Rom One_ARM", NO);
  } 
  else if (patient.getMotherProfile?.rupture_of_membranes_rom_one == "ARM") {
    NonInvasiveScore.append("Rupture Of Membranes Rom One_PROM", NO);
    NonInvasiveScore.append("Rupture Of Membranes Rom One_SROM", NO);
    NonInvasiveScore.append("Rupture Of Membranes Rom One_ARM", YES);
  } 
  else if (patient.getMotherProfile?.rupture_of_membranes_rom_one == "NA") {
    NonInvasiveScore.append("Rupture Of Membranes Rom One_PROM", NO);
    NonInvasiveScore.append("Rupture Of Membranes Rom One_SROM", NO);
    NonInvasiveScore.append("Rupture Of Membranes Rom One_ARM", NO);
  } 
  else if (patient.getMotherProfile?.rupture_of_membranes_rom_one == "") {
    NonInvasiveScore.append("Rupture Of Membranes Rom One_PROM", NO);
    NonInvasiveScore.append("Rupture Of Membranes Rom One_SROM", NO);
    NonInvasiveScore.append("Rupture Of Membranes Rom One_ARM", NO);
  }
}
else{
  NonInvasiveScore.append("Rupture Of Membranes Rom One_PROM", NO);
  NonInvasiveScore.append("Rupture Of Membranes Rom One_SROM", NO);
  NonInvasiveScore.append("Rupture Of Membranes Rom One_ARM", NO);
}
if(patient.getMotherProfile?.rupture_of_membranes_rom){
  if (patient.getMotherProfile?.rupture_of_membranes_rom == "YES") {
    NonInvasiveScore.append("Rupture Of Membranes Rom_Yes", YES);
    NonInvasiveScore.append("Rupture Of Membranes Rom_No", NO);
  }
  else if (patient.getMotherProfile?.rupture_of_membranes_rom == "NO") {
    NonInvasiveScore.append("Rupture Of Membranes Rom_Yes", NO);
    NonInvasiveScore.append("Rupture Of Membranes Rom_No", YES);
  }
  else if (patient.getMotherProfile?.rupture_of_membranes_rom == "NA") {
    NonInvasiveScore.append("Rupture Of Membranes Rom_Yes", NO);
    NonInvasiveScore.append("Rupture Of Membranes Rom_No", NO);
  }
  else if (patient.getMotherProfile?.rupture_of_membranes_rom == "") {
    NonInvasiveScore.append("Rupture Of Membranes Rom_Yes", NO);
    NonInvasiveScore.append("Rupture Of Membranes Rom_No", NO);
  }
}
else{
  NonInvasiveScore.append("Rupture Of Membranes Rom_Yes", NO);
  NonInvasiveScore.append("Rupture Of Membranes Rom_No", NO);
}
if(patient.getBabyProfile?.seizures){
  if (patient.getBabyProfile?.seizures == "YES") {
    NonInvasiveScore.append("Seizures_Yes", YES);
    NonInvasiveScore.append("Seizures_No", NO);
  }
  else if (patient.getBabyProfile?.seizures == "NO") {
    NonInvasiveScore.append("Seizures_Yes", NO);
    NonInvasiveScore.append("Seizures_No", YES);
  }
  else if (patient.getBabyProfile?.seizures == "NA") {
    NonInvasiveScore.append("Seizures_Yes", NO);
    NonInvasiveScore.append("Seizures_No", NO);
  }
  else if (patient.getBabyProfile?.seizures == "") {
    NonInvasiveScore.append("Seizures_Yes", NO);
    NonInvasiveScore.append("Seizures_No", NO);
  }
}
  else{
    NonInvasiveScore.append("Seizures_Yes", NO);
    NonInvasiveScore.append("Seizures_No", NO);
  }

  return NonInvasiveScore;
};

export const SemiInvasiveScoreData = (patient) => {
  const YES = "1";
  const NO = "0";
  const SemiInvasiveScore = new FormData();
if(patient.BabySemiInvasive?.abdominal_dystension){
  if (patient.BabySemiInvasive?.abdominal_dystension == "YES") {
    SemiInvasiveScore.append("Abdominal Dystension_Yes", YES);
    SemiInvasiveScore.append("Abdominal Dystension_No", NO);
  }
  else if (patient.BabySemiInvasive?.abdominal_dystension == "NO") {
    SemiInvasiveScore.append("Abdominal Dystension_Yes", NO);
    SemiInvasiveScore.append("Abdominal Dystension_No", YES);
  }
  else if (patient.BabySemiInvasive?.abdominal_dystension == "NA") {
    SemiInvasiveScore.append("Abdominal Dystension_Yes", NO);
    SemiInvasiveScore.append("Abdominal Dystension_No", NO);
  }
  else if (patient.BabySemiInvasive?.abdominal_dystension == "null") {
    SemiInvasiveScore.append("Abdominal Dystension_Yes", NO);
    SemiInvasiveScore.append("Abdominal Dystension_No", NO);
  }
}
else{
  SemiInvasiveScore.append("Abdominal Dystension_Yes", NO);
  SemiInvasiveScore.append("Abdominal Dystension_No", NO);
}
  if(patient.BabySemiInvasive?.absolute_neutrophil_count == "NA"){
    SemiInvasiveScore.append(
      "Absolute Neutrophil Count_value","8.60"
    );
  }
  else{
    if( patient.BabySemiInvasive?.absolute_neutrophil_count_unit == "mL")
    {
      const abnc_Value = "1000"* patient.BabySemiInvasive?.absolute_neutrophil_count;
      SemiInvasiveScore.append(
        "Absolute Neutrophil Count_value",
        abnc_Value
          ? abnc_Value
          : "8.60"
      );
    }
    else{

    SemiInvasiveScore.append(
    "Absolute Neutrophil Count_value",
    patient.BabySemiInvasive?.absolute_neutrophil_count
      ? patient.BabySemiInvasive?.absolute_neutrophil_count
      : "8.60"
  );
}
}
if(patient.BabySemiInvasive?.baby_blood_glucose == "NA"){
  SemiInvasiveScore.append(
    "Baby Blood Glucose_value","18.80"
  );
}
else{
  if( patient.BabySemiInvasive?.baby_blood_glucose_unit == "mg/dL")
    {
      const BBGC_Value = "0.0555"* patient.BabySemiInvasive?.baby_blood_glucose;
      SemiInvasiveScore.append(
        "Baby Blood Glucose_value",
        BBGC_Value
          ? BBGC_Value
          : "18.80"
      );
    }
    else{
  SemiInvasiveScore.append(
    "Baby Blood Glucose_value",
    patient.BabySemiInvasive?.baby_blood_glucose
      ? patient.BabySemiInvasive?.baby_blood_glucose
      : "18.80"
  );
}
}
if(patient.BabySemiInvasive?.breathing_rate == "NA"){
  SemiInvasiveScore.append(
    "Breathing Rate_value","43.17"
  );
}
else{
  SemiInvasiveScore.append(
    "Breathing Rate_value",
    patient.BabySemiInvasive?.breathing_rate
      ? patient.BabySemiInvasive?.breathing_rate
      : "43.17"
  );
}
if(patient.BabySemiInvasive?.baby_blood_pressure_lower_limb == "NA"){
  SemiInvasiveScore.append(
    "Baby Blood Pressure Lower Limb_value","16.67"
  );
}
else{
  SemiInvasiveScore.append(
    "Baby Blood Pressure Lower Limb_value",
    patient.BabySemiInvasive?.baby_blood_pressure_lower_limb
      ? patient.BabySemiInvasive?.baby_blood_pressure_lower_limb
      : "16.67"
  );
}
if(patient.BabySemiInvasive?.baby_blood_pressure_upper_limb == "NA"){
  SemiInvasiveScore.append(
    "Baby Blood Pressure Upper Limb_value","23.10"
  );
}
else{
  SemiInvasiveScore.append(
    "Baby Blood Pressure Upper Limb_value",
    patient.BabySemiInvasive?.baby_blood_pressure_upper_limb
      ? patient.BabySemiInvasive?.baby_blood_pressure_upper_limb
      : "23.10"
  );
}

if(patient.BabySemiInvasive?.baby_blood_pressure_mean_arterial_bp == "NA"){
  SemiInvasiveScore.append(
    "Baby Blood Pressure Mean Arterial Bp_value","17.33"
  );
}
else{
  SemiInvasiveScore.append(
    "Baby Blood Pressure Mean Arterial Bp_value",
    patient.BabySemiInvasive?.baby_blood_pressure_mean_arterial_bp
      ? patient.BabySemiInvasive?.baby_blood_pressure_mean_arterial_bp
      : "17.33"
  );
}

if(patient.getBabyProfile?.baby_gestational_age == "NA"){
  SemiInvasiveScore.append(
    "Baby Gestational Age","36.00"
  );
}
else{
  SemiInvasiveScore.append(
    "Baby Gestational Age",
    patient.getBabyProfile?.baby_gestational_age
      ? patient.getBabyProfile?.baby_gestational_age
      : "36.00"
  );
}

if(patient.BabySemiInvasive?.baby_haemoglobin_levels == "NA"){
  SemiInvasiveScore.append(
    "Baby Haemoglobin Levels_value","5.62"
  );
}
else{
  
  if( patient.BabySemiInvasive?.baby_haemoglobin_levels_unit == "g/L")
    {
      const BHL_Value = patient.BabySemiInvasive?.baby_haemoglobin_levels * "0.10";
      SemiInvasiveScore.append(
        "Baby Haemoglobin Levels_value",
        BHL_Value
          ? BHL_Value
          : "5.62"
      );
    }
    else{
  SemiInvasiveScore.append(
    "Baby Haemoglobin Levels_value",
    patient.BabySemiInvasive?.baby_haemoglobin_levels
      ? patient.BabySemiInvasive?.baby_haemoglobin_levels
      : "5.62"
  );
}
}
if(patient.BabySemiInvasive?.baby_c_reactive_protien_levels == "NA"){
  SemiInvasiveScore.append(
    "Baby Reactive Protien Levels_value","0.86"
  );
}
else{

  if( patient.BabySemiInvasive?.baby_c_reactive_protien_levels_unit == "mg/dL")
    {
      const CRPL_Value = patient.BabySemiInvasive?.baby_c_reactive_protien_levels * "10";
      SemiInvasiveScore.append(
        "Baby Reactive Protien Levels_value",
        CRPL_Value
          ? CRPL_Value
          : "0.86"
      );
    }
    else{
  SemiInvasiveScore.append(
    "Baby Reactive Protien Levels_value",
    patient.BabySemiInvasive?.baby_c_reactive_protien_levels
      ? patient.BabySemiInvasive?.baby_c_reactive_protien_levels
      : "0.86"
  );
}
}

if(patient.getBabyProfile?.baby_weight_at_birth == "NA"){
  SemiInvasiveScore.append(
    "Baby Reactive Protien Levels_value","2.30"
  );
}
else{
  SemiInvasiveScore.append(
    "Baby Weight At Birth",
    patient.getBabyProfile?.baby_weight_at_birth
      ? patient.getBabyProfile?.baby_weight_at_birth
      : "2.30"
  );
}
if(patient.BabySemiInvasive?.bilirubin_levels == "NA"){
  SemiInvasiveScore.append(
    "Bilirubin Levels_value","0.96"
  );
}
else{
  
  if( patient.BabySemiInvasive?.bilirubin_unit == "mg/dL")
    {
      const BLR_Value = "0.0555"* patient.BabySemiInvasive?.bilirubin_levels;
      SemiInvasiveScore.append(
        "Bilirubin Levels_value",
        BLR_Value
          ? BLR_Value
          : "0.96"
      );  
    }
    else{
  SemiInvasiveScore.append(
    "Bilirubin Levels_value",
    patient.BabySemiInvasive?.bilirubin_levels
      ? patient.BabySemiInvasive?.bilirubin_levels
      : "0.96"
  );
}
}

if(patient.BabySemiInvasive?.calcium == "NA"){
  SemiInvasiveScore.append(
    "Calcium_value","0.41"
  );
}
else{
  if( patient.BabySemiInvasive?.calcium_unit == "mg/dL")
    {
      const calcium_Value = "0.0555"* patient.BabySemiInvasive?.calcium;
      SemiInvasiveScore.append(
        "Calcium_value",
        calcium_Value
          ? calcium_Value
          : "0.41"
      );
    }
    else{
  SemiInvasiveScore.append(
    "Calcium_value",
    patient.BabySemiInvasive?.calcium
    ? patient.BabySemiInvasive?.calcium : "0.41"
  );
}
}
if(patient.BabySemiInvasive?.creatinine == "NA"){
  SemiInvasiveScore.append(
    "Creatinine_value","0.48"
  );
}
else{
  if( patient.BabySemiInvasive?.creatinine_unit == "mg/dL")
    {
      const creatinine_Value = "0.0555"* patient.BabySemiInvasive?.creatinine;
      SemiInvasiveScore.append(
        "Creatinine_value",
        creatinine_Value
          ? creatinine_Value
          : "0.48"
      );
    }
    else{
  SemiInvasiveScore.append(
    "Creatinine_value",
    patient.BabySemiInvasive?.creatinine
      ? patient.BabySemiInvasive?.creatinine
      : "0.48"
  );
}
}

if(patient.BabySemiInvasive?.chlorine == "NA"){
  SemiInvasiveScore.append(
    "Chlorine_value","0.48"
  );
}
else{
  if( patient.BabySemiInvasive?.chlorine_unit == "mg/dL")
    {
      const chlorine_Value = "0.0555"* patient.BabySemiInvasive?.chlorine;
      SemiInvasiveScore.append(
        "Chlorine_value",
        chlorine_Value
          ? chlorine_Value
          : "0.48"
      );
    }
    else{
  SemiInvasiveScore.append(
    "Chlorine_value",
    patient.BabySemiInvasive?.chlorine
      ? patient.BabySemiInvasive?.chlorine
      : "0.48"
  );
}
}
if(patient.getMotherProfile?.delayed_cord_clamping){
  if (patient.getMotherProfile?.delayed_cord_clamping == "YES") {
    SemiInvasiveScore.append("Delayed Cord Clamping_Yes", YES);
    SemiInvasiveScore.append("Delayed Cord Clamping_No", NO);
  }
  if (patient.getMotherProfile?.delayed_cord_clamping == "NO") {
    SemiInvasiveScore.append("Delayed Cord Clamping_Yes", NO);
    SemiInvasiveScore.append("Delayed Cord Clamping_No", YES);
  }
  if (patient.getMotherProfile?.delayed_cord_clamping == "NA") {
    SemiInvasiveScore.append("Delayed Cord Clamping_Yes", NO);
    SemiInvasiveScore.append("Delayed Cord Clamping_No", NO);
  }
}
else{
  SemiInvasiveScore.append("Delayed Cord Clamping_Yes", NO);
    SemiInvasiveScore.append("Delayed Cord Clamping_No", NO);
}

  if(patient.BabySemiInvasive?.frequency_of_stools == "NA"){
    SemiInvasiveScore.append(
      "Frequency Of Stools_value","1.00"
    );
  }
  else{
  SemiInvasiveScore.append(
    "Frequency Of Stools_value",
    patient.BabySemiInvasive?.frequency_of_stools
      ? patient.BabySemiInvasive?.frequency_of_stools
      : "1.00"
  );
  }
  if(patient.BabySemiInvasive?.groaning){
  if (patient.BabySemiInvasive?.groaning == "YES") {
    SemiInvasiveScore.append("Groaning_Yes", YES);
    SemiInvasiveScore.append("Groaning_No", NO);
  }
  if (patient.BabySemiInvasive?.groaning == "NO") {
    SemiInvasiveScore.append("Groaning_Yes", NO);
    SemiInvasiveScore.append("Groaning_No", YES);
  }
  if (patient.BabySemiInvasive?.groaning == "NA") {
    SemiInvasiveScore.append("Groaning_Yes", NO);
    SemiInvasiveScore.append("Groaning_No", NO);
  }
}
else{
  SemiInvasiveScore.append("Groaning_Yes", NO);
    SemiInvasiveScore.append("Groaning_No", NO);
}
  if (patient.BabySemiInvasive?.groaning == "null") {
    SemiInvasiveScore.append("Groaning_Yes", NO);
    SemiInvasiveScore.append("Groaning_No", NO);
  }
  if (patient.BabySemiInvasive?.groaning == "") {
    SemiInvasiveScore.append("Groaning_Yes", NO);
    SemiInvasiveScore.append("Groaning_No", NO);
  }
  if(patient.BabySemiInvasive?.groaning == "null"){
    SemiInvasiveScore.append("Groaning_Yes", NO);
    SemiInvasiveScore.append("Groaning_No", NO);
  }
  if (patient.BabySemiInvasive?.grunting == "YES") {
    SemiInvasiveScore.append("Grunting_Yes", YES);
    SemiInvasiveScore.append("Grunting_No", NO);
  }
  if (patient.BabySemiInvasive?.grunting == "NO") {
    SemiInvasiveScore.append("Grunting_Yes", NO);
    SemiInvasiveScore.append("Grunting_No", YES);
  }
  if (patient.BabySemiInvasive?.grunting == "NA") {
    SemiInvasiveScore.append("Grunting_Yes", NO);
    SemiInvasiveScore.append("Grunting_No", NO);
  }
  if(patient.BabySemiInvasive?.heart_rate == "NA"){
    SemiInvasiveScore.append(
      "Heart Rate_value","140.00"
    );
  }
  else{
  SemiInvasiveScore.append(
    "Heart Rate_value",
    patient.BabySemiInvasive?.heart_rate
      ? patient.BabySemiInvasive?.heart_rate
      : "140.00"
  );
  }
  if (patient.getMotherProfile?.leaking_pv == "YES") {
    SemiInvasiveScore.append("Leaking pv_Yes", YES);
    SemiInvasiveScore.append("Leaking pv_No", NO);
  }
  if (patient.getMotherProfile?.leaking_pv == "NO") {
    SemiInvasiveScore.append("Leaking pv_Yes", NO);
    SemiInvasiveScore.append("Leaking pv_No", YES);
  }
  if (patient.getMotherProfile?.leaking_pv == "NA") {
    SemiInvasiveScore.append("Leaking pv_Yes", NO);
    SemiInvasiveScore.append("Leaking pv_No", NO);
  }
  if (patient.BabySemiInvasive?.oxygen_saturation == "NA") {
    SemiInvasiveScore.append(
      "Oxygen Saturation_value","95"
    );
  }
  else{
  SemiInvasiveScore.append(
    "Oxygen Saturation_value",
    patient.BabySemiInvasive?.oxygen_saturation
      ? patient.BabySemiInvasive?.oxygen_saturation
      : "95"
  );
}

if (patient.BabySemiInvasive?.potassium == "NA") {
  SemiInvasiveScore.append(
    "Potassium_value","1.90"
  );
}
else{
  
  if( patient.BabySemiInvasive?.potassium_unit == "mg/dL")
    {
      const potassium_Value = "0.0555"* patient.BabySemiInvasive?.potassium;
      SemiInvasiveScore.append(
        "Potassium_value",
        potassium_Value
          ? potassium_Value
          : "1.90"
      );
    }
    else{
  SemiInvasiveScore.append(
    "Potassium_value",
    patient.BabySemiInvasive?.potassium
      ? patient.BabySemiInvasive?.potassium
      : "1.90"
  );
}
}

if (patient.BabySemiInvasive?.sodium == "NA") {
  SemiInvasiveScore.append(
    "Sodium_value","1.90"
  );
}
else{
  if( patient.BabySemiInvasive?.Sodium_unit == "mg/dL")
    {
      const sodium_Value = "0.0555"* patient.BabySemiInvasive?.sodium;
      SemiInvasiveScore.append(
        "Sodium_value",
        sodium_Value
          ? sodium_Value
          : "1.90"
      );
    }
    else{
  SemiInvasiveScore.append(
    "Sodium_value",
    patient.BabySemiInvasive?.sodium
     ? patient.BabySemiInvasive?.sodium : "1.90"
  );
}
}

if (patient.BabySemiInvasive?.urea == "NA") {
  SemiInvasiveScore.append(
    "Urea_value","7.62"
  );
}
else{
  
  if( patient.BabySemiInvasive?.urea_unit == "mg/dL")
    {
      const urea_Value = "0.0555"* patient.BabySemiInvasive?.urea;
      SemiInvasiveScore.append(
        "Urea_value",
        urea_Value
          ? urea_Value
          : "7.62"
      );
    }
    else{
  SemiInvasiveScore.append(
    "Urea_value",
    patient.BabySemiInvasive?.urea
     ? patient.BabySemiInvasive?.urea : "7.62"
  );
}
}
if (patient.BabySemiInvasive?.thrombocytopenia == "NA") {
  SemiInvasiveScore.append(
    "Thrombocytopeni_value","74787.50"
  );
}
else{
  SemiInvasiveScore.append(
    "Thrombocytopeni_value",
    patient.BabySemiInvasive?.thrombocytopenia
      ? patient.BabySemiInvasive?.thrombocytopenia
      : "74787.50"
  );
}
if (patient.BabySemiInvasive?.total_leucocute_count == "NA") {
  SemiInvasiveScore.append(
    "Total Leucocute Count_value","5716.67"
  );
}
else{
  SemiInvasiveScore.append(
    "Total Leucocute Count_value",
    patient.BabySemiInvasive?.total_leucocute_count
      ? patient.BabySemiInvasive?.total_leucocute_count
      : "5716.67"
  );
}
  return SemiInvasiveScore;
};

export const QuickSepsisScore = (patient) => {
  const QuickSepsis = new FormData();
  QuickSepsis.append(
    "baby_weight_at_birth",
    patient.getBabyProfile?.baby_weight_at_birth
      ? patient.getBabyProfile?.baby_weight_at_birth
      : "NA"
  );
  QuickSepsis.append(
    "baby_weight_at_admission",
    patient.getBabyProfile?.baby_weight_at_admission
      ? patient.getBabyProfile?.baby_weight_at_admission
      : "NA"
  );
  QuickSepsis.append(
    "baby_gestational_age",
    patient.getBabyProfile?.baby_gestational_age
      ? patient.getBabyProfile?.baby_gestational_age
      : "NA"
  );

  QuickSepsis.append(
    "mother_bmi",
    patient.getMotherProfile?.mother_bmi
      ? patient.getMotherProfile?.mother_bmi
      : "NA"
  );

  QuickSepsis.append(
    "mother_age",
    patient.getMotherProfile?.mother_age
      ? patient.getMotherProfile?.mother_age
      : "NA"
  );
  QuickSepsis.append(
    "maternal_fever",
    patient.getMotherProfile?.maternal_fever
      ? patient.getMotherProfile?.maternal_fever
      : "NA"
  );

  QuickSepsis.append(
    "baby_skin_colour",
    patient.getBabyQuickSepsis?.baby_skin_colour
      ? patient.getBabyQuickSepsis?.baby_skin_colour
      : "NA"
  );
  QuickSepsis.append(
    "baby_cry_sound",
    patient.getBabyQuickSepsis?.baby_cry_sound
      ? patient.getBabyQuickSepsis?.baby_cry_sound
      : "NA"
  );
  QuickSepsis.append(
    "baby_feeding_status",
    updateValue(
      patient.getBabyQuickSepsis?.baby_feeding_status
        ? patient.getBabyQuickSepsis?.baby_feeding_status
        : "NA"
    )
  );
  QuickSepsis.append(
    "breathing_rate",
    patient.getBabyQuickSepsis?.breathing_rate
      ? patient.getBabyQuickSepsis?.breathing_rate
      : "NA"
  );

  QuickSepsis.append(
    "oxygen_saturation",
    patient.getBabyQuickSepsis?.oxygen_saturation
      ? patient.getBabyQuickSepsis?.oxygen_saturation
      : "NA"
  );
  QuickSepsis.append(
    "heart_rate",
    patient.getBabyQuickSepsis?.heart_rate
      ? patient.getBabyQuickSepsis?.heart_rate
      : "NA"
  );
  QuickSepsis.append(
    "frequency_of_stools",
    patient.getBabyQuickSepsis?.frequency_of_stools
      ? patient.getBabyQuickSepsis?.frequency_of_stools
      : "NA"
  );

  return QuickSepsis;
};
